import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import PinInput from "react-pin-input";
import InputField from "./InputField";
import { stateDistricts } from "./State";
import { set, subYears } from "date-fns";
import add from "../asset/Add.png";
import minus from "../asset/minus.png";
import { Button } from "bootstrap";
import Modal from "./Modal";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import Alert from "../component/Alert";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery } from "react-query";
import AddressModal from "./AddressModal";
import wrongIcon from "../asset/close.svg";
import added from "../asset/added.svg";
import { Autocomplete, TextField } from "@mui/material";
import AddConfirmAlt from "./AddConfirmAlt";

export default function ClientInformationCom() {
  const [alsoWhatApp, setAlsoWhatApp] = useState();
  const [alsoPermanent, setAlsoPermanent] = useState();
  const [mobileNumberData, setMobileNumberData] = useState();
  const [districts, setDistricts] = useState([]);
  const [amtError, setAmtError] = useState(false);
  const [showVisitorBtn, setShowVisitorBtn] = useState(false);
  const [showOfficeAdd, setShowOfficeAdd] = useState(null);
  const [showPresentAdd, setShowPresentAdd] = useState(null);
  const [showAddPop, setShowAddPop] = useState(false);
  const [presentConfirm, setPresentConfirm] = useState(null);
  const [officeConfirm, setOfficeConfirm] = useState(null);
  const [PermanentConfirm, setPermanentConfirm] = useState(null);

  const [clientPresentAdd, setClientPresentAdd] = useState(null);
  const [clientOfficeAdd, setClientOfficeAdd] = useState(null);
  const [clientPermentAdd, setClientPermanentAdd] = useState(null);

  const [showPermanentAppend, setShowPermanentAppend] = useState(null);
  const [showOfficeAppend, setOfficeAppend] = useState(null);
  const [showPresentAddAppend, setShowPresentAddAppend] = useState(null);

  const [appPermanentAdd, setAppPermanentAdd] = useState(null);
  const [appOfficeAdd, setAppOfficeAdd] = useState(null);
  const [appPresentAdd, setAppPresentAdd] = useState(null);

  const [formValues, setFormValues] = useState({});
  const [address, setAddress] = useState({
    name: "",
    add: "",
    mNo: "",
    aNo: "",
    email: "",
    addInfo: "",
  });

  console.log("address", address);

  const [clientDetails, setClientDetails] = useState(false);
  const { state } = useLocation();
  console.log("state", state);
  const [page, setPage] = useState();
  const [addressAlert, setAddressAlert] = useState();
  const [postAlert, setPostAlert] = useState();
  const navigate = useNavigate();
  const [putAlert, setPutAlert] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [showPermanentAdd, setShowPermanentAdd] = useState(null);
  const [currentField, setCurrentField] = useState(null); // Track which field triggered the modal
  const [modalValue, setModalValue] = useState(""); // Store modal input value
  const { control, handleSubmit, setValue, watch, register, getValues } =
    useForm({
      defaultValues: {
        subjectDetailstwo: [],

        // referenceData: null,
        // caseDetailsDate: null,
      },
    });
  const { fields, append, remove } = useFieldArray({
    name: "subjectDetailstwo",
    control,
  });

  console.log("clientDetails", clientDetails);
  const {
    subjectDetailstwo,
    caseDetailsTotalAmount,
    caseDetailsAmountReceived,
    caseDetailsBalancePending,
    CheckNumber,
    subjectMobileNumber,
    // subjectInTime,
    subjectReligion,
    subjectCategory,
    subjectSourceForm,
    CheckWhatsappNumber,
    CheckEmailId,
    CheckName,
    subjectPermanentAddNo,
    subjectPermanentAdd1No,
    subjectPermanentAddEmail,
    subjectPermanentAdditionalDetails,
    subjectOfficeName,
    subjectPermanentAddress,
    subjectOfficeAddNo,
    subjectOfficeAdd1No,
    subjectOfficeAddEmail,
    subjectOfficeAdditionalDetails,
    subjectOfficeAddress,
    subjectTemporaryAddress,
    subjectPermanentFamilyName,
    inTime,
    sourceForm,
    category,
    religion,
    gender,
    clientAddress,
    officeAddress,
    permanentAddress,
    officeName,
    officeAddNo,
    officeAdd1No,
    officeAddEmail,
    officeAdditionalDetails,
    permanentFamilyName,
    permanentAddNo,
    permanentAddEmail,
    permanentAdditionalDetails,
  } = watch();
  console.log("subjectDetailstwo", subjectDetailstwo);
  const userData = sessionStorage.getItem("userdata");
  console.log("caseDetailsAmountReceived", caseDetailsAmountReceived);

  const fetchingData = async () => {
    return state
      ? await axios.get(`${process.env.REACT_APP_HOST}/newvisitors`)
      : await axios.get(
          `${process.env.REACT_APP_HOST}/nonConvertedVisitors/all`
        );
  };
  // Fetch visitor data using React Query
  const { data: visitorDetails, refetch } = useQuery(
    "fetchingData",
    fetchingData,
    {
      onSuccess: (data) => {
        console.log("Visitor data fetched:", data?.data);
      },
    }
  );
  const visitorData = visitorDetails?.data || [];
  console.log("visitorData", visitorData);

  const clientNumberData = () => {};
  useEffect(() => {
    const CheckClientNumber = visitorData?.find((data) => {
      const isMobileMatch =
        data?.mobileNumber && Number(data.mobileNumber) === Number(CheckNumber);
      const spiltName = String(CheckName).split(" : ");
      const isNameMatch = data?.name && data.name === spiltName[0];
      const isWhatsappMatch =
        data?.whatsapp && Number(data.whatsapp) === Number(CheckNumber);
      const spilt = String(CheckEmailId).split(" : ");
      console.log("spilt", spilt[1]);

      const isEmailMatch =
        data?.temporaryAddress && data.temporaryAddress === spilt[1];
      console.log("isNameMatch", isEmailMatch);
      return isMobileMatch || isWhatsappMatch || isEmailMatch || isNameMatch;
    });
    console.log("alsoPermanent", alsoPermanent, CheckClientNumber);
    if (CheckClientNumber?.length !== 0) {
      setClientDetails(CheckClientNumber);
    }
    // else if (CheckClientNumber?.length === 0) {
    //   setAlsoPermanent("value");
    // }
    if (clientDetails) {
      console.log("DATA------>", CheckClientNumber);
      setValue("clientName", clientDetails && clientDetails?.name);
      setValue("fatherName", clientDetails && clientDetails?.fatherName);
      setValue(
        "dob",
        clientDetails?.dob !== "Invalid date" ? clientDetails?.dob : ""
      );
      // setValue("dob", clientDetails &&  clientDetails?.dob !== null
      //   ? moment(clientDetails?.dob, "DD-MM-YYYY").format("MM/DD/YYYY")
      //   : "");
      setValue("age", clientDetails && clientDetails?.age);
      setValue("mobileNumber", clientDetails && clientDetails?.mobileNumber);
      setValue(
        "clientAddress",
        clientDetails && clientDetails?.temporaryAddress
      );
      setValue(
        "alternativeMobileNumber",
        clientDetails && clientDetails?.alternativeNumber
      );
      setValue("email", clientDetails && clientDetails?.email);
      setValue("education", clientDetails && clientDetails?.education);
      setValue("gender", clientDetails && clientDetails?.gender);
      setValue("otherGender", clientDetails && clientDetails?.otherGender);
      setValue("qualification", clientDetails && clientDetails?.qualification);
      setValue(
        "whatsapp",
        clientDetails && clientDetails?.whatsapp ? clientDetails?.whatsapp : ""
      );
      setValue("caste", clientDetails && clientDetails?.caste);
      setValue("religion", clientDetails && clientDetails?.religion);
      setValue("otherReligion", clientDetails && clientDetails?.otherReligion);
      setValue("category", clientDetails && clientDetails?.category);
      setValue("otherCategory", clientDetails && clientDetails?.otherCategory);
      setValue("sourceForm", clientDetails && clientDetails?.sourceForm);
      setValue(
        "otherSourceForm",
        clientDetails && clientDetails?.otherSourceForm
      );
      setValue("dateOfVisit", clientDetails && clientDetails?.dateOfVisit);
      setValue(
        "dateOfAppointment",
        clientDetails && clientDetails?.dateOfAppointment
      );
      setValue("status", clientDetails && clientDetails?.status);
      setValue("inTime", clientDetails && clientDetails?.inTime);
      setValue("outTime", clientDetails && clientDetails?.outTime);
      setValue(
        "purposeOfVisit",
        clientDetails && clientDetails?.purposeOfVisit
      );
      setValue(
        "additionalStatus",
        clientDetails && clientDetails?.additionalStatus
      );
      setValue("visitorNote1", clientDetails && clientDetails?.visitorNote1);
      setValue("visitorNote2", clientDetails && clientDetails?.visitorNote2);
      setValue("officeAdd1No", clientDetails && clientDetails?.officeAdd1No);
      setValue("officeAddNo", clientDetails && clientDetails?.officeAddNo);
      setValue("officeAdditionalDetails", clientDetails && clientDetails?.officeAdditionalDetails);
      setValue("officeAddress", clientDetails && clientDetails?.officeAddress);
      setValue("officeName", clientDetails && clientDetails?.officeName);
      setValue("permanentAddNo", clientDetails && clientDetails?.permanentAddNo);
      setValue("permanentAdditionalDetails", clientDetails && clientDetails?.permanentAdditionalDetails);
      setValue("permanentAddress", clientDetails && clientDetails?.permanentAddress);
      setValue("permanentFamilyName", clientDetails && clientDetails?.permanentFamilyName);
      setAlsoWhatApp(true);
    }
    console.log("showVisitorBtn", clientDetails, showVisitorBtn);
  }, [
    CheckNumber,
    clientDetails,
    CheckWhatsappNumber,
    CheckEmailId,
    CheckName,
    subjectDetailstwo,
    state,
  ]);

  const handleFocus = (fieldName, labelName, currentValue = "") => {
    setCurrentField({ fieldName, labelName, value: currentValue });
    if (currentValue?.length > 55) {
      setModalOpen(true); // Open modal if text length > 15
    }
  };

  const handleChange = (fieldName, value) => {
    setFormValues((prev) => ({ ...prev, [fieldName]: value }));

    if (value?.length > 55 && !isModalOpen) {
      setCurrentField({ fieldName, labelName: fieldName, value });
      setModalOpen(true);
    }
  };

  const handleSave = (value) => {
    if (!currentField) return;

    console.log("currentField", currentField, value);

    if (subjectDetailstwo?.length > 0) {
      // If index 0 or more exists, update based on index
      subjectDetailstwo.forEach((user, index) => {
        setValue(currentField.fieldName, value);
      });
    } else {
      // If subjectDetailstwo is empty, update normally
      setValue(currentField.fieldName, value);
    }

    setModalOpen(false);
    setCurrentField(null);
  };

  const Amount = Number(caseDetailsTotalAmount) || 0;
  const balanceAmt = Number(caseDetailsAmountReceived) || 0;
  const amtReceived = Number(caseDetailsAmountReceived) || 0;
  const calclulateAmt = Amount - balanceAmt;

  const addAmt =
    Number(state?.caseDetails?.balancePending) +
    Number(caseDetailsAmountReceived);

  useEffect(() => {
    if (state) {
      const receivedAmount = Number(
        state?.caseDetails?.amountReceived?.reduce(
          (a, b) => Number(a) + Number(b),
          0
        )
      ); // Sum of received amounts
      const totalAmount = Number(Amount); // Total amount
      const balanceAmount = Number(balanceAmt); // Current balance amount
      console.log("receivedAmount", receivedAmount);

      // Case 1: If balance amount is 0
      if (balanceAmount === 0) {
        setValue("caseDetailsBalancePending", Number(Amount - receivedAmount)); // Set balance to empty string
        setValue("caseDetailsStatus", "pending"); // Set status as pending
      }
      // Case 2: If totalAmount equals receivedAmount + balanceAmount
      else if (totalAmount === receivedAmount + balanceAmount) {
        setValue("caseDetailsBalancePending", 0); // Balance is fully cleared
        setValue("caseDetailsStatus", "completed"); // Mark as completed
      }
      // Case 3: If totalAmount is not fully paid
      else if (totalAmount !== receivedAmount + balanceAmount) {
        const remainingBalance = totalAmount - (receivedAmount + balanceAmount);
        setValue("caseDetailsBalancePending", remainingBalance); // Set remaining balance
        setValue("caseDetailsStatus", "pending"); // Mark as pending
      }
      if (Amount < amtReceived) {
        setValue("caseDetailsBalancePending", 0);
        setValue("caseDetailsStatus", "completed");
        setAmtError("Amount received more than total Amount");
      } else if (Amount >= amtReceived) {
        // setValue("caseDetailsBalancePending", "");
        // setValue("caseDetailsStatus", "pending");
        setAmtError("");
      }
    } else {
      if (Amount == Number(balanceAmt)) {
        setValue("caseDetailsBalancePending", 0);
        setValue("caseDetailsStatus", "completed");
      } else if (Number(balanceAmt) == 0) {
        setValue("caseDetailsBalancePending", Number(Amount));
        setValue("caseDetailsStatus", "pending");
      } else if (Number(Amount) - Number(balanceAmt) !== 0) {
        setValue(
          "caseDetailsBalancePending",
          Number(Amount) - Number(balanceAmt)
        );
        setValue("caseDetailsStatus", "pending");
      }
      if (Amount < amtReceived) {
        setValue("caseDetailsBalancePending", 0);
        setValue("caseDetailsStatus", "completed");
        setAmtError("Amount received more than total Amount");
      } else if (Amount >= amtReceived) {
        // setValue("caseDetailsBalancePending", "");
        // setValue("caseDetailsStatus", "pending");
        setAmtError("");
      }
    }
  }, [
    // state,
    balanceAmt,
    Amount,
    calclulateAmt,
    caseDetailsBalancePending,
    caseDetailsTotalAmount,
  ]);

  const { mutate, isLoading } = useMutation(
    (postData) =>
      axios.post(`${process.env.REACT_APP_HOST}/visitors/save`, postData),
    {
      onSuccess: (data) => {
        // setPostAlert(true);
        navigate("/clientList");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const upData = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/visitors/update/${state?.id}`,
        postData
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: () => {
        // setPutAlert(true);
        navigate("/clientList");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  const onSubmit = (data) => {
    const formData = {
      subjectDetails: {
        subjectName: data?.subjectName,
        subjectFatherName: data?.subjectFatherName,
        subjectDob: data?.subjectDob,
        subjectAge: data?.subjectAge,
        subjectReligion: data?.subjectReligion,
        subjectOtherReligion: data?.subjectOtherReligion,
        subjectCaste: data?.subjectCaste,
        subjectMobileNumber: data?.subjectMobileNumber,
        subjectAlternativeNumber: data?.subjectAlternativeNumber,
        subjectLandLineCode: data?.subjectLandLineCode,
        subjectWhatsapp: data?.subjectWhatsapp,
        subjectEmail: data?.subjectEmail,
        employement: data?.employement,
        subjectCategory: data?.subjectCategory,
        subjectOtherCategory: data?.subjectOtherCategory,
        subjectSourceForm: data?.subjectSourceForm,
        subjectOtherSourceForm: data?.subjectOtherSourceForm,
        vehicleDetails: data?.vehicleDetails,
        subjectAdditionalStatus: data?.subjectAdditionalStatus,
        subjectTemporaryAddress: data?.subjectTemporaryAddress,
        subjectOfficeAddress: data?.subjectOfficeAddress,
        subjectOfficeAddNo: data?.subjectOfficeAddNo,
        subjectOfficeAdd1No: data?.subjectOfficeAdd1No,
        subjectOfficeAddEmail: data?.subjectOfficeAddEmail,
        subjectOfficeAdditionalDetails: data?.subjectOfficeAdditionalDetails,
        subjectPermanentAddress: data?.subjectPermanentAddress,
        subjectPermanentAddNo: data?.subjectPermanentAddNo,
        subjectPermanentAddEmail: data?.subjectPermanentAddEmail,
        subjectNote1: data?.subjectNote1,
        subjectPermanentAdditionalDetails:
          data?.subjectPermanentAdditionalDetails,
      },
      // subjectDetailstwo: subjectDetails,
      clientInformation: {
        clientName: data?.clientName,
        age: data?.age,
        mobileNumber: data?.mobileNumber,
        alternativeMobileNumber: data?.alternativeMobileNumber,
        email: data?.email,
        address: data?.clientAddress,
        fatherName: data?.fatherName,
        marrielStatus: data?.clientMarried,
        guardianOrParent: data?.clientGuardian,
        education: data?.education,
        landLineCode: data?.landLineCode,
      },
      caseDetails: {
        caseNumber: data?.caseDetailsName,
        natureOfCareTitle: data?.caseDetailsNatureOfCaseTitle,
        natureOfCareClientReq: data?.natureOfCaseClientRequirement,
        date: data?.caseDetailsDate
          ? moment(data.caseDetailsDate).format("DD/MM/YYYY")
          : undefined,
        natureOfCareDetails: data?.natureOfCareDetails,
        totalAmount: data?.caseDetailsTotalAmount,
        chiefComment: data?.chiefComment,
        paymentCatagory: data?.paymentCatagory,
        dateOfRegards: data?.subjectDateRegards
          ? moment(data?.dateOfRegards).format("DD/MM/YYYY")
          : undefined,
        dateOfClosed: data?.subjectDateClose
          ? moment(data?.dateOfClosed).format("DD/MM/YYYY")
          : undefined,

        amountReceived:
          state?.caseDetails?.amountReceived == null // Handles both undefined and null
            ? data?.caseDetailsAmountReceived != null // Check if data is not null
              ? [Number(data?.caseDetailsAmountReceived)]
              : []
            : Array.isArray(state?.caseDetails?.amountReceived)
            ? state?.caseDetails?.amountReceived.concat(
                data?.caseDetailsAmountReceived != null
                  ? Number(data?.caseDetailsAmountReceived)
                  : []
              )
            : [],

        balancePending:
          state?.caseDetails?.balancePending == null // Handles both undefined and null
            ? data?.caseDetailsBalancePending != 0 // Ensure data is neither null nor undefined
              ? [Number(data?.caseDetailsBalancePending)]
              : []
            : Array.isArray(state?.caseDetails?.balancePending)
            ? state?.caseDetails?.balancePending.concat(
                data?.caseDetailsBalancePending != 0
                  ? Number(data?.caseDetailsBalancePending)
                  : []
              )
            : [],

        status:
          state?.caseDetails?.status == undefined
            ? [data?.caseDetailsStatus]
            : state?.caseDetails?.status &&
              state?.caseDetails?.status.concat(data?.caseDetailsStatus),
        amountDate:
          state?.caseDetails?.amountDate == undefined
            ? [data?.caseDetailsAmountDate]
            : state?.caseDetails?.amountDate &&
              state?.caseDetails?.amountDate.concat(
                data?.caseDetailsAmountDate
              ),
        paymentType:
          state?.caseDetails?.paymentType == undefined
            ? [data?.caseDetailsPaymentType]
            : state?.caseDetails?.paymentType &&
              state?.caseDetails?.paymentType.concat(
                data?.caseDetailsPaymentType
              ),
      },
      referenceDetails: {
        reference: data?.reference,
        nameOfLos: data?.referenceNameOfLos,
        date: data?.referenceData
          ? moment(data?.referenceData).format("DD/MM/YYYY")
          : undefined,
        fileNumber: data?.referenceFileNumber,
        reasonForClosure: data?.reasonForClosure,
        confReport: data?.referenceConfReport,
      },
      subjectDetailstwo: subjectDetailstwo,
    };
    console.log("formData", formData);
    if (subjectTemporaryAddress) {
      setPostAlert(formData);
    } else {
      setAddressAlert("Enter visitor Address");
    }
    setAlsoWhatApp(true);
  };

  // function calculateAge(birthDate, index) {
  //   console.log("birthDate", moment().diff(moment(birthDate), "years"), index);
  //   setValue("subjectAge", moment().diff(moment(birthDate), "years") || "");
  // }
  function calculateClientAge(birthDate, index) {
    console.log("birthDate", moment().diff(moment(birthDate), "years"), index);
    setValue("age", moment().diff(moment(birthDate), "years") || "");
  }

  const mobileCheckBox = (e) => {
    const checked = e.target.checked;

    if (checked) {
      console.log("checked", checked, subjectMobileNumber);
      setValue("subjectWhatsapp", subjectMobileNumber);
      setAlsoWhatApp(true);
    } else {
      setValue("subjectWhatsapp", "");
      setAlsoWhatApp(false);
    }
  };
  const mobileNumberCheckBox = (e, index) => {
    const checked = e.target.checked;

    if (checked) {
      console.log("checked", checked, index);
      subjectDetailstwo?.forEach((user, idx) => {
        // setValue("whatsapp", user.mobileNumber);
        setValue(
          `subjectDetailstwo.${index}.subjectWhatsapp`,
          user.subjectMobileNumber
        );
      });
      setAlsoWhatApp(true);
    } else {
      subjectDetailstwo?.forEach((user, idx) => {
        setValue(`subjectDetailstwo.${index}.subjectWhatsapp`, " ");
      });
      setAlsoWhatApp(false);
    }
  };

  function calculateAge(birthDate, index) {
    if (!birthDate) return;

    const age = moment().diff(moment(birthDate), "years") || "";

    if (index >= 0) {
      setValue(`subjectDetailstwo.${index}.subjectAge`, age);
    } else {
      setValue("subjectAge", age);
    }
  }

  const calculateDOB = (age, index) => {
    if (age) {
      const year = new Date().getFullYear() - age; // Calculate birth year
      const dob = set(new Date(year, 0, 1), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }); // Set DOB to 01/01/YYYY

      if (index >= 0) {
        setValue(`subjectDetailstwo.${index}.subjectDob`, dob);
      } else {
        setValue("subjectDob", dob);
      }
    } else {
      if (index >= 0) {
        setValue(`subjectDetailstwo.${index}.subjectDob`, "");
      } else {
        setValue("subjectDob", "");
      }
    }
  };
  useEffect(() => {
    if (state && state) {
      // remove();
      // console.log("state?.userForm", state?.userForm);

      setValue("subjectName", state?.subjectDetails?.subjectName);
      setValue(
        "subjectFatherName",
        state?.subjectDetails?.subjectFatherName || ""
      );
      setValue("subjectDob", state?.subjectDetails?.subjectDob || "");
      setValue("subjectAge", state?.subjectDetails?.subjectAge || "");
      setValue("subjectReligion", state?.subjectDetails?.subjectReligion || "");

      setValue(
        "subjectOtherReligion",
        state?.subjectDetails?.subjectOtherReligion || ""
      );
      setValue("subjectCaste", state?.subjectDetails?.subjectCaste || "");
      setValue(
        "subjectMobileNumber",
        state?.subjectDetails?.subjectMobileNumber || ""
      );
      setValue(
        "subjectAlternativeNumber",
        state?.subjectDetails?.subjectAlternativeNumber || ""
      );
      setValue(
        "subjectLandLineCode",
        state?.subjectDetails?.subjectLandLineCode || ""
      );
      setValue("subjectWhatsapp", state?.subjectDetails?.subjectWhatsapp || "");
      setValue("subjectEmail", state?.subjectDetails?.subjectEmail || "");
      setValue("subjectCategory", state?.subjectDetails?.subjectCategory || "");
      setValue(
        "subjectOtherCategory",
        state?.subjectDetails?.subjectOtherCategory || ""
      );
      setValue(
        "subjectSourceForm",
        state?.subjectDetails?.subjectSourceForm || ""
      );
      setValue(
        "subjectOtherSourceForm",
        state?.subjectDetails?.subjectOtherSourceForm || ""
      );
      setValue("vehicleDetails", state?.subjectDetails?.vehicleDetails || "");
      setValue(
        "subjectAdditionalStatus",
        state?.subjectDetails?.subjectAdditionalStatus || ""
      );
      setValue(
        "subjectTemporaryAddress",
        state?.subjectDetails?.subjectTemporaryAddress || ""
      );
      setValue(
        "subjectOfficeAddress",
        state?.subjectDetails?.subjectOfficeAddress || ""
      );
      setValue(
        "subjectOfficeAddNo",
        state?.subjectDetails?.subjectOfficeAddNo || ""
      );
      setValue(
        "subjectOfficeAdd1No",
        state?.subjectDetails?.subjectOfficeAdd1No || ""
      );
      setValue(
        "subjectOfficeAddEmail",
        state?.subjectDetails?.subjectOfficeAddEmail || ""
      );
      setValue(
        "subjectOfficeAdditionalDetails",
        state?.subjectDetails?.subjectOfficeAdditionalDetails || ""
      );
      setValue(
        "subjectPermanentAddress",
        state?.subjectDetails?.subjectPermanentAddress || ""
      );
      setValue(
        "subjectPermanentAddNo",
        state?.subjectDetails?.subjectPermanentAddNo || ""
      );
      // setValue("vehicleDetails", state?.subjectDetails?.vehicleDetails || "");
      setValue(
        "subjectPermanentAddEmail",
        state?.subjectDetails?.subjectPermanentAddEmail || ""
      );
      setValue("subjectNote1", state?.subjectDetails?.subjectNote1 || "");
      setValue(
        "subjectPermanentAdditionalDetails",
        state?.subjectPermanentAdditionalDetails || ""
      );
      setValue("employement", state?.subjectDetails?.employement);
      setAlsoPermanent(true);
      // clientInformation
      setValue("clientName", state?.clientInformation?.clientName);
      setValue("education", state?.clientInformation?.education);
      setValue("landLineCode", state?.clientInformation?.landLineCode);
      setValue("age", state?.clientInformation?.age);
      setValue("mobileNumber", state?.clientInformation?.mobileNumber);
      setValue("CheckNumber", state?.clientInformation?.mobileNumber);
      setValue(
        "alternativeMobileNumber",
        state?.clientInformation?.alternativeMobileNumber
      );
      setValue("email", state?.clientInformation?.email);
      setValue("clientAddress", state?.clientInformation?.address);
      setValue("fatherName", state?.clientInformation?.fatherName);
      setValue("clientMarried", state?.clientInformation?.marrielStatus);
      setValue("clientGuardian", state?.clientInformation?.guardianOrParent);
      // case details
      setValue("caseDetailsName", state?.caseDetails?.caseNumber);
      setValue(
        "caseDetailsNatureOfCaseTitle",
        state?.caseDetails?.natureOfCareTitle
      );
      setValue(
        "natureOfCaseClientRequirement",
        state?.caseDetails?.natureOfCareClientReq
      );
      setValue("chiefComment", state?.caseDetails?.chiefComment);
      setValue(
        "caseDetailsDate",
        state?.caseDetails?.date !== null
          ? moment(state?.caseDetails?.date, "DD-MM-YYYY").format("MM/DD/YYYY")
          : ""
      );
      setValue(
        "subjectDateRegards",
        state?.caseDetails?.dateOfRegards !== null
          ? moment(state?.caseDetails?.dateOfRegards, "DD-MM-YYYY").format(
              "MM/DD/YYYY"
            )
          : ""
      );
      setValue(
        "subjectDateClose",
        state?.caseDetails?.dateOfClosed !== null
          ? moment(state?.caseDetails?.dateOfClosed, "DD-MM-YYYY").format(
              "MM/DD/YYYY"
            )
          : ""
      );
      setValue("natureOfCareDetails", state?.caseDetails?.natureOfCareDetails);
      setValue("caseDetailsTotalAmount", state?.caseDetails?.totalAmount);
      setValue("chiefComment", state?.caseDetails?.chiefComment);
      setValue("caseDetailsBalancePending", state?.caseDetails?.balancePending);
      setValue("caseDetailsStatus", state?.caseDetails?.status);
      // setValue("caseDetailsPaymentType", state?.caseDetails?.paymentType);
      setValue("paymentCatagory", state?.caseDetails?.paymentCatagory);
      // referenceDetails
      // console.log(
      //   "state?.caseDetails?.amountDate",
      //   state?.caseDetails?.amountDate[0] !== null
      // );

      setValue("reference", state?.referenceDetails?.reference);
      setValue("referenceNameOfLos", state?.referenceDetails?.nameOfLos);
      // setValue("caseDetailsAmountDate", new Date());

      setValue(
        "referenceData",
        state?.referenceDetails?.date !== null
          ? moment(state?.referenceDetails?.date, "DD-MM-YYYY").format(
              "MM/DD/YYYY"
            )
          : ""
      );
      setValue("referenceFileNumber", state?.referenceDetails?.fileNumber);
      setValue("reasonForClosure", state?.referenceDetails?.reasonForClosure);

      setValue("referenceConfReport", state?.referenceDetails?.confReport);
      if (state?.subjectDetailstwo) {
        append();
        remove();
        state?.subjectDetailstwo?.forEach((value, index) => {
          setValue(
            `subjectDetailstwo.${index}.subjectName`,
            value && value?.subjectName
          );
          setValue(
            `subjectDetailstwo.${index}.subjectFatherName`,
            value && value?.subjectFatherName
          );
          setValue(
            `subjectDetailstwo.${index}.subjectDob`,
            value && value?.subjectDob
          );
          setValue(
            `subjectDetailstwo.${index}.subjectAge`,
            value && value?.subjectAge
          );
          setValue(
            `subjectDetailstwo.${index}.employement`,
            value && value?.employement
          );
          setValue(
            `subjectDetailstwo.${index}.subjectMobileNumber`,
            value && value?.subjectMobileNumber
          );
          // setValue(`subjectDetailstwo.${index}.subjectWhatsapp`, value && value?.subjectWhatsapp);
          setValue(
            `subjectDetailstwo.${index}.subjectLandLineCode`,
            value && value?.subjectLandLineCode
          );
          setValue(
            `subjectDetailstwo.${index}.subjectAlternativeNumber`,
            value && value?.subjectAlternativeNumber
          );
          setValue(
            `subjectDetailstwo.${index}.subjectEmail`,
            value && value?.subjectEmail
          );
          setValue(
            `subjectDetailstwo.${index}.subjectReligion`,
            value && value?.subjectReligion
          );
          setValue(
            `subjectDetailstwo.${index}.subjectOtherReligion`,
            value && value?.subjectOtherReligion
          );
          setValue(
            `subjectDetailstwo.${index}.subjectCaste`,
            value && value?.subjectCaste
          );
          setValue(
            `subjectDetailstwo.${index}.vehicleDetails`,
            value && value?.vehicleDetails
          );
          setValue(
            `subjectDetailstwo.${index}.subjectAdditionalStatus`,
            value && value?.subjectAdditionalStatus
          );
          setValue(
            `subjectDetailstwo.${index}.subjectNote1`,
            value && value?.subjectNote1
          );
          setValue(
            `subjectDetailstwo.${index}.subjectTemporaryAddress`,
            value && value?.subjectTemporaryAddress
          );
          setValue(
            `subjectDetailstwo.${index}.subjectOfficeName`,
            value && value?.subjectOfficeName
          );
          setValue(
            `subjectDetailstwo.${index}.subjectOfficeAddress`,
            value && value?.subjectOfficeAddress
          );
          setValue(
            `subjectDetailstwo.${index}.subjectOfficeAddNo`,
            value && value?.subjectOfficeAddNo
          );
          setValue(
            `subjectDetailstwo.${index}.subjectOfficeAdd1No`,
            value && value?.subjectOfficeAdd1No
          );
          setValue(
            `subjectDetailstwo.${index}.subjectOfficeAddEmail`,
            value && value?.subjectOfficeAddEmail
          );
          setValue(
            `subjectDetailstwo.${index}.subjectOfficeAdditionalDetails`,
            value && value?.subjectOfficeAdditionalDetails
          );
          setValue(
            `subjectDetailstwo.${index}.subjectPermanentFamilyName`,
            value && value?.subjectPermanentFamilyName
          );
          setValue(
            `subjectDetailstwo.${index}.subjectPermanentAddress`,
            value && value?.subjectPermanentAddress
          );
          setValue(
            `subjectDetailstwo.${index}.subjectPermanentAddNo`,
            value && value?.subjectPermanentAddNo
          );
          setValue(
            `subjectDetailstwo.${index}.subjectPermanentAddEmail`,
            value && value?.subjectPermanentAddEmail
          );
          setValue(
            `subjectDetailstwo.${index}.subjectPermanentAdditionalDetails`,
            value && value?.subjectPermanentAdditionalDetails
          );
        });
      }
    }
  }, [state, setValue]);

  return (
    <div className="mainContainerDiv" style={{ overflow: "scroll" }}>
      <section>
        <div className="headerContainer">
          <header className="visitorEntryHeader">Convert to Client</header>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex align-items-center py-4">
              {clientDetails || state ? (
                <div>
                  <div className="visitorEntryFormInput">
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Client Name
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="clientName"
                        render={(field) => (
                          <InputField
                            readOnly={true}
                            visiterInfoCss="visiterInfoCss"
                            {...field}
                            type="text"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                        S/o or D/o / other</label>
                      </div>
                      <Controller
                        control={control}
                        name="fatherName"
                        render={(field) => (
                          <InputField
                            readOnly={true}
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="text"
                          />
                        )}
                      />
                    </div>

                    <div style={{ width: "18%", display: "flex" }}>
                      <div
                        className="visitorEntryInputContainer"
                        style={{ width: "78%", marginRight: "2%" }}
                      >
                        <div>
                          <label className="visitorInfoLabelContainer">
                            DOB
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="dob"
                          render={({ field }) => (
                            <DatePicker
                              readOnly
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              className="visiterInfoCss rounded-start"
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={95} // Includes the current year + 40 previous years
                              selected={field.value}
                              maxDate={new Date()} // Restrict to current date and earlier
                              minDate={subYears(new Date(), 95)} // Restrict to 40 years before the current date
                              onChange={(date) => {
                                field.onChange(date);
                                calculateClientAge(date);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div
                        className="visitorEntryInputContainer"
                        style={{ width: "20%" }}
                      >
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Age
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="age"
                          render={(field) => (
                            <InputField
                              readOnly
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="number"
                            />
                          )}
                          // rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Gender
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="gender"
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <select
                            disabled
                            style={error ? { borderColor: "red" } : {}}
                            value={value}
                            className="visiterInfoCss py-2"
                            onChange={onChange}
                            onBlur={onBlur} // Added onBlur for better form validation handling
                            ref={ref} // Make sure the ref is passed down for proper field tracking
                          >
                            <option value=""> </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Gay">Gay</option>
                            <option value="Lesbian">Lesbian</option>
                            <option value="Other">Other</option>
                          </select>
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                    {gender === "Other" && (
                      <div className="visitorEntryInputContainer">
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Other Gender
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="otherGender"
                          render={(field) => (
                            <InputField
                              readOnly={true}
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="text"
                            />
                          )}
                        />
                      </div>
                    )}

                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Education
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="education"
                        render={(field) => (
                          <InputField
                            readOnly={true}
                            visiterInfoCss="visiterInfoCss"
                            {...field}
                            type="text"
                          />
                        )}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Qualification
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="qualification"
                        render={(field) => (
                          <InputField
                            readOnly={true}
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="text"
                          />
                        )}
                      />
                    </div>

                    <div className="visitorInfoMobile">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Mobile Number
                        </label>
                      </div>
                      <Controller
                        key={
                          alsoWhatApp
                            ? "whatsapp-synced"
                            : "whatsapp-independent"
                        }
                        control={control}
                        name="mobileNumber"
                        rules={{
                          required: ".",
                          minLength: {
                            value: 10,
                            message: "Mobile number must be 10 digits",
                          },
                          maxLength: {
                            value: 10,
                            message: "Mobile number must be 10 digits",
                          },
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message:
                              "Please enter a valid 10-digit mobile number",
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <PinInput
                              readOnly
                              length={10}
                              initialValue={value || ""}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              type="numeric"
                              style={{ width: "100%", borderRadius: "5px" }}
                              inputStyle={{
                                ...{
                                  border: "1px solid gray",
                                  backgroundColor: "#fff",
                                  height: "40px",
                                  width: "10%",
                                  margin: "0px",
                                  borderRadius: "5px",
                                },
                                ...(error ? { borderColor: "red" } : {}),
                              }}
                              autoSelect
                              onComplete={(value, index) => {
                                console.log("onComplete", value);
                                setMobileNumberData(value);
                              }}
                            />
                            {error && (
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginTop: "5px",
                                }}
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div className="visitorInfoMobile">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          WhatsApp Number
                        </label>
                      </div>
                      <Controller
                        key={
                          alsoWhatApp
                            ? "whatsapp-synced"
                            : "whatsapp-independent"
                        } // Unique key for re-rendering
                        control={control}
                        name="whatsapp"
                        rules={{
                          minLength: {
                            value: 10,
                            message: "Mobile number must be 10 digits", // Error message when length is less than 10
                          },
                          maxLength: {
                            value: 10,
                            message: "Mobile number must be 10 digits", // Error message when length is more than 10
                          },
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message:
                              "Please enter a valid 10-digit mobile number", // Ensure it's numeric
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <PinInput
                            readOnly
                            length={10}
                            initialValue={value}
                            value={value || ""}
                            onChange={(val) => onChange(val)}
                            type="numeric"
                            style={{ width: "100%", borderRadius: "5px" }}
                            inputStyle={{
                              border: "1px solid gray",
                              backgroundColor: "#fff",
                              height: "40px",
                              width: "10%",
                              margin: "0px",
                              borderRadius: "5px",
                            }}
                            autoSelect
                          />
                        )}
                      />
                      <div
                        style={{ fontSize: "12px", cursor: "pointer" }}
                        className="d-flex pt-1 justify-content-end"
                      >
                        <label className="d-flex gap-1 align-items-center">
                          <input
                            readOnly
                            onChange={(e) => {
                              // mobileNumberCheckBox(e);
                              mobileCheckBox(e);
                            }}
                            type="checkbox"
                            style={{ width: "15px", height: "15px" }}
                          />
                          Also Same As Above
                        </label>
                      </div>
                    </div>
                    <div style={{ width: "18%", display: "flex" }}>
                      <div
                        className="visitorEntryInputContainer"
                        style={{ width: "20%" }}
                      >
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Code
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="landLineCode"
                          render={(field) => (
                            <InputField
                              readOnly={true}
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="text"
                            />
                          )}
                        />
                      </div>
                      <div
                        className="visitorEntryInputContainer"
                        style={{ width: "78%", marginLeft: "2%" }}
                      >
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Landline Number
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="alternativeMobileNumber"
                          render={(field) => (
                            <InputField
                              readOnly={true}
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="number"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Caste
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="caste"
                        render={(field) => (
                          <InputField
                            readOnly={true}
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="text"
                          />
                        )}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Religion
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="religion"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            disabled
                            value={value}
                            className="visiterInfoCss py-2"
                            onChange={onChange}
                            onBlur={onBlur} // Added onBlur for better form validation handling
                            ref={ref} // Make sure the ref is passed down for proper field tracking
                          >
                            <option value=""> </option>
                            <option value="Christianity">Christianity</option>
                            <option value="Islam">Islam</option>
                            <option value="Hinduism">Hinduism</option>
                            <option value="Other">Other</option>
                          </select>
                        )}
                      />
                    </div>
                    {religion === "Other" && (
                      <div className="visitorEntryInputContainer">
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Other Religion
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="otherReligion"
                          render={(field) => (
                            <InputField
                              readOnly={true}
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="text"
                            />
                          )}
                        />
                      </div>
                    )}

                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Category
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="category"
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <select
                            disabled
                            className="visiterInfoCss"
                            onChange={onChange}
                            value={value}
                            style={error ? { borderColor: "red" } : {}}
                          >
                            <option value=" "> </option>
                            <option value="Visitor">Visitor</option>
                            <option value="Client">Client</option>
                            <option value="Perspective">Perspective</option>
                            <option value="Business">Business</option>
                            <option value="Interview">Interview</option>

                            <option value="Other">Other</option>
                          </select>
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                    {category === "Other" && (
                      <div className="visitorEntryInputContainer">
                        <div>
                          <label className="visitorInfoLabelContainer">
                            other Category
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="otherCategory"
                          render={(field) => (
                            <InputField
                              readOnly={true}
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="text"
                            />
                          )}
                        />
                      </div>
                    )}
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Reference
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="sourceForm"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            readOnly
                            value={value}
                            className="visiterInfoCss"
                            onChange={onChange}
                          >
                            <option value=" "> </option>
                            <option value="JustDial">Just Dial</option>
                            <option value="YouTube">YouTube</option>
                            <option value="Google">Google</option>
                            <option value="Advertisement">Advertisement</option>
                            <option value="SocialMedia">Social Media</option>
                            <option value="Referred">Referred By</option>
                            <option value="Other">Other</option>
                          </select>
                        )}
                      />
                    </div>
                    {sourceForm === "Other" && (
                      <div className="visitorEntryInputContainer">
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Other Reference
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="otherSourceForm"
                          render={(field) => (
                            <InputField
                              readOnly={true}
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="text"
                            />
                          )}
                        />
                      </div>
                    )}
                    {sourceForm === "SocialMedia" && (
                      <div className="visitorEntryInputContainer">
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Social Media Name
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="otherSourceForm"
                          render={(field) => (
                            <InputField
                              readOnly={true}
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="text"
                            />
                          )}
                        />
                      </div>
                    )}
                    {sourceForm === "Referred" && (
                      <div className="visitorEntryInputContainer">
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Reference By
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="otherSourceForm"
                          render={(field) => (
                            <InputField
                              readOnly={true}
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="text"
                            />
                          )}
                        />
                      </div>
                    )}

                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          E Mail
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="email"
                        render={(field) => (
                          <InputField
                            readOnly={true}
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="text"
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Married/ Unmarried
                        </label>
                      </div>
                      {/* <Controller
                        control={control}
                        name="clientMarried"
                        render={(field) => (
                          <InputField
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="text"
                          />
                        )}
                      /> */}
                      <Controller
                        control={control}
                        name="clientMarried"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            readOnly
                            value={value}
                            className="visiterInfoCss py-2"
                            onChange={onChange}
                            onBlur={onBlur} // Added onBlur for better form validation handling
                            ref={ref} // Make sure the ref is passed down for proper field tracking
                          >
                            <option value=""> </option>
                            <option value="Married">Married</option>
                            <option value="Unmarried">Unmarried</option>
                          </select>
                        )}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Relation with the subject
                        </label>
                      </div>

                      <Controller
                        control={control}
                        name="clientGuardian"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            value={value}
                            className="visiterInfoCss py-2"
                            onChange={onChange}
                            onBlur={onBlur} // Added onBlur for better form validation handling
                            ref={ref} // Make sure the ref is passed down for proper field tracking
                          >
                            <option value=""> </option>
                            <option value="Guardian">Guardian</option>
                            <option value="Spouse">Spouse</option>
                            <option value="Parent">Parent</option>
                            <option value="Children">Children</option>
                            <option value="Friend">Friend</option>
                          </select>
                        )}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Date of visit
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="dateOfVisit"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <DatePicker
                            readOnly
                            dateFormat="dd/MM/yyyy eeee"
                            placeholderText="DD/MM/YYYY"
                            className={`visiterInfoCss ${
                              error && "border border-danger"
                            } `}
                            selected={value}
                            onChange={(date) => onChange(date)}
                          />
                        )}
                        // rules={{ required: true }}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Date of Entry
                        </label>
                      </div>

                      <Controller
                        control={control}
                        name="dateOfAppointment"
                        defaultValue={moment().toDate()}
                        render={({ field }) => (
                          <DatePicker
                            readOnly
                            dateFormat="dd/MM/yyyy eeee" // Format the date to display (e.g., Mon 06/11/2024)
                            className="visiterInfoCss"
                            // readOnly
                            selected={
                              field.value ? moment(field.value).toDate() : null
                            } // Convert moment to native Date
                            onChange={(date) => field.onChange(moment(date))} // Convert selected date to moment
                            minDate={moment().toDate()}
                            maxDate={moment().toDate()}
                            disabled={false}
                          />
                        )}
                      />
                    </div>

                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Status
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="status"
                        defaultValue="Active"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            disabled
                            className="visiterInfoCss"
                            value={value}
                            onChange={onChange}
                          >
                            <option value=""></option>
                            <option value="Active">Active</option>
                            <option value="In Active"> In Active</option>
                          </select>
                        )}
                      />
                    </div>
                    <div className="d-flex" style={{ width: "18%" }}>
                      <div
                        className="visitorEntryInputContainer"
                        style={{ width: "50%" }}
                      >
                        <div>
                          <label className="visitorInfoLabelContainer">
                            In Time
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="inTime"
                          render={({ field, fieldState: { error } }) => (
                            <input
                              readOnly
                              {...field}
                              type="time"
                              className={`visiterInfoCss ${
                                error && "border border-danger"
                              } `}
                            />
                          )}
                          // rules={{ required: true }}
                        />
                      </div>
                      <div
                        className="visitorEntryInputContainer"
                        style={{ width: "50%" }}
                      >
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Out Time
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="outTime"
                          render={({ field, fieldState: { error } }) => (
                            <input
                              readOnly
                              {...field}
                              type="time"
                              className={`visiterInfoCss ${
                                error && "border border-danger"
                              } `}
                              // Set the min value dynamically based on In Time
                              min={inTime || ""}
                            />
                          )}
                          // rules={{ required: true }}
                        />
                      </div>
                    </div>

                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Purpose of visit
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="purposeOfVisit"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <textarea
                            ref={ref}
                            style={{ height: "40px", width: "100%",fontSize:"14px" }}
                            value={value}
                            className="visiterInfoCss  "
                            onFocus={() =>
                              handleFocus(
                                "purposeOfVisit",
                                "Purpose of visit",
                                value
                              )
                            }
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleChange("purposeOfVisit", e.target.value);
                            }}
                            onBlur={onBlur}
                            disabled={isModalOpen}
                          ></textarea>
                        )}
                      />
                    </div>
                    <div
                      className="visitorEntryInputContainer"
                      // style={{ width: "18%", height: "50px" }}
                    >
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Additional Particulars
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="additionalStatus"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <textarea
                            ref={ref}
                            style={{ height: "40px", width: "100%",fontSize:"14px" }}
                            value={value}
                            className="visiterInfoCss"
                            onFocus={() =>
                              handleFocus(
                                "additionalStatus",
                                "Additional Particulars",
                                value
                              )
                            }
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleChange("additionalStatus", e.target.value);
                            }}
                            onBlur={onBlur}
                            disabled={isModalOpen}
                          ></textarea>
                        )}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Note 1
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="visitorNote1"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <textarea
                            readOnly
                            ref={ref}
                            style={{ height: "40px", width: "100%",fontSize:"14px" }}
                            value={value}
                            className="visiterInfoCss "
                            onFocus={() =>
                              handleFocus("visitorNote1", "Note 1", value)
                            }
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleChange("visitorNote1", e.target.value);
                            }}
                            onBlur={onBlur}
                            disabled={isModalOpen}
                          ></textarea>
                        )}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Specific Note
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="visitorNote2"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <textarea
                            ref={ref}
                            style={{ height: "40px", width: "100%",fontSize:"14px" }}
                            value={value}
                            className="visiterInfoCss "
                            onFocus={() =>
                              handleFocus(
                                "visitorNote2",
                                "Specific Note",
                                value
                              )
                            }
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleChange("visitorNote2", e.target.value);
                            }}
                            onBlur={onBlur}
                            disabled={isModalOpen}
                          ></textarea>
                        )}
                      />
                    </div>
                    <div className="" style={{ width: "18%" }}>
                      <div
                        style={
                          clientAddress
                            ? {
                                backgroundColor: "green",
                                color: "white",
                                cursor: "pointer",
                                width: "100%",
                                height: "40px",
                                alignContent: "center",
                              }
                            : {
                                backgroundColor: "red",
                                color: "white",
                                cursor: "pointer",
                                width: "100%",
                                height: "40px",
                                alignContent: "center",
                              }
                        }
                        title={clientAddress || "No address available"}
                        className="visitorInfoShowBtn mb-0 fs-6 px-2 pb-0 text-center"
                        onClick={() => {
                          setClientPresentAdd(true);
                        }}
                      >
                        Present Address<span style={{ color: "white" }}>*</span>
                      </div>
                      <div>
                        <p
                          style={{
                            alignContent: "center",
                            marginLeft: "10px",
                            color: "red",
                          }}
                        >
                          {addressAlert && "Enter visitor Address"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex" style={{ width: "18%" }}>
                      <div
                        className="visitorInfoShowBtn fs-6 px-2 text-center"
                        title={officeAddress || "No address available"}
                        style={
                          officeName ||
                          officeAddNo ||
                          officeAdd1No ||
                          officeAddEmail ||
                          officeAdditionalDetails ||
                          officeAddress
                            ? {
                                backgroundColor: "green",
                                color: "white",
                                cursor: "pointer",
                                width: "100%",
                                height: "40px",
                                alignContent: "center",
                              }
                            : {
                                backgroundColor: "red",
                                color: "white",
                                cursor: "pointer",
                                width: "100%",
                                height: "40px",
                                alignContent: "center",
                              }
                        }
                        onClick={() => {
                          setClientOfficeAdd(true);
                        }}
                      >
                        {/* Office Address & Permanent Address */}
                        Office Address
                      </div>
                    </div>
                    <div className="d-flex" style={{ width: "18%" }}>
                      <div
                        className="visitorInfoShowBtn fs-6 px-2 text-center"
                        title={permanentAddress || "No address available"}
                        style={
                          permanentFamilyName ||
                          permanentAddNo ||
                          permanentAddEmail ||
                          permanentAdditionalDetails ||
                          permanentAddress
                            ? {
                                backgroundColor: "green",
                                color: "white",
                                cursor: "pointer",
                                width: "100%",
                                height: "40px",
                                alignContent: "center",
                              }
                            : {
                                backgroundColor: "red",
                                color: "white",
                                cursor: "pointer",
                                width: "100%",
                                height: "40px",
                                alignContent: "center",
                              }
                        }
                        onClick={() => {
                          setClientPermanentAdd(true);
                        }}
                      >
                        Permanent Address
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-3">
                  <div className="ms-2 visitorInfoMobile">
                    <label className="visitorInfoLabelContainer">
                      Name of the visitor<span style={{ color: "red" }}>*</span>
                    </label>
                    <Controller
                      control={control}
                      name="CheckName"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          value={value && value ? value : ""}
                          onChange={(event, newValue) => {
                            onChange(newValue);
                          }}
                          style={error ? { borderColor: "red" } : {}}
                          className="visiterInfoCss css-2avex9-MuiAutocomplete-root .MuiOutlinedInput-root"
                          disablePortal
                          id="combo-box-demo"
                          options={
                            visitorData
                              ? visitorData?.map((data) => {
                                  return (
                                    data.name +
                                    (data?.fatherName !== null
                                      ? " : " + data?.fatherName
                                      : "")
                                  );
                                })
                              : []
                          }
                          sx={{ height: 40 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={error ? { borderColor: "red" } : {}}
                              value={value && value ? value : "none"}
                              onChange={(e) => onChange(e.target.value)}
                              error={!!error}
                            />
                          )}
                        />
                      )}
                      rules={{ required: true }}
                    />
                    {alsoPermanent === "Value" && <p>CheckClientNumber</p>}
                  </div>
                  <div className="ms-2 visitorInfoMobile">
                    <label className="visitorInfoLabelContainer">
                      Mobile/Whatsapp No<span style={{ color: "red" }}>*</span>
                    </label>
                    <Controller
                      control={control}
                      name="CheckNumber"
                      rules={{
                        required: ".",
                        minLength: {
                          value: 10,
                          message: "Mobile number must be 10 digits", // Error message when length is less than 10
                        },
                        maxLength: {
                          value: 10,
                          message: "Mobile number must be 10 digits", // Error message when length is more than 10
                        },
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message:
                            "Please enter a valid 10-digit mobile number", // Ensure it's numeric
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <PinInput
                          length={10}
                          initialValue={value}
                          value={value || ""}
                          onChange={(val) => onChange(val)}
                          type="numeric"
                          style={{ width: "100%", borderRadius: "5px" }}
                          inputStyle={{
                            ...{
                              border: "1px solid gray",
                              backgroundColor: "#fff",
                              height: "40px",
                              width: "10%",
                              margin: "0px",
                              borderRadius: "5px",
                            },
                            ...(error ? { borderColor: "red" } : {}),
                          }}
                          onComplete={(val) => {
                            clientNumberData(val);
                            console.log("completed");
                          }}
                          autoSelect
                        />
                      )}
                    />
                    {alsoPermanent === "Value" && <p>CheckClientNumber</p>}
                  </div>

                  <div className="ms-2 visitorInfoMobile">
                    <label className="visitorInfoLabelContainer">
                      General<span style={{ color: "red" }}>*</span>
                    </label>
                    <Controller
                      control={control}
                      name="CheckEmailId"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          value={value && value ? value : ""}
                          onChange={(event, newValue) => {
                            onChange(newValue);
                          }}
                          style={error ? { borderColor: "red" } : {}}
                          className="visiterInfoCss css-2avex9-MuiAutocomplete-root .MuiOutlinedInput-root"
                          disablePortal
                          id="combo-box-demo"
                          options={
                            visitorData
                              ? visitorData
                                  .map((data) => {
                                    console.log("visitorDataData", data);
                                    return (
                                      data.name + " : " + data?.temporaryAddress
                                    );
                                  })
                                  .filter(
                                    (address) =>
                                      address !== null && address !== undefined
                                  ) // Remove null or undefined values
                              : []
                          }
                          sx={{ height: 40 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={value && value ? value : "none"}
                              onChange={(e) => onChange(e.target.value)}
                              error={!!error}
                            />
                          )}
                        />
                      )}
                      rules={{ required: true }}
                    />
                    {alsoPermanent === "Value" && <p>CheckClientNumber</p>}
                  </div>
                </div>
              )}
            </div>

            <div className="" style={{ width: "100%" }}>
              <div className="headerContainer">
                <a
                  href="#submit"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <div className="visitorEntryHeader "> Case Registration</div>
                </a>
              </div>
              <div className="visitorEntryFormInput py-5">
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Case Number<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="caseDetailsName"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Reference
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="reference"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      {/* Nature of Case (Title) */}
                      Case File Name<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="caseDetailsNatureOfCaseTitle"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Nature of Case (Details)
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="natureOfCareDetails"
                    render={({ field: { value, onChange, ref } }) => (
                      <textarea
                        ref={ref}
                        value={value || ""}
                        style={{ height: "40px", width: "100%",fontSize:"14px" }}
                        className="visiterInfoCss "
                        onFocus={() =>
                          handleFocus(
                            "natureOfCareDetails",
                            "Nature of Care Details",
                            value
                          )
                        }
                        // onChange={onChange}
                        onChange={(e) => {
                          onChange(e.target.value);
                          handleChange("natureOfCareDetails", e.target.value);
                        }}
                        disabled={isModalOpen}
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      {/* (Client’s Requirement) */}
                      Requirement Of Client's
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="natureOfCaseClientRequirement"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      {/* Date of Regards */}
                      Date Of Registration
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectDateRegards"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        dateFormat="dd/MM/yyyy eeee"
                        placeholderText="DD/MM/YYYY"
                        className={`visiterInfoCss ${
                          error && "border border-danger"
                        } `}
                        selected={value}
                        onChange={(date) => onChange(date)}
                        isClearable
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={75}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Date of Closed
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectDateClose"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <DatePicker
                          placeholderText="DD/MM/YYYY"
                          dateFormat="dd/MM/yyyy eeee" // Correct format
                          className={`visiterInfoCss ${
                            error && "border border-danger"
                          } `}
                          selected={value}
                          onChange={(date) => onChange(date)}
                          isClearable
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={15}
                        />
                      </>
                    )}
                    // rules={{ required: true }}
                  />
                </div>
                <div
                  className="visitorEntryInputContainer"
                  style={{ width: "18%" }}
                >
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Reason of Closer
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="reasonForClosure"
                    render={({ field: { value, onChange, ref } }) => (
                      <textarea
                        ref={ref}
                        value={value || ""}
                        style={{ height: "40px", width: "100%",fontSize:"14px" }}
                        className="visiterInfoCss "
                        onFocus={() =>
                          handleFocus(
                            "reasonForClosure",
                            "Reason of Closed",
                            value
                          )
                        }
                        // onChange={onChange}
                        onChange={(e) => {
                          onChange(e.target.value);
                          handleChange("reasonForClosure", e.target.value);
                        }}
                        disabled={isModalOpen}
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      {/* Name of (IOS) */}
                      List Of Investications
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="referenceNameOfLos"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>

                <div className="visitorEntryInputContainer ">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Confidential Report
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="referenceConfReport"
                    render={({ field: { value, onChange, ref } }) => (
                      <textarea
                        ref={ref}
                        value={value || ""}
                        style={{ height: "40px", width: "100%",fontSize:"14px" }}
                        className="visiterInfoCss "
                        onFocus={() =>
                          handleFocus(
                            "referenceConfReport",
                            "Confidential Report",
                            value
                          )
                        }
                        // onChange={onChange}
                        onChange={(e) => {
                          onChange(e.target.value);
                          handleChange("referenceConfReport", e.target.value);
                        }}
                        disabled={isModalOpen}
                      />
                    )}
                  />
                </div>
                {/* </div> */}
              </div>
            </div>

            <div>
              <div className="headerContainer">
                <header id={`${0}form`} className="visitorEntryHeader">
                  {" "}
                  Subject Details
                </header>
              </div>
              <div className="visitorEntryFormInput">
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Name<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectName"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                    S/o or D/o / other</label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectFatherName"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div style={{ width: "18%", display: "flex" }}>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "78%", marginRight: "2%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">DOB</label>
                    </div>

                    <Controller
                      control={control}
                      name="subjectDob"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          className="visiterInfoCss rounded-start"
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={95} // Includes the current year + 40 previous years
                          selected={field.value}
                          maxDate={new Date()} // Restrict to current date and earlier
                          minDate={subYears(new Date(), 95)} // Restrict to 40 years before the current date
                          onChange={(date) => {
                            field.onChange(date);
                            calculateAge(date);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "20%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Age<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="subjectAge"
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                      }) => (
                        <input
                          style={error ? { borderColor: "red" } : {}}
                          className="visiterInfoCss"
                          type="number"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            calculateDOB(e.target.value);
                          }}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Education
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="employement"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>

                <div className="visitorInfoMobile">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Mobile Number<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <Controller
                    key={
                      alsoWhatApp ? "whatsapp-synced" : "whatsapp-independent"
                    }
                    control={control}
                    name="subjectMobileNumber"
                    rules={{
                      required: ".",
                      minLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits",
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits",
                      },
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit mobile number",
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <PinInput
                          length={10}
                          initialValue={value || ""}
                          onChange={(val) => {
                            onChange(val);

                            if (alsoWhatApp && val?.length === 10) {
                              setValue("whatsapp", val);
                            }
                          }}
                          type="numeric"
                          style={{ width: "100%", borderRadius: "5px" }}
                          inputStyle={{
                            ...{
                              border: "1px solid gray",
                              backgroundColor: "#fff",
                              height: "40px",
                              width: "10%",
                              margin: "0px",
                              borderRadius: "5px",
                            },
                            ...(error ? { borderColor: "red" } : {}),
                          }}
                          autoSelect
                          onComplete={(value, index) => {
                            console.log("onComplete", value);
                            setMobileNumberData(value);
                          }}
                        />
                        {error && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "5px",
                            }}
                          >
                            {error.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className="visitorInfoMobile">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      WhatsApp Number
                    </label>
                  </div>
                  <Controller
                    key={
                      alsoWhatApp ? "whatsapp-synced" : "whatsapp-independent"
                    } // Unique key for re-rendering
                    control={control}
                    name="subjectWhatsapp"
                    rules={{
                      minLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits", // Error message when length is less than 10
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits", // Error message when length is more than 10
                      },
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit mobile number", // Ensure it's numeric
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PinInput
                        length={10}
                        initialValue={value}
                        value={value || ""}
                        onChange={(val) => onChange(val)}
                        type="numeric"
                        style={{ width: "100%", borderRadius: "5px" }}
                        inputStyle={{
                          border: "1px solid gray",
                          backgroundColor: "#fff",
                          height: "40px",
                          width: "10%",
                          margin: "0px",
                          borderRadius: "5px",
                        }}
                        autoSelect
                      />
                    )}
                  />
                  <div
                    style={{ fontSize: "12px", cursor: "pointer" }}
                    className="d-flex pt-1 justify-content-end"
                  >
                    <label className="d-flex gap-1 align-items-center">
                      <input
                        onChange={(e) => {
                          // mobileNumberCheckBox(e);
                          mobileCheckBox(e);
                        }}
                        type="checkbox"
                        style={{ width: "15px", height: "15px" }}
                      />
                      Also Same As Above
                    </label>
                  </div>
                </div>
                <div style={{ width: "18%", display: "flex" }}>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "20%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">Code</label>
                    </div>
                    <Controller
                      control={control}
                      name="subjectLandLineCode"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "78%", marginLeft: "2%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Landline Number
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="subjectAlternativeNumber"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="number"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      E Mail<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectEmail"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="email"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Religion
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectReligion"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        className="visiterInfoCss py-2"
                        onChange={onChange}
                        onBlur={onBlur} // Added onBlur for better form validation handling
                        ref={ref} // Make sure the ref is passed down for proper field tracking
                      >
                        <option value=""> </option>
                        <option value="Christian">Christian</option>
                        <option value="Muslim">Muslim</option>
                        <option value="Hindu">Hindu</option>
                        <option value="Other">Other</option>
                      </select>
                    )}
                  />
                </div>
                {subjectReligion === "Other" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Other Religion
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="subjectOtherReligion"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Caste</label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectCaste"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Vehicle Details
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="vehicleDetails"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div
                  className="visitorEntryInputContainer"
                  // style={{ width: "18%", height: "50px" }}
                >
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Additional Status
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectAdditionalStatus"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        ref={ref}
                        style={{ height: "40px", width: "100%",fontSize:"14px" }}
                        value={value}
                        className="visiterInfoCss"
                        onFocus={() =>
                          handleFocus(
                            "subjectAdditionalStatus",
                            "Additional Status",
                            value
                          )
                        }
                        // onChange={(e) => {
                        //   onChange(e.target.value);
                        // }}
                        onChange={(e) => {
                          onChange(e.target.value);
                          handleChange(
                            "subjectAdditionalStatus",
                            e.target.value
                          );
                        }}
                        onBlur={onBlur}
                        disabled={isModalOpen}
                      ></textarea>
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Note 1</label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectNote1"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        ref={ref}
                        style={{ height: "40px", width: "100%",fontSize:"14px" }}
                        value={value}
                        className="visiterInfoCss"
                        onFocus={() =>
                          handleFocus("subjectNote1", "Note 1", value)
                        }
                        // onChange={(e) => {
                        //   onChange(e.target.value);
                        // }}
                        onChange={(e) => {
                          onChange(e.target.value);
                          handleChange("subjectNote1", e.target.value);
                        }}
                        onBlur={onBlur}
                        disabled={isModalOpen}
                      ></textarea>
                    )}
                  />
                </div>
                <div className="" style={{ width: "18%" }}>
                  <div
                    className="visitorInfoShowBtn mb-0 fs-6 px-2 text-center"
                    title={subjectTemporaryAddress || "No address available"}
                    style={
                      subjectTemporaryAddress
                        ? {
                            backgroundColor: "green",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                        : {
                            backgroundColor: "red",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                    }
                    onClick={() => {
                      setShowPresentAdd(true);
                      setAddress({ add: subjectTemporaryAddress });
                    }}
                  >
                    Present Address<span style={{ color: "white" }}>*</span>
                  </div>
                  <div>
                    <p
                      style={{
                        alignContent: "center",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {addressAlert && "Enter Subject Address"}
                    </p>
                  </div>
                </div>

                <div className="d-flex" style={{ width: "18%" }}>
                  <div
                    className="visitorInfoShowBtn fs-6 px-2 text-center"
                    title={subjectOfficeAddress || "No address available"}
                    style={
                      subjectOfficeAddNo ||
                      subjectOfficeAdd1No ||
                      subjectOfficeName ||
                      subjectOfficeAddEmail ||
                      subjectOfficeAdditionalDetails ||
                      subjectOfficeAddress
                        ? {
                            backgroundColor: "green",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                        : {
                            backgroundColor: "red",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                    }
                    onClick={() => {
                      setShowOfficeAdd(true);
                      setAddress({
                        name: subjectOfficeName,
                        add: subjectOfficeAddress,
                        mNo: subjectOfficeAddNo,
                        aNo: subjectOfficeAdd1No,
                        email: subjectOfficeAddEmail,
                        addInfo: subjectOfficeAdditionalDetails,
                      });
                    }}
                  >
                    {/* Office Address & Permanent Address */}
                    Office Address
                  </div>
                </div>
                <div className="d-flex" style={{ width: "18%" }}>
                  <div
                    className="visitorInfoShowBtn fs-6 px-2 text-center"
                    title={subjectPermanentAddress || "No address available"}
                    style={
                      subjectPermanentAddNo ||
                      subjectPermanentFamilyName ||
                      subjectPermanentAddEmail ||
                      subjectPermanentAdditionalDetails ||
                      subjectPermanentAddress
                        ? {
                            backgroundColor: "green",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                        : {
                            backgroundColor: "red",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                    }
                    onClick={() => {
                      setShowPermanentAdd(true);
                      setAddress({
                        name: subjectPermanentFamilyName,
                        add: subjectPermanentAddress,
                        mNo: subjectPermanentAddNo,
                        email: subjectPermanentAddEmail,
                        addInfo: subjectPermanentAdditionalDetails,
                      });
                    }}
                  >
                    Permanent Address
                  </div>
                </div>
                <div
                  style={{ width: "100%" }}
                  className="d-flex justify-content-center"
                >
                  {page && (
                    <div>
                      <a
                        href={`#${1}form`}
                        style={{
                          width: "210px",
                          textDecorationLine: "none",
                        }}
                        id="field"
                        onClick={() => {
                          // window.scrollTo(0,1000)
                          // append({}, { shouldFocus: false });
                        }}
                        className="visitorInfoSaveBtn px-2 py-1 pb-2"
                      >
                        Next
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {fields?.map((field, index) => {
              return (
                <div key={field.id} id={`${index + 1}form`}>
                  <div className="headerContainer">
                    <header className="visitorEntryHeader">
                      {" "}
                      Subject Details {index + 1}
                    </header>
                  </div>
                  <div className="visitorEntryFormInput">
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Name<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name={`subjectDetailstwo.${index}.subjectName`}
                        render={(field) => (
                          <InputField
                            visiterInfoCss="visiterInfoCss"
                            {...field}
                            type="text"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">S/o or D/o / other</label>
                      </div>
                      <Controller
                        control={control}
                        name={`subjectDetailstwo.${index}.subjectFatherName`}
                        render={(field) => (
                          <InputField
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="text"
                          />
                        )}
                      />
                    </div>
                    <div style={{ width: "18%", display: "flex" }}>
                      <div
                        className="visitorEntryInputContainer"
                        style={{ width: "78%", marginRight: "2%" }}
                      >
                        <div>
                          <label className="visitorInfoLabelContainer">
                            DOB
                          </label>
                        </div>

                        <Controller
                          control={control}
                          name={`subjectDetailstwo.${index}.subjectDob`}
                          render={({ field }) => (
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              className="visiterInfoCss rounded-start"
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={95} // Includes the current year + 40 previous years
                              selected={field.value}
                              maxDate={new Date()} // Restrict to current date and earlier
                              minDate={subYears(new Date(), 95)} // Restrict to 40 years before the current date
                              onChange={(date) => {
                                field.onChange(date);
                                calculateAge(date, index);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div
                        className="visitorEntryInputContainer"
                        style={{ width: "20%" }}
                      >
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Age<span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name={`subjectDetailstwo.${index}.subjectAge`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <input
                              style={error ? { borderColor: "red" } : {}}
                              className="visiterInfoCss"
                              value={value}
                              type="number"
                              onChange={(e) => {
                                onChange(e);
                                calculateDOB(e.target.value, index);
                              }}
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Education
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name={`subjectDetailstwo.${index}.employement`}
                        render={(field) => (
                          <InputField
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="text"
                          />
                        )}
                      />
                    </div>

                    <div className="visitorInfoMobile">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Mobile Number<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <Controller
                        key={
                          alsoWhatApp
                            ? "whatsapp-synced"
                            : "whatsapp-independent"
                        }
                        control={control}
                        name={`subjectDetailstwo.${index}.subjectMobileNumber`}
                        rules={{
                          required: ".",
                          minLength: {
                            value: 10,
                            message: "Mobile number must be 10 digits",
                          },
                          maxLength: {
                            value: 10,
                            message: "Mobile number must be 10 digits",
                          },
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message:
                              "Please enter a valid 10-digit mobile number",
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <PinInput
                              length={10}
                              initialValue={value || ""}
                              onChange={(val) => {
                                onChange(val);

                                if (alsoWhatApp && val?.length === 10) {
                                  setValue("whatsapp", val);
                                }
                              }}
                              type="numeric"
                              style={{ width: "100%", borderRadius: "5px" }}
                              inputStyle={{
                                ...{
                                  border: "1px solid gray",
                                  backgroundColor: "#fff",
                                  height: "40px",
                                  width: "10%",
                                  margin: "0px",
                                  borderRadius: "5px",
                                },
                                ...(error ? { borderColor: "red" } : {}),
                              }}
                              autoSelect
                              onComplete={(value, index) => {
                                console.log("onComplete", value);
                                setMobileNumberData(value);
                              }}
                            />
                            {error && (
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginTop: "5px",
                                }}
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <div className="visitorInfoMobile">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          WhatsApp Number
                        </label>
                      </div>
                      <Controller
                        key={
                          alsoWhatApp
                            ? "whatsapp-synced"
                            : "whatsapp-independent"
                        } // Unique key for re-rendering
                        control={control}
                        name={`subjectDetailstwo.${index}.subjectWhatsapp`}
                        rules={{
                          minLength: {
                            value: 10,
                            message: "Mobile number must be 10 digits", // Error message when length is less than 10
                          },
                          maxLength: {
                            value: 10,
                            message: "Mobile number must be 10 digits", // Error message when length is more than 10
                          },
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message:
                              "Please enter a valid 10-digit mobile number", // Ensure it's numeric
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <PinInput
                            length={10}
                            initialValue={value}
                            value={value || ""}
                            onChange={(val) => onChange(val)}
                            type="numeric"
                            style={{ width: "100%", borderRadius: "5px" }}
                            inputStyle={{
                              border: "1px solid gray",
                              backgroundColor: "#fff",
                              height: "40px",
                              width: "10%",
                              margin: "0px",
                              borderRadius: "5px",
                            }}
                            autoSelect
                          />
                        )}
                      />
                      <div
                        style={{ fontSize: "12px", cursor: "pointer" }}
                        className="d-flex pt-1 justify-content-end"
                      >
                        <label className="d-flex gap-1 align-items-center">
                          <input
                            onChange={(e) => {
                              // mobileNumberCheckBox(e);
                              mobileNumberCheckBox(e, index);
                            }}
                            type="checkbox"
                            style={{ width: "15px", height: "15px" }}
                          />
                          Also Same As Above
                        </label>
                      </div>
                    </div>
                    <div style={{ width: "18%", display: "flex" }}>
                      <div
                        className="visitorEntryInputContainer"
                        style={{ width: "20%" }}
                      >
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Code
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name={`subjectDetailstwo.${index}.subjectLandLineCode`}
                          render={(field) => (
                            <InputField
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="text"
                            />
                          )}
                        />
                      </div>
                      <div
                        className="visitorEntryInputContainer"
                        style={{ width: "78%", marginLeft: "2%" }}
                      >
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Landline Number
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name={`subjectDetailstwo.${index}.subjectAlternativeNumber`}
                          render={(field) => (
                            <InputField
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="number"
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          E Mail<span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name={`subjectDetailstwo.${index}.subjectEmail`}
                        render={(field) => (
                          <InputField
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="email"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>

                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Religion
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name={`subjectDetailstwo.${index}.subjectReligion`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            value={value}
                            className="visiterInfoCss py-2"
                            onChange={onChange}
                            onBlur={onBlur} // Added onBlur for better form validation handling
                            ref={ref} // Make sure the ref is passed down for proper field tracking
                          >
                            <option value=""> </option>
                            <option value="Christian">Christian</option>
                            <option value="Muslim">Muslim</option>
                            <option value="Hindu">Hindu</option>
                            <option value="Other">Other</option>
                          </select>
                        )}
                      />
                    </div>
                    {subjectReligion === "Other" && (
                      <div className="visitorEntryInputContainer">
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Other Religion
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name={`subjectDetailstwo.${index}.subjectOtherReligion`}
                          render={(field) => (
                            <InputField
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="text"
                            />
                          )}
                        />
                      </div>
                    )}
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Caste
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name={`subjectDetailstwo.${index}.subjectCaste`}
                        render={(field) => (
                          <InputField
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="text"
                          />
                        )}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Vehicle Details
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name={`subjectDetailstwo.${index}.vehicleDetails`}
                        render={(field) => (
                          <InputField
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="text"
                          />
                        )}
                      />
                    </div>
                    <div
                      className="visitorEntryInputContainer"
                      // style={{ width: "18%", height: "50px" }}
                    >
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Additional Status
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name={`subjectDetailstwo.${index}.subjectAdditionalStatus`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <textarea
                            ref={ref}
                            style={{ height: "40px", width: "100%",fontSize:"14px" }}
                            value={value}
                            className="visiterInfoCss "
                            onFocus={() =>
                              handleFocus(
                                `subjectDetailstwo.${index}.subjectAdditionalStatus`,
                                "Additional Status",
                                value
                              )
                            }
                            // onChange={(e) => {
                            //   onChange(e.target.value);
                            // }}
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleChange(
                                `subjectDetailstwo.${index}.subjectAdditionalStatus`,
                                e.target.value
                              );
                            }}
                            onBlur={onBlur}
                            disabled={isModalOpen}
                          ></textarea>
                        )}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Note 1
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name={`subjectDetailstwo.${index}.subjectNote1`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <textarea
                            ref={ref}
                            style={{ height: "40px", width: "100%",fontSize:"14px" }}
                            value={value}
                            className="visiterInfoCss "
                            onFocus={() =>
                              handleFocus(
                                `subjectDetailstwo.${index}.subjectNote1`,
                                "Note 1",
                                value
                              )
                            }
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleChange(
                                `subjectDetailstwo.${index}.subjectNote1`,
                                e.target.value
                              );
                            }}
                            onBlur={onBlur}
                            disabled={isModalOpen}
                          ></textarea>
                        )}
                      />
                    </div>
                    <div className="" style={{ width: "18%" }}>
                      <div
                        className="visitorInfoShowBtn mb-0 fs-6 px-2 text-center"
                        title={
                          subjectDetailstwo[index]?.subjectTemporaryAddress ||
                          "No address available"
                        }
                        style={
                          subjectDetailstwo[index]?.subjectTemporaryAddress
                            ? {
                                backgroundColor: "green",
                                color: "white",
                                cursor: "pointer",
                                width: "100%",
                                height: "40px",
                                alignContent: "center",
                              }
                            : {
                                backgroundColor: "red",
                                color: "white",
                                cursor: "pointer",
                                width: "100%",
                                height: "40px",
                                alignContent: "center",
                              }
                        }
                        onClick={() => {
                          setShowPresentAddAppend(index);
                          setAddress({
                            add: subjectDetailstwo[index]
                              ?.subjectTemporaryAddress,
                          });
                        }}
                      >
                        Present Address<span style={{ color: "white" }}>*</span>
                      </div>
                      <div>
                        <p
                          style={{
                            alignContent: "center",
                            marginLeft: "10px",
                            color: "red",
                          }}
                        >
                          {addressAlert && "Enter Subject Address"}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex" style={{ width: "18%" }}>
                      <div
                        className="visitorInfoShowBtn fs-6 px-2 text-center"
                        title={
                          subjectDetailstwo[index]?.subjectOfficeAddress ||
                          "No address available"
                        }
                        style={
                          subjectDetailstwo[index]?.subjectOfficeAddNo ||
                          subjectDetailstwo[index]?.subjectOfficeAdd1No ||
                          subjectDetailstwo[index]?.subjectOfficeName ||
                          subjectDetailstwo[index]?.subjectOfficeAddEmail ||
                          subjectDetailstwo[index]
                            ?.subjectOfficeAdditionalDetails ||
                          subjectDetailstwo[index]?.subjectOfficeAddress
                            ? {
                                backgroundColor: "green",
                                color: "white",
                                cursor: "pointer",
                                width: "100%",
                                height: "40px",
                                alignContent: "center",
                              }
                            : {
                                backgroundColor: "red",
                                color: "white",
                                cursor: "pointer",
                                width: "100%",
                                height: "40px",
                                alignContent: "center",
                              }
                        }
                        onClick={() => {
                          setOfficeAppend(index);
                          setAddress({
                            name: subjectDetailstwo[index]?.subjectOfficeName,
                            add: subjectDetailstwo[index]?.subjectOfficeAddress,
                            mNo: subjectDetailstwo[index]?.subjectOfficeAddNo,
                            aNo: subjectDetailstwo[index]?.subjectOfficeAdd1No,
                            email:
                              subjectDetailstwo[index]?.subjectOfficeAddEmail,
                            addInfo:
                              subjectDetailstwo[index]
                                ?.subjectOfficeAdditionalDetails,
                          });
                        }}
                      >
                        {/* Office Address & Permanent Address */}
                        Office Address
                      </div>
                    </div>
                    <div className="d-flex" style={{ width: "18%" }}>
                      <div
                        className="visitorInfoShowBtn fs-6 px-2 text-center"
                        title={
                          subjectDetailstwo[index]?.subjectPermanentAddress ||
                          "No address available"
                        }
                        style={
                          subjectDetailstwo[index]
                            ?.subjectPermanentFamilyName ||
                          subjectDetailstwo[index]?.subjectPermanentAddNo ||
                          // subjectDetailstwo[index]?.subjectPermanentAdd1No ||
                          subjectDetailstwo[index]?.subjectPermanentAddEmail ||
                          subjectDetailstwo[index]
                            ?.subjectPermanentAdditionalDetails ||
                          subjectDetailstwo[index]?.subjectPermanentAddress
                            ? {
                                backgroundColor: "green",
                                color: "white",
                                cursor: "pointer",
                                width: "100%",
                                height: "40px",
                                alignContent: "center",
                              }
                            : {
                                backgroundColor: "red",
                                color: "white",
                                cursor: "pointer",
                                width: "100%",
                                height: "40px",
                                alignContent: "center",
                              }
                        }
                        onClick={() => {
                          setShowPermanentAppend(index);
                          setAddress({
                            name: subjectDetailstwo[index]
                              ?.subjectPermanentFamilyName,
                            add: subjectDetailstwo[index]
                              ?.subjectPermanentAddress,
                            mNo: subjectDetailstwo[index]
                              ?.subjectPermanentAddNo,
                            email:
                              subjectDetailstwo[index]
                                ?.subjectPermanentAddEmail,
                            addInfo:
                              subjectDetailstwo[index]
                                ?.subjectPermanentAdditionalDetails,
                          });
                        }}
                      >
                        Permanent Address
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-3 justify-content-between">
                    <div
                      className="d-flex justify-content-start align-items-center"
                      style={{ width: "18%" }}
                    >
                      <div
                        style={{
                          color: "white",
                          cursor: "pointer",
                          width: "100%",
                          height: "50px",
                          alignContent: "center",
                          textDecoration: "none",
                          // backgroundColor:"",
                        }}
                        onClick={(e) => {
                          e.preventDefault(); // Stop default anchor navigation
                          remove(index);

                          setTimeout(() => {
                            const targetElement = document.getElementById(
                              index === 0 ? `#${0}form` : `#${index}form`
                            );
                            if (targetElement) {
                              targetElement.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }, 100); // Small delay to allow React to update
                        }}
                        className="visitorInfoSaveBtn text-center px-2"
                      >
                        Delete Detail
                      </div>
                    </div>
                    <div className="" style={{}}>
                      {subjectDetailstwo && subjectDetailstwo.length > 0 && (
                        <div className="d-flex gap-4 mt-1 justify-content-center">
                          <div className="">
                            <a
                              onClick={() => {
                                setPage(subjectDetailstwo.length);
                              }}
                              href={index === 0 ? `#${0}form` : `#${index}form`}
                              style={{
                                height: "42px",
                                textDecorationLine: "none",
                              }}
                              className="visitorInfoSaveBtn px-2 py-1 pb-2"
                            >
                              Back
                            </a>
                          </div>

                          {page && page - 1 !== index ? (
                            <div>
                              <a
                                href={`#${index + 2}form`}
                                style={{
                                  width: "210px",
                                  textDecorationLine: "none",
                                }}
                                id="field"
                                className="visitorInfoSaveBtn px-2 py-1 pb-2"
                              >
                                Next
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                    {/* {index === subjectDetailstwo.length - 1 && ( */}
                    <div
                      className="d-flex justify-content-start"
                      style={{ width: "18%" }}
                    >
                      <div
                        style={{ width: "100%", textDecorationLine: "none" }}
                        id="field"
                        // href={`#${index + 2}form`}
                        onClick={(e) => {
                          e.preventDefault(); // Stop default anchor navigation
                          append({}, { shouldFocus: false }); // Append new form entry

                          // Wait for the new form to be added, then scroll
                          setTimeout(() => {
                            const targetElement = document.getElementById(
                              `${index + 2}form`
                            );
                            if (targetElement) {
                              targetElement.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }, 100); // Small delay to allow React to update
                        }}
                        className="visitorInfoSaveBtn p-2"
                      >
                        Additional Details
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              );
            })}
            {subjectDetailstwo < 1 && (
              <div
                className="d-flex justify-content-center"
                style={{ width: "100%" }}
              >
                <div
                  style={{
                    width: "18%",
                    textDecorationLine: "none",
                    textAlign: "center",
                  }}
                  // id="field"
                  href={`#1form`}
                  onClick={(e) => {
                    e.preventDefault(); // Stop default anchor navigation
                    append({}, { shouldFocus: false }); // Append new form entry

                    // Wait for the new form to be added, then scroll
                    setTimeout(() => {
                      const targetElement = document.getElementById(`1form`);
                      if (targetElement) {
                        targetElement.scrollIntoView({ behavior: "smooth" });
                      }
                    }, 100); // Small delay to allow React to update
                  }}
                  className="visitorInfoSaveBtn p-2"
                >
                  Additional Details
                </div>
              </div>
            )}
            {/* </div>
            )} */}

            {userData !== "superAdmin" && (
              <div className="w-100 d-flex justify-content-center pe-5">
                <div className="visitorEntryInputContainer">
                  <button
                    id="submit"
                    style={{ backgroundColor: "green", color: "white" }}
                    onClick={() => {
                      console.log("hello");
                    }}
                    type="submit"
                    className="visitorInfoSaveBtn py-2 px-4 mt-4 mb-0 w-100"
                  >
                    {isLoading ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            )}
            {userData === "superAdmin" && (
              <div style={{ width: "100%" }}>
                <div className="headerContainer">
                  <header className="visitorEntryHeader">
                    {" "}
                    Professional Payment Particular
                  </header>
                </div>
                <div className="visitorEntryFormInput py-5">
                  <div className="visitorEntryInputContainer ">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Total Amount
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="caseDetailsTotalAmount"
                      render={(field) => (
                        <InputField
                          visiterInfoCss="visiterInfoCss"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                  </div>
                  <div className="visitorEntryInputContainer ">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Amount Received
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="caseDetailsAmountReceived"
                      render={(field) => (
                        <InputField
                          visiterInfoCss="visiterInfoCss"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                    {amtError && <p style={{ color: "red" }}>{amtError}</p>}
                  </div>

                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Mode of payment
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="caseDetailsPaymentType"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <select
                          value={value}
                          className="visiterInfoCss  py-2"
                          onChange={onChange}
                        >
                          <option value=" "> </option>
                          <option value=" Cash">Cash </option>
                          <option value=" Card">Card </option>
                          <option value="Online">Online</option>
                        </select>
                      )}
                    />
                  </div>
                  <div className="visitorEntryInputContainer ">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Balance Pending
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="caseDetailsBalancePending"
                      render={(field) => (
                        <InputField
                          visiterInfoCss="visiterInfoCss"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                  </div>
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">Date</label>
                    </div>
                    <Controller
                      control={control}
                      name="caseDetailsAmountDate"
                      render={({ field }) => (
                        <DatePicker
                          placeholderText="DD/MM/YYYY"
                          dateFormat="dd/MM/yyyy eeee" // Correct format
                          className="visiterInfoCss"
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          isClearable
                          showYearDropdown
                          scrollableYearDropdown
                          maxDate={new Date()} // Restrict to current date and earlier
                          minDate={subYears(new Date(), 15)}
                          yearDropdownItemNumber={15}
                        />
                      )}
                    />
                  </div>
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Payment Category
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="paymentCatagory"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <select
                          value={value}
                          className="visiterInfoCss  py-2"
                          onChange={onChange}
                        >
                          <option value=" "> </option>
                          <option value="Free Of Cost">Free Of Cost </option>
                          <option value="100% Cost">100% Cost </option>
                          <option value="50% Cost">50% Cost </option>
                        </select>
                      )}
                    />
                  </div>
                  {/* <div style={{ width: "100%",display:"flex",gap:"33px" }}> */}
                  <div className="visitorEntryInputContainer ">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Status
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="caseDetailsStatus"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <select
                          value={value}
                          className="visiterInfoCss  py-2"
                          onChange={onChange}
                        >
                          <option value=" "> </option>
                          <option value="collect">collect</option>
                          <option value="pending">Pending</option>
                          <option value="completed">Completed</option>
                        </select>
                      )}
                    />
                  </div>
                  <div className="visitorEntryInputContainer ">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Chief comment
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="chiefComment"
                      render={({ field: { value, onChange, ref } }) => (
                        <textarea
                          ref={ref}
                          value={value || ""}
                          style={{ height: "40px", width: "100%",fontSize:"14px" }}
                          className="visiterInfoCss"
                          onFocus={() =>
                            handleFocus("chiefComment", "Chief comment", value)
                          }
                          onChange={(e) => {
                            onChange(e.target.value);
                            handleChange("chiefComment", e.target.value);
                          }}
                          disabled={isModalOpen}
                        />
                      )}
                    />
                  </div>
                  <div className=" visitorEntryInputContainer">
                    <button
                      // id="submit"
                      style={{ backgroundColor: "green", color: "white" }}
                      onClick={() => {
                        console.log("hello");
                      }}
                      type="submit"
                      className="visitorInfoSaveBtn py-2 px-4 mt-4 mb-0 w-100"
                    >
                      {isLoading ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
                <div>
                  <table
                    style={{
                      borderCollapse: "collapse",
                      width: "100%",
                    }}
                  >
                    {state && (
                      <thead>
                        <tr>
                          <th className="visitorAddAmtDetails">
                            Balance Pending
                          </th>
                          <th className="visitorAddAmtDetails">
                            Amount Received
                          </th>
                          <th className="visitorAddAmtDetails">Date Of Pay</th>
                          <th className="visitorAddAmtDetails">Status</th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {state &&
                        state?.caseDetails?.balancePending?.map((ele, i) => {
                          if (state?.caseDetails?.amountReceived[i] === null) {
                            return null;
                          }
                          return (
                            <tr key={i}>
                              <td className="visitorAddAmtDetails">
                                {state?.caseDetails?.balancePending[i]}
                              </td>
                              <td className="visitorAddAmtDetails">
                                {state?.caseDetails?.amountReceived[i]}
                              </td>
                              <td className="visitorAddAmtDetails">
                                {moment(state?.caseDetails?.amountDate[i])
                                  .utc()
                                  .format("DD-MM-YYYY")}
                              </td>
                              <td className="visitorAddAmtDetails">
                                {state?.caseDetails?.status[i]}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </form>
        </div>
      </section>

      {isModalOpen && currentField && (
        <Modal
          show={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSave}
          value={currentField.value} // Pass current value to the modal
          currentField={currentField}
        />
      )}
      <AddressModal isOpen={showPermanentAdd !== null}>
        <div className="mb-3 text-end">
          <img
            onClick={() => {
              if (
                address?.name !== subjectPermanentFamilyName ||
                address?.add !== subjectPermanentAddress ||
                address?.mNo !== subjectPermanentAddNo ||
                address?.email !== subjectPermanentAddEmail ||
                address?.addInfo !== subjectPermanentAdditionalDetails
              ) {
                setPermanentConfirm(true);
              } else {
                setShowPermanentAdd(null);
              }
              // setPermanentConfirm(true);
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          {/* </div> */}

          <div style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Permanent Address Details
            </header>
            <div>
              <div>
                <label className="visitorInfoLabelContainer ">
                  Family/House Name
                </label>
              </div>
              <Controller
                control={control}
                name="subjectPermanentFamilyName"
                render={(field) => (
                  <InputField
                    autoFocus={true}
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="text"
                  />
                )}
              />
            </div>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "120px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name="subjectPermanentAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // style={{ minHeight: "100px" }}
                    // autoFocus
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
            <div>
              <div>
                <label className="visitorInfoLabelContainer ">Mobile No</label>
              </div>
              <Controller
                control={control}
                name="subjectPermanentAddNo"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="number"
                  />
                )}
              />
            </div>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer ">
                  Address Mail id
                </label>
              </div>
              <Controller
                control={control}
                name="subjectPermanentAddEmail"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">
                  Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name="subjectPermanentAdditionalDetails"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ height: "60px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPermanentAdd(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={showOfficeAdd !== null}>
        <div className="mb-3 text-end">
          <img
            // title="Remove details and close"
            onClick={() => {
              if (
                address?.name !== subjectOfficeName ||
                address?.add !== subjectOfficeAddress ||
                address?.mNo !== subjectOfficeAddNo ||
                address?.aNo !== subjectOfficeAdd1No ||
                address?.email !== subjectOfficeAddEmail ||
                address?.addInfo !== subjectOfficeAdditionalDetails
              ) {
                setOfficeConfirm(true);
              } else {
                setShowOfficeAdd(null);
              }
              // setOfficeConfirm(true);
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div className="" style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Office Address Details
            </header>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Office Name</label>
              </div>
              <Controller
                control={control}
                name="subjectOfficeName"
                render={(field) => (
                  <InputField
                    autoFocus={true}
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="text"
                  />
                )}
              />
            </div>

            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "120px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name="subjectOfficeAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // style={{ minHeight: "100px" }}
                    // autoFocus
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
            <div className="my-2">
              <div className="d-flex gap-2">
                <div className="w-50">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Mobile No
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectOfficeAddNo"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
                <div className="w-50">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Additional No
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectOfficeAdd1No"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Mail id</label>
              </div>
              <Controller
                control={control}
                name="subjectOfficeAddEmail"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>

            <div>
              <div>
                <label className="visitorInfoLabelContainer">
                  Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name="subjectOfficeAdditionalDetails"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ height: "60px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowOfficeAdd(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={showPresentAdd !== null}>
        <div className="mb-3 text-end">
          <img
            onClick={() => {
              // setPresentConfirm(true);
              if (address?.add !== subjectTemporaryAddress) {
                setPresentConfirm(true);
              } else {
                setShowPresentAdd(null);
              }
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Present Address
            </header>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "200px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name="subjectTemporaryAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    autoFocus
                    style={{ height: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPresentAdd(null);
              // setAddress({ add: subjectTemporaryAddress });
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <Alert
        open={postAlert}
        setPutAlert={setPutAlert}
        postAlert={postAlert}
        state={state}
        upData={upData}
        putAlert={putAlert}
        mutate={mutate}
        navigates="/clientList"
        id={state?.id}
        add={true}
        onCloses={setPostAlert}
        showAddPop={showAddPop}
        setShowAddPop={setShowAddPop}
        setShowPresentAdd={setShowPresentAdd}
      />

      <AddConfirmAlt isOpen={presentConfirm !== null}>
        <div className="addAltContainer">
          <div className="addAlt_text">Do you want to save changes?</div>
          <div className="addAltBtn">
            <div>
              <button
                className="dont-save-btn"
                onClick={() => {
                  setShowPresentAdd(null);
                  setValue("subjectTemporaryAddress", address?.add);
                  setPresentConfirm(null);
                }}
              >
                Don't Save
              </button>
            </div>
            <div className="">
              <button
                className="save-btn"
                onClick={() => {
                  setShowPresentAdd(null);
                  setPresentConfirm(null);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </AddConfirmAlt>
      <AddConfirmAlt isOpen={officeConfirm !== null}>
        <div className="addAltContainer">
          <div className="addAlt_text">Do you want to save changes?</div>
          <div className="addAltBtn">
            <div>
              <button
                className="dont-save-btn"
                onClick={() => {
                  setShowOfficeAdd(null);
                  setValue("subjectOfficeName", address?.name);
                  setValue("subjectOfficeAddress", address?.add);
                  setValue("subjectOfficeAddNo", address?.mNo);
                  setValue("subjectOfficeAdd1No", address?.aNo);
                  setValue("subjectOfficeAddEmail", address?.email);
                  setValue("subjectOfficeAdditionalDetails", address?.addInfo);
                  setOfficeConfirm(null);
                }}
              >
                Don't Save
              </button>
            </div>
            <div className="">
              <button
                className="save-btn"
                onClick={() => {
                  setShowOfficeAdd(null);
                  setOfficeConfirm(null);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </AddConfirmAlt>
      <AddConfirmAlt isOpen={PermanentConfirm !== null}>
        <div className="addAltContainer">
          <div className="addAlt_text">Do you want to save changes?</div>
          <div className="addAltBtn">
            <div>
              <button
                className="dont-save-btn"
                onClick={() => {
                  setShowPermanentAdd(null);
                  setValue("subjectPermanentFamilyName", address?.name);
                  setValue("subjectPermanentAddress", address?.add);
                  setValue("subjectPermanentAddNo", address?.mNo);
                  setValue("subjectPermanentAddEmail", address?.email);
                  setValue(
                    "subjectPermanentAdditionalDetails",
                    address?.addInfo
                  );
                  setPermanentConfirm(null);
                }}
              >
                Don't Save
              </button>
            </div>
            <div className="">
              <button
                className="save-btn"
                onClick={() => {
                  setShowPermanentAdd(null);
                  setPermanentConfirm(null);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </AddConfirmAlt>

      <AddressModal isOpen={clientPresentAdd !== null}>
        <div className="mb-3 text-end">
          <img
            // title="Remove details and close"
            onClick={() => {
              setClientPresentAdd(null);
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Present Address
            </header>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "200px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name="clientAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // readOnly
                    // autoFocus
                    style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setClientPresentAdd(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={clientOfficeAdd !== null}>
        <div className="mb-2 text-end">
          <img
            // title="Remove details and close"
            onClick={() => {
              setClientOfficeAdd(null);
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
              // marginBottom: "20px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div className="" style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Office Address Details
            </header>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Office Name</label>
              </div>
              <Controller
                control={control}
                name="officeName"
                render={(field) => (
                  <InputField
                    readOnly={true}
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="text"
                  />
                )}
              />
            </div>

            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "120px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name="officeAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // autoFocus
                    readOnly
                    // style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>

            <div className="my-2">
              <div className="d-flex gap-2">
                <div className="w-50">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Mobile No
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="officeAddNo"
                    render={(field) => (
                      <InputField
                        readOnly={true}
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
                <div className="w-50">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Additional No
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="officeAdd1No"
                    render={(field) => (
                      <InputField
                        readOnly={true}
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Mail id</label>
              </div>
              <Controller
                control={control}
                name="officeAddEmail"
                render={(field) => (
                  <InputField
                    readOnly={true}
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>

            <div>
              <div>
                <label className="visitorInfoLabelContainer">
                  Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name="officeAdditionalDetails"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    readOnly
                    style={{ height: "60px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
      </AddressModal>

      <AddressModal isOpen={clientPermentAdd !== null}>
        <div className="mb-3 text-end">
          <img
            // title="Remove details and close"
            onClick={() => {
              setClientPermanentAdd(null);
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Permanent Address Details
            </header>
            <div>
              <div>
                <label className="visitorInfoLabelContainer ">Mobile No</label>
              </div>
              <Controller
                control={control}
                name="permanentFamilyName"
                render={(field) => (
                  <InputField
                    readOnly={true}
                    autoFocus={true}
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="text"
                  />
                )}
              />
            </div>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "120px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name="permanentAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // style={{ minHeight: "100px" }}
                    readOnly
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
            <div>
              <div>
                <label className="visitorInfoLabelContainer ">Mobile No</label>
              </div>
              <Controller
                control={control}
                name="permanentAddNo"
                render={(field) => (
                  <InputField
                    readOnly={true}
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="number"
                  />
                )}
              />
            </div>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer ">Mail id</label>
              </div>
              <Controller
                control={control}
                name="permanentAddEmail"
                render={(field) => (
                  <InputField
                    readOnly={true}
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">
                  Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name="permanentAdditionalDetails"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    readOnly
                    style={{ height: "60px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        {/* <div className="text-center">
          <button
            onClick={() => {
              setClientPermanentAdd(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div> */}
      </AddressModal>

      <AddressModal isOpen={showPresentAddAppend !== null}>
        <div className="mb-3 text-end">
          <img
            // title="Remove details and close"
            onClick={() => {
              if (
                address?.add !==
                subjectDetailstwo[showPresentAddAppend]?.subjectTemporaryAddress
              ) {
                setAppPresentAdd(true);
              } else {
                setShowPresentAddAppend(null);
              }
              // setValue(`userForm.${showPresentAddAppend}.temporaryAddress`, "");
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Present Address
            </header>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "200px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name={`subjectDetailstwo.${showPresentAddAppend}.subjectTemporaryAddress`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ minHeight: "100px" }}
                    autoFocus
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPresentAddAppend(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>
      <AddressModal isOpen={showOfficeAppend !== null}>
        <div className=" text-end mb-2">
          <img
            // title="Remove details and close"
            onClick={() => {
              if (
                address?.name !==
                  subjectDetailstwo[showOfficeAppend]?.subjectOfficeName ||
                address?.add !==
                  subjectDetailstwo[showOfficeAppend]?.subjectOfficeAddress ||
                address?.mNo !==
                  subjectDetailstwo[showOfficeAppend]?.subjectOfficeAddNo ||
                address?.aNo !==
                  subjectDetailstwo[showOfficeAppend]?.subjectOfficeAdd1No ||
                address?.email !==
                  subjectDetailstwo[showOfficeAppend]?.subjectOfficeAddEmail ||
                address?.addInfo !==
                  subjectDetailstwo[showOfficeAppend]
                    ?.subjectOfficeAdditionalDetails
              ) {
                setAppOfficeAdd(true);
              } else {
                setOfficeAppend(null);
              }
              // setAppOfficeAdd(true);
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div className="" style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Office Address Details
            </header>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Office Name</label>
              </div>
              <Controller
                control={control}
                // name="officeName"
                name={`subjectDetailstwo.${showOfficeAppend}.subjectOfficeName`}
                render={(field) => (
                  <InputField
                    autoFocus={true}
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "120px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name={`subjectDetailstwo.${showOfficeAppend}.subjectOfficeAddress`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // autoFocus
                    // style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>

            <div className="my-2">
              <div className="d-flex gap-2">
                <div className="w-50">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Mobile No
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name={`subjectDetailstwo.${showOfficeAppend}.subjectOfficeAddNo`}
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
                <div className="w-50">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Additional No
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name={`subjectDetailstwo.${showOfficeAppend}.subjectOfficeAdd1No`}
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Mail id</label>
              </div>
              <Controller
                control={control}
                name={`subjectDetailstwo.${showOfficeAppend}.subjectOfficeAddEmail`}
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>

            <div>
              <div>
                <label className="visitorInfoLabelContainer">
                  Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name={`subjectDetailstwo.${showOfficeAppend}.subjectOfficeAdditionalDetails`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ height: "60px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setOfficeAppend(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>
      <AddressModal isOpen={showPermanentAppend !== null}>
        <div className=" text-end mb-2">
          <img
            // title="Remove details and close"
            onClick={() => {
              if (
                address?.name !==
                  subjectDetailstwo[showPermanentAppend]
                    ?.subjectPermanentFamilyName ||
                address?.add !==
                  subjectDetailstwo[showPermanentAppend]
                    ?.subjectPermanentAddress ||
                address?.mNo !==
                  subjectDetailstwo[showPermanentAppend]
                    ?.subjectPermanentAddNo ||
                address?.email !==
                  subjectDetailstwo[showPermanentAppend]
                    ?.subjectPermanentAddEmail ||
                address?.addInfo !==
                  subjectDetailstwo[showPermanentAppend]
                    ?.subjectPermanentAdditionalDetails
              ) {
                setAppPermanentAdd(true);
              } else {
                setShowPermanentAppend(null);
              }
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div style={{ width: "100%", display: "" }}>
          <header
            className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
            style={{ color: "#fff" }}
          >
            Permanent Address Details
          </header>
          <div>
            <div>
              <label className="visitorInfoLabelContainer mt-2 ">
                Family/House Name
              </label>
            </div>
            <Controller
              control={control}
              name={`subjectDetailstwo.${showPermanentAppend}.subjectPermanentFamilyName`}
              render={(field) => (
                <InputField
                  autoFocus={true}
                  visiterInfoCss="visiterInfoCss"
                  {...field}
                  type="text"
                />
              )}
            />
          </div>
          <div
            className="visitorEntryInputContainer my-2"
            style={{ width: "100%", height: "120px" }}
          >
            <div>
              <label className="visitorInfoLabelContainer">Address</label>
            </div>

            <Controller
              control={control}
              name={`subjectDetailstwo.${showPermanentAppend}.subjectPermanentAddress`}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <textarea
                  // style={{ minHeight: "100px" }}

                  value={value}
                  className="visitorAddCss py-2"
                  onChange={onChange}
                ></textarea>
              )}
            />
          </div>
          <div>
            <div>
              <label className="visitorInfoLabelContainer ">Mobile No</label>
            </div>
            <Controller
              control={control}
              name={`subjectDetailstwo.${showPermanentAppend}.subjectPermanentAddNo`}
              render={(field) => (
                <InputField
                  visiterInfoCss="visiterInfoCss"
                  {...field}
                  type="number"
                />
              )}
            />
          </div>
          <div className="my-2">
            <div>
              <label className="visitorInfoLabelContainer ">Mail id</label>
            </div>
            <Controller
              control={control}
              name={`subjectDetailstwo.${showPermanentAppend}.subjectPermanentAddEmail`}
              render={(field) => (
                <InputField
                  visiterInfoCss="visiterInfoCss"
                  {...field}
                  type="email"
                />
              )}
            />
          </div>
          <div className="my-2">
            <div>
              <label className="visitorInfoLabelContainer">
                Additional Details
              </label>
            </div>
            <Controller
              control={control}
              name={`subjectDetailstwo.${showPermanentAppend}.subjectPermanentAdditionalDetails`}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <textarea
                  style={{ height: "60px" }}
                  value={value}
                  className="visitorAddCss py-2"
                  onChange={onChange}
                ></textarea>
              )}
            />
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPermanentAppend(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddConfirmAlt isOpen={appPresentAdd !== null}>
        <div className="addAltContainer">
          <div className="addAlt_text">Do you want to save changes?</div>
          <div className="addAltBtn">
            <div>
              <button
                className="dont-save-btn"
                onClick={() => {
                  setShowPresentAddAppend(null);
                  setValue(
                    `subjectDetailstwo.${showPresentAddAppend}.subjectTemporaryAddress`,
                    address?.add
                  );
                  setAppPresentAdd(null);
                }}
              >
                Don't Save
              </button>
            </div>
            <div className="">
              <button
                className="save-btn"
                onClick={() => {
                  setShowPresentAddAppend(null);
                  setAppPresentAdd(null);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </AddConfirmAlt>
      <AddConfirmAlt isOpen={appOfficeAdd !== null}>
        <div className="addAltContainer">
          <div className="addAlt_text">Do you want to save changes?</div>
          <div className="addAltBtn">
            <div>
              <button
                className="dont-save-btn"
                onClick={() => {
                  setOfficeAppend(null);
                  setValue(
                    `subjectDetailstwo.${showOfficeAppend}.subjectOfficeName`,
                    address?.name
                  );
                  setValue(
                    `subjectDetailstwo.${showOfficeAppend}.subjectOfficeAddress`,
                    address?.add
                  );
                  setValue(
                    `subjectDetailstwo.${showOfficeAppend}.subjectOfficeAddNo`,
                    address?.mNo
                  );
                  setValue(
                    `subjectDetailstwo.${showOfficeAppend}.subjectOfficeAdd1No`,
                    address?.aNo
                  );
                  setValue(
                    `subjectDetailstwo.${showOfficeAppend}.subjectOfficeAddEmail`,
                    address?.email
                  );
                  setValue(
                    `subjectDetailstwo.${showOfficeAppend}.subjectOfficeAdditionalDetails`,
                    address?.addInfo
                  );
                  setAppOfficeAdd(null);
                }}
              >
                Don't Save
              </button>
            </div>
            <div className="">
              <button
                className="save-btn"
                onClick={() => {
                  setOfficeAppend(null);
                  setAppOfficeAdd(null);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </AddConfirmAlt>
      <AddConfirmAlt isOpen={appPermanentAdd !== null}>
        <div className="addAltContainer">
          <div className="addAlt_text">Do you want to save changes?</div>
          <div className="addAltBtn">
            <div>
              <button
                className="dont-save-btn"
                onClick={() => {
                  setShowPermanentAppend(null);
                  setValue(
                    `subjectDetailstwo.${showPermanentAppend}.subjectPermanentFamilyName`,
                    address?.name
                  );
                  setValue(
                    `subjectDetailstwo.${showPermanentAppend}.subjectPermanentAddress`,
                    address?.add
                  );
                  setValue(
                    `subjectDetailstwo.${showPermanentAppend}.subjectPermanentAddNo`,
                    address?.mNo
                  );
                  setValue(
                    `subjectDetailstwo.${showPermanentAppend}.subjectPermanentAddEmail`,
                    address?.email
                  );
                  setValue(
                    `subjectDetailstwo.${showPermanentAppend}.subjectPermanentAdditionalDetails`,
                    address?.addInfo
                  );
                  setAppPermanentAdd(null);
                }}
              >
                Don't Save
              </button>
            </div>
            <div className="">
              <button
                className="save-btn"
                onClick={() => {
                  setShowPermanentAppend(null);
                  setAppPermanentAdd(null);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </AddConfirmAlt>
    </div>
  );
}
