import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import PinInput from "react-pin-input";
import InputField from "./InputField";
import { stateDistricts } from "./State";
import add from "../asset/Add.png";
import minus from "../asset/minus.png";
import { Button } from "bootstrap";
import Modal from "./Modal";
import { useMutation } from "react-query";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import Alert from "./Alert";
import AddressModal from "./AddressModal";
import wrongIcon from "../asset/close.svg";
import { set, subYears } from "date-fns";
import added from "../asset/added.svg";
import AddConfirmAlt from "./AddConfirmAlt";

export default function VisitorInformationCom() {
  const [alsoWhatApp, setAlsoWhatApp] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentField, setCurrentField] = useState([]);
  const [postAlert, setPostAlert] = useState();
  const [putAlert, setPutAlert] = useState();
  const [page, setPage] = useState();
  const [mobileNumberData, setMobileNumberData] = useState();
  const [showPresentAdd, setShowPresentAdd] = useState(null);
  const [showOfficeAdd, setShowOfficeAdd] = useState(null);
  const [showOfficeAppend, setOfficeAppend] = useState(null);
  const [presentConfirm, setPresentConfirm] = useState(null);
  const [officeConfirm, setOfficeConfirm] = useState(null);
  const [PermanentConfirm, setPermanentConfirm] = useState(null);
  const [showPermanentAppend, setShowPermanentAppend] = useState(null);
  const [showPermanentAddress, setShowPermanentAddress] = useState(null);
  const [showPresentAddAppend, setShowPresentAddAppend] = useState(null);
  const [appPresentAdd, setAppPresentAdd] = useState(null);
  const [appOfficeAdd, setAppOfficeAdd] = useState(null);
  const [appPermanentAdd, setAppPermanentAdd] = useState(null);
  const [addressAlert, setAddressAlert] = useState();
  const [formValues, setFormValues] = useState({});
  // console.log('userForm',userForm);

  const [address, setAddress] = useState({
    name: "",
    add: "",
    mNo: "",
    aNo: "",
    email: "",
    addInfo: "",
  });

  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state", state);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    register,
    getValues,
  } = useForm({
    defaultValues: {
      userForm: [],
    },
  });
  console.log("errors", errors);
  const { fields, append, remove } = useFieldArray({
    name: "userForm",
    control,
  });

  const {
    userForm,
    mobileNumber,
    inTime,
    religion,
    gender,
    category,
    sourceForm,
    permanentAddNo,
    permanentAddEmail,
    permanentAdditionalDetails,
    permanentAddress,
    officeName,
    officeAddNo,
    officeAdd1No,
    officeAddEmail,
    officeAdditionalDetails,
    officeAddress,
    temporaryAddress,
    permanentFamilyName,
    purposeOfVisit,
    age,
  } = watch();
  console.log("userForm", age);

  useEffect(() => {
    if (state) {
      setValue("name", state && state?.name);
      setValue(
        "mobileNumber",
        state && state?.mobileNumber ? state?.mobileNumber : ""
      );
      setValue("category", state?.category);
      setValue("gender", state?.gender);
      setValue("age", state?.age);
      setValue("fileNumber", state?.fileNumber);
      setValue(
        "alternativeNumber",
        state && state?.alternativeNumber ? state?.alternativeNumber : ""
      );
      setValue(
        "landLineCode",
        state && state?.landLineCode ? state?.landLineCode : ""
      );
      setValue("sourceForm", state?.sourceForm);
      setValue("permanentAddress", state?.permanentAddress);
      setValue("temporaryAddress", state?.temporaryAddress);
      setValue("officeAddress", state?.officeAddress);
      // setValue("placeOfResidence", state?.placeOfResidence);
      setValue("district", state && state?.district);
      setValue("pincode", state?.pincode);
      setValue("inTime", state?.inTime);
      setValue("outTime", state?.outTime);
      setValue(
        "dateOfVisit",
        state?.dateOfVisit === "Invalid date" ? "" : state?.dateOfVisit
      );
      setValue(
        "dateOfAppointment",
        state?.dateOfAppointment === "Invalid date"
          ? ""
          : state?.dateOfAppointment
      );
      setValue("additionalStatus", state?.additionalStatus);
      setValue("state", state && state?.state);
      setValue("email", state?.email);
      setValue("whatsapp", state && state?.whatsapp ? state?.whatsapp : "");
      setValue("caste", state?.caste);
      setValue("religion", state?.religion);
      setValue("date", state?.date);
      setValue("otherReligion", state?.otherReligion);
      setValue("otherSourceForm", state?.otherSourceForm);
      setValue("otherCategory", state?.otherCategory);
      setValue("permanentAddNo", state?.permanentAddNo);
      setValue("permanentAddEmail", state?.permanentAddEmail);
      setValue("permanentFamilyName", state?.permanentFamilyName);
      setValue("officeAddNo", state?.officeAddNo);
      setValue("officeName", state?.officeName);
      setValue("officeAdd1No", state?.officeAdd1No);
      setValue("officeAddEmail", state?.officeAddEmail);
      setValue("country", state?.country);
      setValue("fatherName", state?.fatherName);
      setValue("dob", state?.dob === "Invalid date" ? "" : state?.dob);
      setValue("purposeOfVisit", state?.purposeOfVisit);
      setValue("officeAdditionalDetails", state?.officeAdditionalDetails);
      setValue("permanentAdditionalDetails", state?.permanentAdditionalDetails);
      setValue("visitorNote1", state?.visitorNote1);
      setValue("visitorNote2", state?.visitorNote2);
      setValue("education", state?.education);
      setValue("qualification", state?.qualification);
      // setFormValues({
      //   purposeOfVisit:state?.purposeOfVisit,
      //   visitorNote1: state?.visitorNote1,
      //   visitorNote2:state?.visitorNote2,
      //   additionalStatus:state?.additionalStatus
      // })
      setAlsoWhatApp(true);
      if (state?.duplicatevisitor) {
        append();
        remove();
        state?.duplicatevisitor?.forEach((value, index) => {
          setValue(`userForm.${index}.name`, value && value?.name);
          setValue(`userForm.${index}.fileNumber`, value && value?.fileNumber);
          setValue(
            `userForm.${index}.mobileNumber`,
            value && value?.mobileNumber ? value?.mobileNumber : ""
          );
          setValue(
            `userForm.${index}.alternativeNumber`,
            value && value?.alternativeNumber ? value?.alternativeNumber : ""
          );
          setValue(
            `userForm.${index}.landLineCode`,
            value && value?.landLineCode ? value?.landLineCode : ""
          );
          setValue(
            `userForm.${index}.whatsapp`,
            value && value?.whatsapp ? value?.whatsapp : ""
          );
          setValue(`userForm.${index}.email`, value && value?.email);
          setValue(`userForm.${index}.caste`, value && value?.caste);
          setValue(`userForm.${index}.gender`, value && value?.gender);
          setValue(`userForm.${index}.relegion`, value && value?.relegion);
          setValue(
            `userForm.${index}.temporaryAddress`,
            value && value?.temporaryAddress
          );
          setValue(
            `userForm.${index}.permanentAddress`,
            value && value?.permanentAddress
          );
          setValue(
            `userForm.${index}.officeAddress`,
            value && value?.officeAddress
          );
          setValue(
            `userForm.${index}.dateOfVisit`,
            // value && value?.dateOfVisit !== null
            value && value?.dateOfVisit === "Invalid date"
              ? ""
              : value?.dateOfVisit
          );
          setValue(
            `userForm.${index}.dateOfAppointment`,
            value && value?.dateOfAppointment === "Invalid date"
              ? ""
              : value?.dateOfAppointment
          );
          setValue(`userForm.${index}.category`, value && value?.category);
          setValue(`userForm.${index}.sourceForm`, value && value?.sourceForm);
          setValue(`userForm.${index}.age`, value && value?.age);
          setValue(
            `userForm.${index}.placeOfResidence`,
            value && value?.placeOfResidence
          );
          setValue(`userForm.${index}.address`, value && value?.address);
          setValue(`userForm.${index}.district`, value && value?.district);
          setValue(`userForm.${index}.state`, value && value?.state);
          setValue(`userForm.${index}.pincode`, value && value?.pincode);
          setValue(
            `userForm.${index}.additionalStatus`,
            value && value?.additionalStatus
          );
          setValue(
            `userForm.${index}.purposeOfVisit`,
            value && value?.purposeOfVisit
          );
          setValue(
            `userForm.${index}.otherReligion`,
            value && value?.otherReligion
          );
          setValue(
            `userForm.${index}.otherSourceForm`,
            value && value?.otherSourceForm
          );
          setValue(
            `userForm.${index}.otherCategory`,
            value && value?.otherCategory
          );
          setValue(
            `userForm.${index}.otherGender`,
            value && value?.otherGender
          );
          setValue(
            `userForm.${index}.permanentAddNo`,
            value && value?.permanentAddNo
          );
          setValue(
            `userForm.${index}.permanentFamilyName`,
            value && value?.permanentFamilyName
          );
          setValue(
            `userForm.${index}.permanentAddEmail`,
            value && value?.permanentAddEmail
          );
          setValue(
            `userForm.${index}.permanentAdditionalDetails`,
            value && value?.permanentAdditionalDetails
          );
          setValue(`userForm.${index}.officeName`, value && value?.officeName);
          setValue(
            `userForm.${index}.officeAddNo`,
            value && value?.officeAddNo
          );
          setValue(
            `userForm.${index}.officeAdd1No`,
            value && value?.officeAdd1No
          );
          setValue(
            `userForm.${index}.officeAddEmail`,
            value && value?.officeAddEmail
          );
          setValue(
            `userForm.${index}.officeAdditionalDetails`,
            value && value?.officeAdditionalDetails
          );

          setValue(`userForm.${index}.country`, value && value?.country);
          setValue(`userForm.${index}.fatherName`, value && value?.fatherName);
          setValue(
            `userForm.${index}.dob`,
            value && value?.dob === "Invalid date" ? "" : value?.dob
          );
          setValue(`userForm.${index}.inTime`, value && value?.inTime);
          setValue(`userForm.${index}.outTime`, value && value?.outTime);
          setValue(
            `userForm.${index}.visitorNote1`,
            value && value?.visitorNote1
          );
          setValue(
            `userForm.${index}.visitorNote2`,
            value && value?.visitorNote2
          );
          setValue(`userForm.${index}.education`, value && value?.education);
          setValue(
            `userForm.${index}.qualification`,
            value && value?.qualification
          );
        });
      }
    }
  }, [state]);

  const { mutate, isLoading } = useMutation(
    (postData) =>
      axios.post(`${process.env.REACT_APP_HOST}/newvisitors`, postData),
    {
      onSuccess: (data) => {
        navigate("/");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  const upData = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/newvisitors/${state?.id}`,
        postData
      ),
    {
      onSuccess: () => {
        navigate("/");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  // function calculateAge(birthDate, index) {
  //   console.log("birthDate", moment().diff(moment(birthDate), "years"), index);
  //   setValue("age", moment().diff(moment(birthDate), "years") || "");
  //   if (birthDate && index === 0) {
  //     userForm?.map((items) => {
  //       console.log("welcome");
  //       if (index === 0) {
  //         setValue(
  //           `userForm.${index}.age`,
  //           moment().diff(moment(birthDate), "years") || ""
  //         );
  //       }
  //     });
  //   } else if (birthDate && index) {
  //     userForm?.map((items) => {
  //       // console.log("welcome");
  //       if (index === 0) {
  //         setValue(
  //           `userForm.${index}.age`,
  //           moment().diff(moment(birthDate), "years") || ""
  //         );
  //       } else {
  //         setValue(
  //           `userForm.${index}.age`,
  //           moment().diff(moment(birthDate), "years") || ""
  //         );
  //       }
  //     });
  //   }
  //   // return moment().diff(moment(birthDate), "years");
  // }
  function calculateAge(birthDate, index) {
    if (!birthDate) return;

    const age = moment().diff(moment(birthDate), "years") || "";

    if (index >= 0) {
      setValue(`userForm.${index}.age`, age);
    } else {
      setValue("age", age);
    }
  }

  const onSubmit = (data) => {
    const formData = {
      name: data?.name,
      mobileNumber: data?.mobileNumber,
      category: data?.category,
      gender: data?.gender,
      otherGender: data?.otherGender,
      age: data?.age,
      // fileNumber: data?.fileNumber,
      alternativeNumber: data?.alternativeNumber,
      landLineCode: data?.landLineCode,
      sourceForm: data?.sourceForm,
      permanentAddress: data?.permanentAddress,
      temporaryAddress: data?.temporaryAddress,
      officeAddress: data?.officeAddress,
      placeOfResidence: data?.placeOfResidence,
      district: data?.district,
      pincode: data?.pincode,
      inTime: data?.inTime,
      outTime: data?.outTime,
      state: data?.state,
      dateOfVisit: data?.dateOfVisit,
      dateOfAppointment: data?.dateOfAppointment,
      additionalStatus: data?.additionalStatus,
      status: data?.status,
      email: data?.email,
      whatsapp: data?.whatsapp,
      caste: data?.caste,
      religion: data?.religion,
      date: data?.date,
      otherReligion: data?.otherReligion,
      otherSourceForm: data?.otherSourceForm,
      otherCategory: data?.otherCategory,
      permanentFamilyName: data?.permanentFamilyName,
      permanentAddNo: data?.permanentAddNo,
      permanentAddEmail: data?.permanentAddEmail,
      officeName: data?.officeName,
      officeAddNo: data?.officeAddNo,
      officeAdd1No: data?.officeAdd1No,
      officeAddEmail: data?.officeAddEmail,
      country: data?.country,
      fatherName: data?.fatherName,
      dob: moment(data?.dob ? data?.dob : "").format("YYYY-MM-DD"),
      purposeOfVisit: data?.purposeOfVisit,
      permanentAdditionalDetails: data?.permanentAdditionalDetails,
      officeAdditionalDetails: data?.officeAdditionalDetails,
      visitorNote1: data?.visitorNote1,
      visitorNote2: data?.visitorNote2,
      qualification: data?.qualification,
      education: data?.education,
      duplicatevisitor: userForm,
    };
    console.log("education", data);
    if (temporaryAddress) {
      setPostAlert(formData);
    } else {
      setAddressAlert("Enter visitor Address");
    }
  };

  const handleFocus = (fieldName, labelName, currentValue = "") => {
    setCurrentField({ fieldName, labelName, value: currentValue });
    if (currentValue?.length > 55) {
      setModalOpen(true); // Open modal if text length > 15
    }
  };

  const handleChange = (fieldName, value) => {
    setFormValues((prev) => ({ ...prev, [fieldName]: value }));

    if (value?.length > 55 && !isModalOpen) {
      setCurrentField({ fieldName, labelName: fieldName, value });
      setModalOpen(true);
    }
  };

  const handleSave = (value) => {
    if (!currentField) return;
    if (userForm?.length > 0) {
      userForm.forEach((user, index) => {
        setValue(currentField.fieldName, value);
      });
    } else {
      setValue(currentField.fieldName, value);
    }

    setModalOpen(false);
    setCurrentField(null);
  };

  const mobileNumberCheckBox = (e, index) => {
    const checked = e.target.checked;

    if (checked) {
      console.log("checked", checked, index);
      userForm?.forEach((user, idx) => {
        // setValue("whatsapp", user.mobileNumber);
        setValue(`userForm.${index}.whatsapp`, user.mobileNumber);
      });
      setAlsoWhatApp(true);
    } else {
      userForm?.forEach((user, idx) => {
        setValue(`userForm.${index}.whatsapp`, " ");
      });
      setAlsoWhatApp(false);
    }
  };
  const mobileCheckBox = (e, index) => {
    const checked = e.target.checked;

    if (checked) {
      setValue("whatsapp", mobileNumber);
      setAlsoWhatApp(true);
    } else {
      setValue("whatsapp", "");
      setAlsoWhatApp(false);
    }
  };

  const calculateDOB = (age, index) => {
    if (age) {
      const year = new Date().getFullYear() - age; // Calculate birth year
      const dob = set(new Date(year, 0, 1), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }); // Set DOB to 01/01/YYYY

      if (index >= 0) {
        setValue(`userForm.${index}.dob`, dob);
      } else {
        setValue("dob", dob);
      }
    } else {
      if (index >= 0) {
        setValue(`userForm.${index}.dob`, "");
      } else {
        setValue("dob", "");
      }
    }
  };
  // console.log("districts", districts);
  return (
    <div className="mainContainerDiv" style={{ overflow: "scroll" }}>
      <section>
        <div className="headerContainer" id={`${0}form`}>
          <header className="visitorEntryHeader">Visitor Entry</header>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="visitorEntryFormInput">
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Name</label>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <Controller
                    control={control}
                    name="name"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                    S/o or D/o / other
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="fatherName"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div style={{ width: "18%", display: "flex" }}>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "78%", marginRight: "2%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">DOB</label>
                    </div>
                    <Controller
                      control={control}
                      name="dob"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          className="visiterInfoCss rounded-start"
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={95} // Includes the current year + 40 previous years
                          selected={field.value}
                          maxDate={new Date()} // Restrict to current date and earlier
                          minDate={subYears(new Date(), 95)} // Restrict to 40 years before the current date
                          onChange={(date) => {
                            field.onChange(date);
                            calculateAge(date);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "20%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Age
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="age"
                      // render={({ field }) => (
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                      }) => (
                        <input
                          // {...field}
                          style={error ? { borderColor: "red" } : {}}
                          className="visiterInfoCss"
                          type="number"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            calculateDOB(e.target.value);
                          }}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Education
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="education"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Qualification
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="qualification"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Gender<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="gender"
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <select
                        style={error ? { borderColor: "red" } : {}}
                        value={value}
                        className="visiterInfoCss py-2"
                        onChange={onChange}
                        onBlur={onBlur} // Added onBlur for better form validation handling
                        ref={ref} // Make sure the ref is passed down for proper field tracking
                      >
                        <option value=""> </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Gay">Gay</option>
                        <option value="Lesbian">Lesbian</option>
                        <option value="Other">Other</option>
                      </select>
                    )}
                    rules={{ required: true }}
                  />
                </div>
                {gender === "Other" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Other Gender
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="otherGender"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Caste</label>
                  </div>
                  <Controller
                    control={control}
                    name="caste"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Religion
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="religion"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        className="visiterInfoCss py-2"
                        onChange={onChange}
                        onBlur={onBlur} // Added onBlur for better form validation handling
                        ref={ref} // Make sure the ref is passed down for proper field tracking
                      >
                        <option value=""> </option>
                        <option value="Christianity">Christianity</option>
                        <option value="Islam">Islam</option>
                        <option value="Hinduism">Hinduism</option>
                        <option value="Other">Other</option>
                      </select>
                    )}
                  />
                </div>
                {religion === "Other" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Other Religion
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="otherReligion"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}

                <div className="visitorInfoMobile">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Mobile Number<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <Controller
                    key={
                      alsoWhatApp ? "whatsapp-synced" : "whatsapp-independent"
                    }
                    control={control}
                    name="mobileNumber"
                    rules={{
                      required: ".",
                      minLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits",
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits",
                      },
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit mobile number",
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <PinInput
                          required
                          length={10}
                          initialValue={value || ""}
                          onChange={(val) => {
                            onChange(val);

                            if (alsoWhatApp && val?.length === 10) {
                              setValue("whatsapp", val);
                            }
                          }}
                          type="numeric"
                          style={error ? { borderColor: "red" } : {}}
                          // style={{ width: "100%", borderRadius: "5px" }}
                          inputStyle={{
                            ...{
                              border: "1px solid gray",
                              backgroundColor: "#fff",
                              height: "40px",
                              width: "10%",
                              margin: "0px",
                              borderRadius: "5px",
                            },
                            ...(error ? { borderColor: "red" } : {}),
                          }}
                          autoSelect
                          onComplete={(value, index) => {
                            console.log("onComplete", value);
                            setMobileNumberData(value);
                          }}
                        />
                        {error && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "5px",
                            }}
                          >
                            {error.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className="visitorInfoMobile">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      WhatsApp Number
                    </label>
                  </div>
                  <Controller
                    key={
                      alsoWhatApp ? "whatsapp-synced" : "whatsapp-independent"
                    } // Unique key for re-rendering
                    control={control}
                    name="whatsapp"
                    rules={{
                      minLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits", // Error message when length is less than 10
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits", // Error message when length is more than 10
                      },
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit mobile number", // Ensure it's numeric
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PinInput
                        length={10}
                        initialValue={value}
                        value={value || ""}
                        onChange={(val) => onChange(val)}
                        type="numeric"
                        style={{ width: "100%", borderRadius: "5px" }}
                        inputStyle={{
                          border: "1px solid gray",
                          backgroundColor: "#fff",
                          height: "40px",
                          width: "10%",
                          margin: "0px",
                          borderRadius: "5px",
                        }}
                        autoSelect
                      />
                    )}
                  />
                  <div
                    style={{ fontSize: "12px", cursor: "pointer" }}
                    className="d-flex pt-1 justify-content-end"
                  >
                    <label className="d-flex gap-1 align-items-center">
                      <input
                        onChange={(e) => {
                          // mobileNumberCheckBox(e);
                          mobileCheckBox(e);
                        }}
                        type="checkbox"
                        style={{ width: "15px", height: "15px" }}
                      />
                      Also Same As Above
                    </label>
                  </div>
                </div>
                <div style={{ width: "18%", display: "flex" }}>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "20%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">Code</label>
                    </div>
                    <Controller
                      control={control}
                      name="landLineCode"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "78%", marginLeft: "2%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Landline Number
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="alternativeNumber"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="number"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      E Mail<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="email"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="email"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Category<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="category"
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <select
                        className="visiterInfoCss"
                        onChange={onChange}
                        value={value}
                        style={error ? { borderColor: "red" } : {}}
                      >
                        <option value=" "> </option>
                        <option value="Visitor">Visitor</option>
                        <option value="Client">Client</option>
                        <option value="Perspective">Perspective</option>
                        <option value="Business">Business</option>
                        <option value="Interview">Interview</option>

                        <option value="Other">Other</option>
                      </select>
                    )}
                    rules={{ required: true }}
                  />
                </div>
                {category === "Other" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        other Category
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="otherCategory"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Reference
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="sourceForm"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        className="visiterInfoCss"
                        onChange={onChange}
                      >
                        <option value=" "> </option>
                        <option value="JustDial">Just Dial</option>
                        <option value="YouTube">YouTube</option>
                        <option value="Google">Google</option>
                        <option value="Advertisement">Advertisement</option>
                        <option value="SocialMedia">Social Media</option>
                        <option value="Referred">Referred By</option>
                        <option value="Other">Other</option>
                      </select>
                    )}
                  />
                </div>
                {sourceForm === "Other" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Other Reference
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="otherSourceForm"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}
                {sourceForm === "SocialMedia" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Social Media Name
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="otherSourceForm"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}
                {sourceForm === "Referred" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Reference By
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="otherSourceForm"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Date of visit<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="dateOfVisit"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                      showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50} 
                          maxDate={new Date()} 
                          minDate={subYears(new Date(), 50)}
                        dateFormat="dd/MM/yyyy eeee"
                        placeholderText="DD/MM/YYYY"
                        className={`visiterInfoCss ${
                          error && "border border-danger"
                        } `}
                        selected={value}
                        onChange={(date) => onChange(date)}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Date of Entry
                    </label>
                  </div>

                  <Controller
                    control={control}
                    name="dateOfAppointment"
                    defaultValue={moment().toDate()}
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="dd/MM/yyyy eeee" // Format the date to display (e.g., Mon 06/11/2024)
                        className="visiterInfoCss"
                        // showYearDropdown
                        //   scrollableYearDropdown
                        readOnly
                        selected={
                          field.value ? moment(field.value).toDate() : null
                        } // Convert moment to native Date
                        onChange={(date) => field.onChange(moment(date))} // Convert selected date to moment
                        minDate={moment().toDate()}
                        maxDate={moment().toDate()}
                        disabled={false}
                      />
                    )}
                  />
                </div>

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Status</label>
                  </div>
                  <Controller
                    control={control}
                    name="status"
                    defaultValue="Active"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        className="visiterInfoCss"
                        value={value}
                        onChange={onChange}
                      >
                        <option value=""></option>
                        <option value="Active">Active</option>
                        <option value="In Active"> In Active</option>
                      </select>
                    )}
                  />
                </div>
                <div className="d-flex" style={{ width: "18%" }}>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "50%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">
                        In Time<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="inTime"
                      render={({ field, fieldState: { error } }) => (
                        <input
                          {...field}
                          type="time"
                          className={`visiterInfoCss ${
                            error && "border border-danger"
                          } `}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "50%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Out Time<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="outTime"
                      render={({ field, fieldState: { error } }) => (
                        <input
                          {...field}
                          type="time"
                          className={`visiterInfoCss ${
                            error && "border border-danger"
                          } `}
                          // Set the min value dynamically based on In Time
                          min={inTime || ""}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                </div>

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Purpose of visit
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="purposeOfVisit"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        ref={ref}
                        style={{ height: "40px", width: "100%",fontSize:"14px" }}
                        value={value}
                        className="visiterInfoCss"
                        onFocus={() =>
                          handleFocus(
                            "purposeOfVisit",
                            "Purpose of visit",
                            value
                          )
                        }
                        onChange={(e) => {
                          onChange(e.target.value);
                          handleChange("purposeOfVisit", e.target.value);
                        }}
                        onBlur={onBlur}
                        disabled={isModalOpen}
                      ></textarea>
                    )}
                  />
                </div>
                <div
                  className="visitorEntryInputContainer"
                  // style={{ width: "18%", height: "50px" }}
                >
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Additional Particulars
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="additionalStatus"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        ref={ref}
                        style={{ height: "40px", width: "100%",fontSize:"14px" }}
                        value={value}
                        className="visiterInfoCss"
                        onFocus={() =>
                          handleFocus(
                            "additionalStatus",
                            "Additional Particulars",
                            value
                          )
                        }
                        onChange={(e) => {
                          onChange(e.target.value);
                          handleChange("additionalStatus", e.target.value);
                        }}
                        onBlur={onBlur}
                        disabled={isModalOpen}
                      ></textarea>
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Note 1</label>
                  </div>
                  <Controller
                    control={control}
                    name="visitorNote1"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        ref={ref}
                        style={{ height: "40px", width: "100%",fontSize:"14px" }}
                        value={value}
                        className="visiterInfoCss"
                        onFocus={() =>
                          handleFocus("visitorNote1", "Note 1", value)
                        }
                        onChange={(e) => {
                          onChange(e.target.value);
                          handleChange("visitorNote1", e.target.value);
                        }}
                        onBlur={onBlur}
                        disabled={isModalOpen}
                      ></textarea>
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Specific Note
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="visitorNote2"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        ref={ref}
                        style={{ height: "40px", width: "100%",fontSize:"14px" }}
                        value={value}
                        className="visiterInfoCss"
                        onFocus={() =>
                          handleFocus("visitorNote2", "Specific Note", value)
                        }
                        onChange={(e) => {
                          onChange(e.target.value);
                          handleChange("visitorNote2", e.target.value);
                        }}
                        onBlur={onBlur}
                        disabled={isModalOpen}
                      ></textarea>
                    )}
                  />
                </div>
                <div className="" style={{ width: "18%" }}>
                  <div
                    style={
                      temporaryAddress
                        ? {
                            backgroundColor: "green",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                        : {
                            backgroundColor: "red",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                    }
                    title={temporaryAddress || "No address available"}
                    className="visitorInfoShowBtn mb-0 fs-6 px-2 pb-0 text-center"
                    onClick={() => {
                      setShowPresentAdd(true);
                      setAddress({ add: temporaryAddress });
                    }}
                  >
                    Present Address<span style={{ color: "white" }}>*</span>
                  </div>
                  <div>
                    <p
                      style={{
                        alignContent: "center",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {addressAlert && "Enter visitor Address"}
                    </p>
                  </div>
                </div>

                <div className="d-flex" style={{ width: "18%" }}>
                  <div
                    className="visitorInfoShowBtn fs-6 px-2 text-center"
                    title={officeAddress || "No address available"}
                    style={
                      officeName ||
                      officeAddNo ||
                      officeAdd1No ||
                      officeAddEmail ||
                      officeAdditionalDetails ||
                      officeAddress
                        ? {
                            backgroundColor: "green",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                        : {
                            backgroundColor: "red",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                    }
                    onClick={() => {
                      setShowOfficeAdd(true);
                      setAddress({
                        name: officeName,
                        add: officeAddress,
                        mNo: officeAddNo,
                        aNo: officeAdd1No,
                        email: officeAddEmail,
                        addInfo: officeAdditionalDetails,
                      });
                    }}
                  >
                    {/* Office Address & Permanent Address */}
                    Office Address
                  </div>
                </div>
                <div className="d-flex" style={{ width: "18%" }}>
                  <div
                    className="visitorInfoShowBtn fs-6 px-2 text-center"
                    title={permanentAddress || "No address available"}
                    style={
                      permanentFamilyName ||
                      permanentAddNo ||
                      permanentAddEmail ||
                      permanentAdditionalDetails ||
                      permanentAddress
                        ? {
                            backgroundColor: "green",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                        : {
                            backgroundColor: "red",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                            height: "40px",
                            alignContent: "center",
                          }
                    }
                    onClick={() => {
                      setShowPermanentAddress(true);
                      setAddress({
                        name: permanentFamilyName,
                        add: permanentAddress,
                        mNo: permanentAddNo,
                        email: permanentAddEmail,
                        addInfo: permanentAdditionalDetails,
                      });
                    }}
                  >
                    {/* Office Address & Permanent Address */}
                    Permanent Address
                  </div>
                  {/* <p style={{ alignContent: "center", marginLeft: "10px" }}>
                    {(permanentAddNo ||
                      permanentAddEmail ||
                      permanentAdditionalDetails ||
                      permanentAddress) && <img src={added} alt="" />}
                  </p> */}
                </div>
                <div
                  style={{ width: "100%" }}
                  className="d-flex justify-content-center"
                >
                  {page && (
                    <div>
                      <a
                        href={`#${1}form`}
                        style={{
                          width: "210px",
                          textDecorationLine: "none",
                        }}
                        id="field"
                        onClick={() => {
                          // window.scrollTo(0,1000)
                          // append({}, { shouldFocus: false });
                        }}
                        className="visitorInfoSaveBtn px-2 py-1 pb-2"
                      >
                        Next
                      </a>
                    </div>
                  )}
                </div>
              </div>

              <div>
                {fields?.map((field, index) => {
                  console.log("page", page, index);
                  return (
                    <div
                      className="pt-3 "
                      key={field.id}
                      id={`${index + 1}form`}
                    >
                      <div className="headerContainer">
                        <header className="visitorEntryHeader">
                          Co-Visitor {index + 1}
                        </header>
                      </div>
                      <div className="visitorEntryFormInput">
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Name<span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.name`}
                            render={(field) => (
                              <InputField
                                visiterInfoCss="visiterInfoCss"
                                {...field}
                                type="text"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                            S/o or D/o / other
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.fatherName`}
                            render={(field) => (
                              <InputField
                                {...field}
                                visiterInfoCss="visiterInfoCss"
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div style={{ width: "18%", display: "flex" }}>
                          <div
                            className="visitorEntryInputContainer"
                            style={{ width: "78%", marginRight: "2%" }}
                          >
                            <div>
                              <label className="visitorInfoLabelContainer">
                                DOB
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.dob`}
                              render={({ field }) => (
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="DD/MM/YYYY"
                                  className="visiterInfoCss rounded-start"
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={95} // Includes the current year + 40 previous years
                                  selected={field.value}
                                  maxDate={new Date()} // Restrict to current date and earlier
                                  minDate={subYears(new Date(), 95)} // Restrict to 40 years before the current date
                                  onChange={(date) => {
                                    field.onChange(date);
                                    calculateAge(date, index);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div
                            className="visitorEntryInputContainer"
                            style={{ width: "20%" }}
                          >
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Age<span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.age`}
                              render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState: { error },
                              }) => (
                                <input
                                  style={error ? { borderColor: "red" } : {}}
                                  // {...field}
                                  value={value}
                                  className="visiterInfoCss"
                                  type="number"
                                  onChange={(e) => {
                                    onChange(e);
                                    calculateDOB(e.target.value, index);
                                  }}
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </div>
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Education
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.education`}
                            render={(field) => (
                              <InputField
                                {...field}
                                visiterInfoCss="visiterInfoCss"
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Qualification
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.qualification`}
                            render={(field) => (
                              <InputField
                                {...field}
                                visiterInfoCss="visiterInfoCss"
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Gender<span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.gender`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                              fieldState: { error },
                            }) => (
                              <select
                                style={error ? { borderColor: "red" } : {}}
                                value={value}
                                className="visiterInfoCss py-2"
                                onChange={onChange}
                                onBlur={onBlur} // Added onBlur for better form validation handling
                                ref={ref} // Make sure the ref is passed down for proper field tracking
                              >
                                <option value=""> </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Gay">Gay</option>
                                <option value="Lesbian">Lesbian</option>
                                <option value="Other">Other</option>
                              </select>
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                        {userForm[index]?.gender === "Other" && (
                          <div className="visitorEntryInputContainer">
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Other Gender
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.otherGender`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        )}
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Caste
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.caste`}
                            render={(field) => (
                              <InputField
                                {...field}
                                visiterInfoCss="visiterInfoCss"
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Religion
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.religion`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <select
                                value={value}
                                className="visiterInfoCss py-2"
                                onChange={onChange}
                                onBlur={onBlur} // Added onBlur for better form validation handling
                                ref={ref} // Make sure the ref is passed down for proper field tracking
                              >
                                <option value=""> </option>
                                <option value="Christian">Christian</option>
                                <option value="Muslim">Muslim</option>
                                <option value="Hindu">Hindu</option>
                                <option value="Other">Other</option>
                              </select>
                            )}
                          />
                        </div>
                        {userForm[index]?.religion === "Other" && (
                          <div className="visitorEntryInputContainer">
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Other Religion
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.otherReligion`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        )}
                        

                        <div className="visitorInfoMobile">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Mobile Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                          <Controller
                            key={
                              alsoWhatApp
                                ? "whatsapp-synced"
                                : "whatsapp-independent"
                            }
                            control={control}
                            name={`userForm.${index}.mobileNumber`}
                            rules={{
                              required: ".",
                              minLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits",
                              },
                              maxLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits",
                              },
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message:
                                  "Please enter a valid 10-digit mobile number",
                              },
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <>
                                <PinInput
                                  length={10}
                                  initialValue={value || ""}
                                  onChange={(val) => {
                                    onChange(val);

                                    if (alsoWhatApp && val?.length === 10) {
                                      setValue("whatsapp", val);
                                    }
                                  }}
                                  type="numeric"
                                  style={{ width: "100%", borderRadius: "5px" }}
                                  inputStyle={{
                                    ...{
                                      border: "1px solid gray",
                                      backgroundColor: "#fff",
                                      height: "40px",
                                      width: "10%",
                                      margin: "0px",
                                      borderRadius: "5px",
                                    },
                                    ...(error ? { borderColor: "red" } : {}),
                                  }}
                                  autoSelect
                                  onComplete={(value, index) => {
                                    console.log("onComplete", value);
                                    setMobileNumberData(value);
                                  }}
                                />
                                {error && (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {error.message}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </div>

                        <div className="visitorInfoMobile">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              WhatsApp Number
                            </label>
                          </div>
                          <Controller
                            key={
                              alsoWhatApp
                                ? "whatsapp-synced"
                                : "whatsapp-independent"
                            } // Unique key for re-rendering
                            control={control}
                            name={`userForm.${index}.whatsapp`}
                            rules={{
                              minLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message when length is less than 10
                              },
                              maxLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message when length is more than 10
                              },
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message:
                                  "Please enter a valid 10-digit mobile number", // Ensure it's numeric
                              },
                            }}
                            render={({ field: { onChange, value } }) => (
                              <PinInput
                                length={10}
                                initialValue={value}
                                value={value || ""}
                                onChange={(val) => onChange(val)}
                                type="numeric"
                                style={{ width: "100%", borderRadius: "5px" }}
                                inputStyle={{
                                  border: "1px solid gray",
                                  backgroundColor: "#fff",
                                  height: "40px",
                                  width: "10%",
                                  margin: "0px",
                                  borderRadius: "5px",
                                }}
                                autoSelect
                              />
                            )}
                          />
                          <div
                            style={{ fontSize: "12px", cursor: "pointer" }}
                            className="d-flex pt-1 justify-content-end"
                          >
                            <label className="d-flex gap-1 align-items-center">
                              <input
                                onChange={(e) => {
                                  mobileNumberCheckBox(e, index);
                                  // mobileCheckBox(e);
                                }}
                                type="checkbox"
                                style={{ width: "15px", height: "15px" }}
                              />
                              Also Same As Above
                            </label>
                          </div>
                        </div>

                        <div style={{ width: "18%", display: "flex" }}>
                          <div
                            className="visitorEntryInputContainer"
                            style={{ width: "20%" }}
                          >
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Code
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.landLineCode`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                          <div
                            className="visitorEntryInputContainer"
                            style={{ width: "78%", marginLeft: "2%" }}
                          >
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Landline Number
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.alternativeNumber`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="number"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              E Mail<span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.email`}
                            render={(field) => (
                              <InputField
                                {...field}
                                visiterInfoCss="visiterInfoCss"
                                type="email"
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>

                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Category<span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.category`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                              fieldState: { error },
                            }) => (
                              <select
                                className="visiterInfoCss"
                                onChange={onChange}
                                value={value}
                                style={error ? { borderColor: "red" } : {}}
                              >
                                <option value=" "> </option>
                                <option value="Visitor">Visitor</option>
                                <option value="Client">Client</option>
                                <option value="Perspective">paspective</option>
                                <option value="Business">Business</option>
                                <option value="Interview">Interview</option>

                                <option value="Other">Other</option>
                              </select>
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                        {userForm[index]?.category === "Other" && (
                          <div className="visitorEntryInputContainer">
                            <div>
                              <label className="visitorInfoLabelContainer">
                                other Category
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.otherCategory`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        )}
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Reference
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.sourceForm`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <select
                                value={value}
                                className="visiterInfoCss"
                                onChange={onChange}
                              >
                                <option value=" "> </option>
                                <option value="JustDial">Just Dial</option>
                                <option value="YouTube">YouTube</option>
                                <option value="Google">Google</option>
                                <option value="Advertisement">
                                  Advertisement
                                </option>
                                <option value="SocialMedia">
                                  Social Media
                                </option>
                                <option value="Referred">Referred By</option>
                                <option value="Other">Other</option>
                              </select>
                            )}
                          />
                        </div>
                        {userForm[index]?.sourceForm === "Other" && (
                          <div className="visitorEntryInputContainer">
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Other Reference
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.otherSourceForm`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        )}
                        {userForm[index]?.sourceForm === "SocialMedia" && (
                          <div className="visitorEntryInputContainer">
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Social Media Name
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.otherSourceForm`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        )}
                        {userForm[index]?.sourceForm === "Referred" && (
                          <div className="visitorEntryInputContainer">
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Reference By
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.otherSourceForm`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  visiterInfoCss="visiterInfoCss"
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        )}

                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Date of visit
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.dateOfVisit`}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <DatePicker
                                dateFormat="dd/MM/yyyy eeee"
                                placeholderText="DD/MM/YYYY"
                                showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50} 
                          maxDate={new Date()} 
                          minDate={subYears(new Date(), 50)}
                                className={`visiterInfoCss ${
                                  error && "border border-danger"
                                } `}
                                selected={value}
                                onChange={(date) => onChange(date)}
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Date of Entry
                            </label>
                          </div>

                          <Controller
                            control={control}
                            name={`userForm.${index}.dateOfAppointment`}
                            defaultValue={moment().toDate()}
                            render={({ field }) => (
                              <DatePicker
                                dateFormat="dd/MM/yyyy eeee"
                                className="visiterInfoCss"
                                showYearDropdown
                          scrollableYearDropdown
                                readOnly
                                selected={
                                  field.value
                                    ? moment(field.value).toDate()
                                    : null
                                } // Convert moment to native Date
                                onChange={(date) =>
                                  field.onChange(moment(date))
                                } // Convert selected date to moment
                                minDate={moment().toDate()}
                                maxDate={moment().toDate()}
                                disabled={false}
                              />
                            )}
                          />
                        </div>

                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Status
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.status`}
                            defaultValue="Active"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <select
                                className="visiterInfoCss"
                                value={value}
                                onChange={onChange}
                              >
                                <option value=""></option>
                                <option value="Active">Active</option>
                                <option value="In Active"> In Active</option>
                              </select>
                            )}
                          />
                        </div>
                        <div className="d-flex" style={{ width: "18%" }}>
                          <div
                            className="visitorEntryInputContainer"
                            style={{ width: "50%" }}
                          >
                            <div>
                              <label className="visitorInfoLabelContainer">
                                In Time<span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.inTime`}
                              render={({ field, fieldState: { error } }) => (
                                <input
                                  {...field}
                                  type="time"
                                  className={`visiterInfoCss ${
                                    error && "border border-danger"
                                  } `}
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </div>
                          <div
                            className="visitorEntryInputContainer"
                            style={{ width: "50%" }}
                          >
                            <div>
                              <label className="visitorInfoLabelContainer">
                                Out Time<span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                            <Controller
                              control={control}
                              name={`userForm.${index}.outTime`}
                              render={({ field, fieldState: { error } }) => (
                                <input
                                  {...field}
                                  type="time"
                                  className={`visiterInfoCss ${
                                    error && "border border-danger"
                                  } `}
                                  min={userForm[index]?.inTime || ""}
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </div>
                        </div>

                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Purpose of visit
                            </label>
                          </div>
                          <Controller
                            control={control}
                            // name={`userForm.${index}.purposeOfVisit`}
                            name={`userForm.${index}.purposeOfVisit`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <textarea
                                ref={ref}
                                style={{ height: "40px", width: "100%",fontSize:"14px" }}
                                value={value}
                                className="visiterInfoCss"
                                onFocus={() =>
                                  handleFocus(
                                    `userForm.${index}.purposeOfVisit`,
                                    "Purpose of visit",
                                    value
                                  )
                                }
                                onChange={(e) => {
                                  onChange(e.target.value);
                                  handleChange(
                                    `userForm.${index}.purposeOfVisit`,
                                    e.target.value
                                  );
                                }}
                                onBlur={onBlur}
                                disabled={isModalOpen}
                              ></textarea>
                            )}
                          />
                        </div>
                        <div
                          className="visitorEntryInputContainer"
                          // style={{ width: "18%", height: "50px" }}
                        >
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Additional Particulars
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.additionalStatus`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <textarea
                                ref={ref}
                                style={{ height: "40px", width: "100%",fontSize:"14px" }}
                                value={value}
                                className="visiterInfoCss"
                                onFocus={() =>
                                  handleFocus(
                                    `userForm.${index}.additionalStatus`,
                                    "Additional Particulars",
                                    value
                                  )
                                }
                                onChange={(e) => {
                                  onChange(e.target.value);
                                  handleChange(
                                    `userForm.${index}.additionalStatus`,
                                    e.target.value
                                  );
                                }}
                                onBlur={onBlur}
                                disabled={isModalOpen}
                              ></textarea>
                            )}
                          />
                        </div>

                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Note 1
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.visitorNote1`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <textarea
                                ref={ref}
                                style={{ height: "40px", width: "100%",fontSize:"14px" }}
                                value={value}
                                className="visiterInfoCss"
                                onFocus={() =>
                                  handleFocus(
                                    `userForm.${index}.visitorNote1`,
                                    "Note 1",
                                    value
                                  )
                                }
                                onChange={(e) => {
                                  onChange(e.target.value);
                                  handleChange(
                                    `userForm.${index}.visitorNote1`,
                                    e.target.value
                                  );
                                }}
                                onBlur={onBlur}
                                disabled={isModalOpen}
                              ></textarea>
                            )}
                          />
                        </div>
                        <div className="visitorEntryInputContainer">
                          <div>
                            <label className="visitorInfoLabelContainer">
                              Specific Note
                            </label>
                          </div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.visitorNote2`}
                            r
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <textarea
                                ref={ref}
                                style={{ height: "40px", width: "100%",fontSize:"14px" }}
                                value={value}
                                className="visiterInfoCss"
                                onFocus={() =>
                                  handleFocus(
                                    `userForm.${index}.visitorNote2`,
                                    "Specific Note",
                                    value
                                  )
                                }
                                onChange={(e) => {
                                  onChange(e.target.value);
                                  handleChange(
                                    `userForm.${index}.visitorNote2`,
                                    e.target.value
                                  );
                                }}
                                onBlur={onBlur}
                                disabled={isModalOpen}
                              ></textarea>
                            )}
                          />
                        </div>

                        <div className="d-flex" style={{ width: "18%" }}>
                          <div
                            className="visitorInfoShowBtn fs-6 px-2 text-center"
                            title={
                              userForm[index]?.temporaryAddress ||
                              "No address available"
                            }
                            style={
                              userForm[index]?.temporaryAddress
                                ? {
                                    backgroundColor: "green",
                                    color: "white",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "40px",
                                    alignContent: "center",
                                  }
                                : {
                                    backgroundColor: "red",
                                    color: "white",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "40px",
                                    alignContent: "center",
                                  }
                            }
                            onClick={() => {
                              setShowPresentAddAppend(index);
                              setAddress({
                                add: userForm[index]?.temporaryAddress,
                              });
                            }}
                          >
                            Present Address
                          </div>
                        </div>
                        <div className="d-flex" style={{ width: "18%" }}>
                          <div
                            className="visitorInfoShowBtn fs-6 px-2 text-center"
                            title={
                              userForm[index]?.officeAddress ||
                              "No address available"
                            }
                            style={
                              userForm[index]?.officeName ||
                              userForm[index]?.officeAddress ||
                              userForm[index]?.officeAddNo ||
                              userForm[index]?.officeAdd1No ||
                              userForm[index]?.officeAddEmail ||
                              userForm[index]?.officeAdditionalDetails
                                ? {
                                    backgroundColor: "green",
                                    color: "white",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "40px",
                                    alignContent: "center",
                                  }
                                : {
                                    backgroundColor: "red",
                                    color: "white",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "40px",
                                    alignContent: "center",
                                  }
                            }
                            onClick={() => {
                              setOfficeAppend(index);
                              setAddress({
                                name: userForm[index]?.officeName,
                                add: userForm[index]?.officeAddress,
                                mNo: userForm[index]?.officeAddNo,
                                aNo: userForm[index]?.officeAdd1No,
                                email: userForm[index]?.officeAddEmail,
                                addInfo:
                                  userForm[index]?.officeAdditionalDetails,
                              });
                            }}
                          >
                            Office Address
                          </div>
                        </div>
                        <div className="d-flex" style={{ width: "18%" }}>
                          <div
                            className="visitorInfoShowBtn fs-6 px-2 text-center"
                            title={
                              userForm[index]?.permanentAddress ||
                              "No address available"
                            }
                            style={
                              userForm[index]?.permanentFamilyName ||
                              userForm[index]?.permanentAddress ||
                              userForm[index]?.permanentAddNo ||
                              userForm[index]?.permanentAddEmail ||
                              userForm[index]?.permanentAdditionalDetails
                                ? {
                                    backgroundColor: "green",
                                    color: "white",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "40px",
                                    alignContent: "center",
                                  }
                                : {
                                    backgroundColor: "red",
                                    color: "white",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "40px",
                                    alignContent: "center",
                                  }
                            }
                            onClick={() => {
                              // setShowPermanentAddAppend(index);
                              setShowPermanentAppend(index);
                              setAddress({
                                name: userForm[index]?.permanentFamilyName,
                                add: userForm[index]?.permanentAddress,
                                mNo: userForm[index]?.permanentAddNo,
                                email: userForm[index]?.permanentAddEmail,
                                addInfo:
                                  userForm[index]?.permanentAdditionalDetails,
                              });
                            }}
                          >
                            Permanent Address
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: "42px",
                            padding: "0px 30px 0px 0px",
                            display: "flex",
                            justifyContent:
                              index === userForm.length - 1
                                ? "space-between"
                                : "center",
                          }}
                        >
                          {index === userForm.length - 1 && (
                            <div className="d-flex justify-content-start">
                              <div
                                // href="/#submit"
                                style={{
                                  width: "212px",

                                  textDecorationLine: "none",
                                  alignContent: "center",

                                  height: "50px",
                                }}
                                id="field"
                                onClick={(e) => {
                                  e.preventDefault(); // Stop default anchor navigation
                                  append({}, { shouldFocus: false }); // Append new form entry

                                  // Wait for the new form to be added, then scroll
                                  setTimeout(() => {
                                    const targetElement =
                                      document.getElementById("submit");
                                    if (targetElement) {
                                      targetElement.scrollIntoView({
                                        behavior: "smooth",
                                      });
                                    }
                                  }, 100);
                                }}
                                className="visitorInfoSaveBtn  d-flex justify-content-center align-items-center "
                              >
                                Additional Visitor
                              </div>
                            </div>
                          )}
                          <div className=" d-flex gap-5 ">
                            <div
                              className="d-flex justify-content-start"
                              style={{ width: "100%" }}
                            >
                              <div
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                  width: "200px",
                                  height: "100%",
                                  alignContent: "center",
                                  backgroundColor: "red",
                                }}
                                onClick={() => {
                                  remove(index);
                                }}
                                className="visitorInfoSaveBtn text-center px-2"
                              >
                                Delete Detail
                              </div>
                            </div>
                            <div
                              style={{ width: "100%" }}
                              className="d-flex gap-5 justify-content-between text-center "
                            >
                              <div className="" style={{ width: "100%" }}>
                                {userForm && userForm.length > 0 && (
                                  <div className="d-flex gap-4 mt-1 justify-content-center">
                                    <div className="">
                                      <a
                                        onClick={() => {
                                          setPage(userForm.length);
                                        }}
                                        href={
                                          index === 0
                                            ? `#${0}form`
                                            : `#${index}form`
                                        }
                                        style={{
                                          // width: "210px",
                                          height: "42px",
                                          textDecorationLine: "none",
                                        }}
                                        className="visitorInfoSaveBtn px-2 py-1 pb-2"
                                      >
                                        Back
                                      </a>
                                    </div>

                                    {page && page - 1 !== index ? (
                                      <div>
                                        <a
                                          href={`#${index + 2}form`}
                                          style={{
                                            width: "210px",
                                            textDecorationLine: "none",
                                          }}
                                          id="field"
                                          onClick={() => {
                                            // window.scrollTo(0,1000)
                                            // append({}, { shouldFocus: false });
                                          }}
                                          className="visitorInfoSaveBtn px-2 py-1 pb-2"
                                        >
                                          Next
                                        </a>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {index === userForm.length - 1 && ( // Show only for last index
                            <div id="submit">
                              <button
                                type="submit"
                                id="aboutUs"
                                className="visitorInfoSaveBtn p-2 px-4"
                                style={{
                                  backgroundColor: "green",
                                  width: "210px",
                                }}
                              >
                                {isLoading ? (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {userForm < 1 && (
                <div className="d-flex gap-4 pe-5  mt-2 justify-content-between text-center ">
                  <div
                    className="d-flex justify-content-start"
                    style={{ width: "18%" }}
                  >
                    <div
                      // className="p-0 m-0"
                      href="#submit"
                      // style={{ width: "210px", textDecorationLine: "none" }}
                      style={{ width: "100%", textDecorationLine: "none" }}
                      id="field"
                      onClick={(e) => {
                        e.preventDefault(); // Stop default anchor navigation
                        append({}, { shouldFocus: false }); // Append new form entry

                        // Wait for the new form to be added, then scroll
                        setTimeout(() => {
                          const targetElement =
                            document.getElementById("submit");
                          if (targetElement) {
                            targetElement.scrollIntoView({
                              behavior: "smooth",
                            });
                          }
                        }, 100);
                      }}
                      className="visitorInfoSaveBtn p-2  "
                    >
                      Additional Visitor
                    </div>
                  </div>

                  <div id="submit">
                    <button
                      onClick={() => {
                        // console.log("hello");
                      }}
                      type="submit"
                      id="aboutUs"
                      className="visitorInfoSaveBtn p-2 px-4"
                      style={{ backgroundColor: "green", width: "210px" }}
                    >
                      {isLoading ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </section>
      <Alert
        open={postAlert}
        setPutAlert={setPutAlert}
        postAlert={postAlert}
        state={state}
        upData={upData}
        putAlert={putAlert}
        mutate={mutate}
        navigates="/"
        id={state?.id}
        add={true}
        onCloses={setPostAlert}
        // setPutAlert={setPutAlert}
      />
      {isModalOpen && currentField && (
        <Modal
          show={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSave}
          value={currentField?.value} // Pass current value to the modal
          currentField={currentField}
        />
      )}
      <AddressModal isOpen={showPresentAdd !== null}>
        <div className="mb-3 text-end">
          <img
            // title="Remove details and close"
            onClick={() => {
              if (address?.add !== temporaryAddress) {
                setPresentConfirm(true);
              } else {
                setShowPresentAdd(null);
              }
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Present Address
            </header>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "200px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name="temporaryAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    autoFocus
                    style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPresentAdd(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={showOfficeAdd !== null}>
        <div className="mb-2 text-end">
          <img
            // title="Remove details and close"
            onClick={() => {
              if (
                address?.name !== officeName ||
                address?.add !== officeAddress ||
                address?.mNo !== officeAddNo ||
                address?.aNo !== officeAdd1No ||
                address?.email !== officeAddEmail ||
                address?.addInfo !== officeAdditionalDetails
              ) {
                setOfficeConfirm(true);
              } else {
                setShowOfficeAdd(null);
              }
              // setOfficeConfirm(true);
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
              // marginBottom: "20px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div className="" style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Office Address Details
            </header>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Office Name</label>
              </div>
              <Controller
                control={control}
                name="officeName"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="text"
                  />
                )}
              />
            </div>

            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "120px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name="officeAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // autoFocus
                    // style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>

            <div className="my-2">
              <div className="d-flex gap-2">
                <div className="w-50">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Mobile No
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="officeAddNo"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
                <div className="w-50">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Additional No
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="officeAdd1No"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Mail id</label>
              </div>
              <Controller
                control={control}
                name="officeAddEmail"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>

            <div>
              <div>
                <label className="visitorInfoLabelContainer">
                  Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name="officeAdditionalDetails"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ height: "60px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowOfficeAdd(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={showPermanentAddress !== null}>
        <div className="mb-3 text-end">
          <img
            // title="Remove details and close"
            onClick={() => {
              if (
                address?.name !== permanentFamilyName ||
                address?.add !== permanentAddress ||
                address?.mNo !== permanentAddNo ||
                address?.email !== permanentAddEmail ||
                address?.addInfo !== permanentAdditionalDetails
              ) {
                setPermanentConfirm(true);
              } else {
                setShowPermanentAddress(null);
              }
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Permanent Address Details
            </header>
            <div>
              <div>
                <label className="visitorInfoLabelContainer ">
                  Family/House Name
                </label>
              </div>
              <Controller
                control={control}
                name="permanentFamilyName"
                render={(field) => (
                  <InputField
                    autoFocus={true}
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="text"
                  />
                )}
              />
            </div>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "120px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name="permanentAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
            <div>
              <div>
                <label className="visitorInfoLabelContainer ">Mobile No</label>
              </div>
              <Controller
                control={control}
                name="permanentAddNo"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="number"
                  />
                )}
              />
            </div>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer ">Mail id</label>
              </div>
              <Controller
                control={control}
                name="permanentAddEmail"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">
                  Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name="permanentAdditionalDetails"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ height: "60px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPermanentAddress(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={showPresentAddAppend !== null}>
        <div className="mb-3 text-end">
          <img
            // title="Remove details and close"
            onClick={() => {
              if (
                address?.add !==
                userForm[showPresentAddAppend]?.temporaryAddress
              ) {
                setAppPresentAdd(true);
              } else {
                setShowPresentAddAppend(null);
              }
              // setValue(`userForm.${showPresentAddAppend}.temporaryAddress`, "");
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Present Address
            </header>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "200px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name={`userForm.${showPresentAddAppend}.temporaryAddress`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ minHeight: "100px" }}
                    autoFocus
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPresentAddAppend(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={showOfficeAppend !== null}>
        <div className=" text-end mb-2">
          <img
            // title="Remove details and close"
            onClick={() => {
              if (
                address?.name !== userForm[showOfficeAppend]?.officeName ||
                address?.add !== userForm[showOfficeAppend]?.officeAddress ||
                address?.mNo !== userForm[showOfficeAppend]?.officeAddNo ||
                address?.aNo !== userForm[showOfficeAppend]?.officeAdd1No ||
                address?.email !== userForm[showOfficeAppend]?.officeAddEmail ||
                address?.addInfo !==
                  userForm[showOfficeAppend]?.officeAdditionalDetails
              ) {
                setAppOfficeAdd(true);
              } else {
                setOfficeAppend(null);
              }
              // setAppOfficeAdd(true);
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div className="" style={{ width: "100%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Office Address Details
            </header>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Office Name</label>
              </div>
              <Controller
                control={control}
                // name="officeName"
                name={`userForm.${showOfficeAppend}.officeName`}
                render={(field) => (
                  <InputField
                    autoFocus={true}
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%", height: "120px" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">Address</label>
              </div>

              <Controller
                control={control}
                name={`userForm.${showOfficeAppend}.officeAddress`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // autoFocus
                    // style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>

            <div className="my-2">
              <div className="d-flex gap-2">
                <div className="w-50">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Mobile No
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name={`userForm.${showOfficeAppend}.officeAddNo`}
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
                <div className="w-50">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Additional No
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name={`userForm.${showOfficeAppend}.officeAdd1No`}
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">Mail id</label>
              </div>
              <Controller
                control={control}
                name={`userForm.${showOfficeAppend}.officeAddEmail`}
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>

            <div>
              <div>
                <label className="visitorInfoLabelContainer">
                  Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name={`userForm.${showOfficeAppend}.officeAdditionalDetails`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ height: "60px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setOfficeAppend(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddressModal isOpen={showPermanentAppend !== null}>
        <div className=" text-end mb-2">
          <img
            // title="Remove details and close"
            onClick={() => {
              if (
                address?.name !==
                  userForm[showPermanentAppend]?.permanentFamilyName ||
                address?.add !==
                  userForm[showPermanentAppend]?.permanentAddress ||
                address?.mNo !==
                  userForm[showPermanentAppend]?.permanentAddNo ||
                address?.email !==
                  userForm[showPermanentAppend]?.permanentAddEmail ||
                address?.addInfo !==
                  userForm[showPermanentAppend]?.permanentAdditionalDetails
              ) {
                setAppPermanentAdd(true);
              } else {
                setShowPermanentAppend(null);
              }
            }}
            src={wrongIcon}
            style={{
              // backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div style={{ width: "100%", display: "" }}>
          <header
            className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
            style={{ color: "#fff" }}
          >
            Permanent Address Details
          </header>
          <div>
            <div>
              <label className="visitorInfoLabelContainer mt-2 ">
                Family/House Name
              </label>
            </div>
            <Controller
              control={control}
              name={`userForm.${showPermanentAppend}.permanentFamilyName`}
              render={(field) => (
                <InputField
                  autoFocus={true}
                  visiterInfoCss="visiterInfoCss"
                  {...field}
                  type="text"
                />
              )}
            />
          </div>
          <div
            className="visitorEntryInputContainer my-2"
            style={{ width: "100%", height: "120px" }}
          >
            <div>
              <label className="visitorInfoLabelContainer">Address</label>
            </div>

            <Controller
              control={control}
              name={`userForm.${showPermanentAppend}.permanentAddress`}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <textarea
                  // style={{ minHeight: "100px" }}

                  value={value}
                  className="visitorAddCss py-2"
                  onChange={onChange}
                ></textarea>
              )}
            />
          </div>
          <div>
            <div>
              <label className="visitorInfoLabelContainer ">Mobile No</label>
            </div>
            <Controller
              control={control}
              name={`userForm.${showPermanentAppend}.permanentAddNo`}
              render={(field) => (
                <InputField
                  visiterInfoCss="visiterInfoCss"
                  {...field}
                  type="number"
                />
              )}
            />
          </div>
          <div className="my-2">
            <div>
              <label className="visitorInfoLabelContainer ">Mail id</label>
            </div>
            <Controller
              control={control}
              name={`userForm.${showPermanentAppend}.permanentAddEmail`}
              render={(field) => (
                <InputField
                  visiterInfoCss="visiterInfoCss"
                  {...field}
                  type="email"
                />
              )}
            />
          </div>
          <div className="my-2">
            <div>
              <label className="visitorInfoLabelContainer">
                Additional Details
              </label>
            </div>
            <Controller
              control={control}
              name={`userForm.${showPermanentAppend}.permanentAdditionalDetails`}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <textarea
                  style={{ height: "60px" }}
                  value={value}
                  className="visitorAddCss py-2"
                  onChange={onChange}
                ></textarea>
              )}
            />
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPermanentAppend(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>

      <AddConfirmAlt isOpen={presentConfirm !== null}>
        <div className="addAltContainer">
          <div className="addAlt_text">Do you want to save changes?</div>
          <div className="addAltBtn">
            <div>
              <button
                className="dont-save-btn"
                onClick={() => {
                  setShowPresentAdd(null);
                  setValue("temporaryAddress", address?.add);
                  setPresentConfirm(null);
                }}
              >
                Don't Save
              </button>
            </div>
            <div className="">
              <button
                className="save-btn"
                onClick={() => {
                  setShowPresentAdd(null);
                  setPresentConfirm(null);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </AddConfirmAlt>

      <AddConfirmAlt isOpen={officeConfirm !== null}>
        <div className="addAltContainer">
          <div className="addAlt_text">Do you want to save changes?</div>
          <div className="addAltBtn">
            <div>
              <button
                className="dont-save-btn"
                onClick={() => {
                  setShowOfficeAdd(null);
                  setValue("officeName", address?.name);
                  setValue("subjectOfficeAddress", address?.add);
                  setValue("officeAddNo", address?.mNo);
                  setValue("officeAdd1No", address?.aNo);
                  setValue("officeAddEmail", address?.email);
                  setValue("officeAdditionalDetails", address?.addInfo);
                  setOfficeConfirm(null);
                }}
              >
                Don't Save
              </button>
            </div>
            <div className="">
              <button
                className="save-btn"
                onClick={() => {
                  setShowOfficeAdd(null);
                  setOfficeConfirm(null);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </AddConfirmAlt>

      <AddConfirmAlt isOpen={PermanentConfirm !== null}>
        <div className="addAltContainer">
          <div className="addAlt_text">Do you want to save changes?</div>
          <div className="addAltBtn">
            <div>
              <button
                className="dont-save-btn"
                onClick={() => {
                  setShowPermanentAddress(null);
                  setValue("permanentFamilyName", address?.name);
                  setValue("permanentAddress", address?.add);
                  setValue("permanentAddNo", address?.mNo);
                  setValue("permanentAddEmail", address?.email);
                  setValue("permanentAdditionalDetails", address?.addInfo);
                  setPermanentConfirm(null);
                }}
              >
                Don't Save
              </button>
            </div>
            <div className="">
              <button
                className="save-btn"
                onClick={() => {
                  setShowPermanentAddress(null);
                  setPermanentConfirm(null);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </AddConfirmAlt>

      <AddConfirmAlt isOpen={appPresentAdd !== null}>
        <div className="addAltContainer">
          <div className="addAlt_text">Do you want to save changes?</div>
          <div className="addAltBtn">
            <div>
              <button
                className="dont-save-btn"
                onClick={() => {
                  setShowPresentAddAppend(null);
                  setValue(
                    `userForm.${showPresentAddAppend}.temporaryAddress`,
                    address?.add
                  );
                  setAppPresentAdd(null);
                }}
              >
                Don't Save
              </button>
            </div>
            <div className="">
              <button
                className="save-btn"
                onClick={() => {
                  setShowPresentAddAppend(null);
                  setAppPresentAdd(null);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </AddConfirmAlt>

      <AddConfirmAlt isOpen={appOfficeAdd !== null}>
        <div className="addAltContainer">
          <div className="addAlt_text">Do you want to save changes?</div>
          <div className="addAltBtn">
            <div>
              <button
                className="dont-save-btn"
                onClick={() => {
                  setOfficeAppend(null);
                  setValue(
                    `userForm.${showOfficeAppend}.officeName`,
                    address?.name
                  );
                  setValue(
                    `userForm.${showOfficeAppend}.officeAddress`,
                    address?.add
                  );
                  setValue(
                    `userForm.${showOfficeAppend}.officeAddNo`,
                    address?.mNo
                  );
                  setValue(
                    `userForm.${showOfficeAppend}.officeAdd1No`,
                    address?.aNo
                  );
                  setValue(
                    `userForm.${showOfficeAppend}.officeAddEmail`,
                    address?.email
                  );
                  setValue(
                    `userForm.${showOfficeAppend}.officeAdditionalDetails`,
                    address?.addInfo
                  );
                  setAppOfficeAdd(null);
                }}
              >
                Don't Save
              </button>
            </div>
            <div className="">
              <button
                className="save-btn"
                onClick={() => {
                  setOfficeAppend(null);
                  setAppOfficeAdd(null);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </AddConfirmAlt>

      <AddConfirmAlt isOpen={appPermanentAdd !== null}>
        <div className="addAltContainer">
          <div className="addAlt_text">Do you want to save changes?</div>
          <div className="addAltBtn">
            <div>
              <button
                className="dont-save-btn"
                onClick={() => {
                  setShowPermanentAppend(null);
                  setValue(
                    `userForm.${showPermanentAppend}.permanentFamilyName`,
                    address?.name
                  );
                  setValue(
                    `userForm.${showPermanentAppend}.permanentAddress`,
                    address?.add
                  );
                  setValue(
                    `userForm.${showPermanentAppend}.permanentAddNo`,
                    address?.mNo
                  );
                  setValue(
                    `userForm.${showPermanentAppend}.permanentAddEmail`,
                    address?.email
                  );
                  setValue(
                    `userForm.${showPermanentAppend}.permanentAdditionalDetails`,
                    address?.addInfo
                  );
                  setAppPermanentAdd(null);
                }}
              >
                Don't Save
              </button>
            </div>
            <div className="">
              <button
                className="save-btn"
                onClick={() => {
                  setShowPermanentAppend(null);
                  setAppPermanentAdd(null);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </AddConfirmAlt>
    </div>
  );
}
