import React, { useEffect, useState } from "react";
import companyLogo from "../asset/logo.jpg";
import registerImg from "../asset/registerImg.jpg";
import { Controller, useForm } from "react-hook-form";
import InputField from "../component/InputField";
import userIcon from "../asset/User.png";
import emailIcon from "../asset/Email.png";
import passwordIcon from "../asset/Lock.png";
import profile from "../asset/profile.png";
import telephone from "../asset/telephone.png";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useMutation } from "react-query";
import visible from "../asset/visibility.png";
import visibleOff from "../asset/visibility_off.png";
import axios from "axios";
import Modal from "../component/Modal";
import AlertModal from "../component/AlertModal";
import Images from "../asset";

const UserRegister = () => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(true);
  const [isShowConfirm, setIsShowConfirm] = useState(true);
  const [open, setOpen] = useState();
  const [showError, setShowError] = useState();
  const [passwordCheck, setPasswordCheck] = useState(false);
  console.log("state", state);

  useEffect(() => {
    if (state && state) {
      setValue("userName", state?.userName);
      setValue("email", state?.email);
      setValue("mobileNumber", state?.mobileNumber);
      setValue("role", state?.role);
    }
  }, [state, setValue]);

  const { mutate, isLoading, reset } = useMutation(
    (postData) =>
      axios.post(`${process.env.REACT_APP_HOST}/registers`, postData),
    {
      onSuccess: (data) => {
        // navigate("/");
        // reset()
        setOpen("Register Add Successfully");
        // window.location.reload()
      },
      onError: (error) => {
        console.error("Mutation failed:", error?.response?.data);
        setOpen(error?.response?.data);
      },
    }
  );
  const upData = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/registers/${state && state?.id}`,
        postData
      ),
    {
      onSuccess: (data) => {
        // navigate("/");
        // reset()
        // window.location.reload()
        setOpen("Register Update Successfully");
      },
      onError: (error) => {
        console.error("Mutation failed:", error?.response?.data);
        setOpen(error?.response?.data);
      },
    }
  );

  const { password, confirmPassword } = watch();
  const submit = (data) => {
    console.log("data", data);
    const formValue = {
      userName: data?.userName,
      email: data?.email,
      password: data?.password,
      confirmPassword: data?.confirmPassword,
      role: data?.role,
      mobileNumber: data?.mobileNumber,
    };
    if (password === confirmPassword) {
      if (state && state?.id) {
        upData.mutate(formValue);
      } else {
        mutate(formValue);
      }
    } else {
      alert("password not match");
    }
  };

  return (
    <div className="register-form-block">
      <div className="col-12 px-4 pt-4 d-flex align-items-center justify-content-between">
        <div
          style={{ width: "50px", height: "40px", cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            src={Images?.arrowBack}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <button
          className="new-register-btn"
          onClick={() => {
            navigate("/viewRegister");
          }}
        >
          VIEW REGISTER
        </button>
      </div>
      <div className="register-form-container">
        <form
          onSubmit={handleSubmit(submit)}
          className="row d-flex justify-content-center"
        >
          <div
            className="d-flex  
              justify-content-around"
          >
            <div className=" registerInputDivCss d-flex align-items-center">
              <img className="userRegisterInputLogo" src={userIcon} alt="" />
              <Controller
                control={control}
                name="userName"
                render={(field) => (
                  <InputField
                    registerInputCss=" registerInputCss"
                    {...field}
                    type="text"
                    placeholder="USERNAME"
                  />
                )}
              />
            </div>

            {/* <div className=""> */}
            <div className=" registerInputDivCss d-flex align-items-center ">
              <img className="userRegisterInputLogo" src={emailIcon} alt="" />
              <Controller
                control={control}
                name="email"
                render={(field) => (
                  <InputField
                    registerInputCss=" registerInputCss"
                    {...field}
                    type="email"
                    placeholder="E-MAIL"
                  />
                )}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {showError && showError ? (
              <i style={{ color: "red" }}>{showError}</i>
            ) : (
              ""
            )}
          </div>
          {/* </div> */}
          {/* Number & Role Start */}
          <div className=" d-flex p-3 justify-content-around">
            <div className=" registerInputDivCss d-flex align-items-center">
              <img className="userRegisterInputLogo" src={telephone} alt="" />
              <Controller
                control={control}
                name="mobileNumber"
                render={(field) => (
                  <InputField
                    registerInputCss="registerInputCss "
                    {...field}
                    type="number"
                    placeholder="Phone No"
                  />
                )}
                rules={{ required: true }}
              />
            </div>
            <div className=" registerInputDivCss d-flex align-items-center">
              <img className="userRegisterInputLogo" src={profile} alt="" />
              <Controller
                control={control}
                name="role"
                render={({ field: { onChange, value } }) => (
                  <select
                    value={value}
                    onChange={onChange}
                    style={{
                      width: "100%",
                      background: "transparent",
                      border: "none",
                      outline: "none",
                    }}
                    className="mt-2  text-capitalize registerInputDivCss"
                    // customerInputCss="--customerInputCss"
                    // style={error ? { borderColor: "red" } : {}}
                  >
                    <option>None</option>
                    <option value="admin">User</option>

                    <option value="superAdmin">Admin</option>
                  </select>
                )}
                rules={{ required: true }}
              />
            </div>
          </div>
          {/* Number & Role End */}

          <div className=" d-flex p-3 justify-content-around">
            <div className=" registerInputDivCss d-flex align-items-center">
              <img
                className="userRegisterInputLogo"
                src={passwordIcon}
                alt=""
              />
              <Controller
                control={control}
                name="password"
                render={(field) => (
                  <InputField
                    registerInputCss="registerInputCss "
                    {...field}
                    type={isShow ? "password" : "text"}
                    placeholder="PASSWORD"
                  />
                )}
                rules={{ required: true }}
              />
              {!isShow ? (
                <img
                  onClick={() => setIsShow(!isShow)}
                  src={visible}
                  alt="Show"
                  style={{ height: "30px" }}
                />
              ) : (
                <img
                  onClick={() => setIsShow(!isShow)}
                  src={visibleOff}
                  alt="Show"
                  style={{ height: "30px" }}
                />
              )}
            </div>
            <div className=" registerInputDivCss d-flex align-items-center">
              <img
                className="userRegisterInputLogo"
                src={passwordIcon}
                alt=""
              />
              <Controller
                control={control}
                name="confirmPassword"
                render={(field) => (
                  <InputField
                    registerInputCss="registerInputCss "
                    {...field}
                    type={isShowConfirm ? "password" : "text"}
                    placeholder="CONFIRM PASSWORD"
                  />
                )}
                rules={{ required: true }}
              />
              {!isShowConfirm ? (
                <img
                  onClick={() => setIsShowConfirm(!isShowConfirm)}
                  src={visible}
                  alt="Show"
                  style={{ height: "30px" }}
                />
              ) : (
                <img
                  onClick={() => setIsShowConfirm(!isShowConfirm)}
                  src={visibleOff}
                  alt="Show"
                  style={{ height: "30px" }}
                />
              )}
            </div>
          </div>
          {/* {passwordCheck && passwordCheck ? <i>password not match</i>:""} */}
          <div style={{ textAlign: "center" }}>
            {(errors.role ||
              errors.mobileNumber ||
              errors.email ||
              errors.userName ||
              errors.password ||
              errors.confirmPassword) && (
              <p className="mb-0" style={{ color: "red", fontSize: "22px" }}>
                {" "}
                Enter all the field
              </p>
            )}
          </div>
          <div className=" col-3 m-3 userRegisterBtnContainer ">
            <button className="userRegisterBtn" type="submit">
              {isLoading ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Register"
              )}
            </button>
          </div>
        </form>
      </div>

      <AlertModal
        open={open}
        setOpen={setOpen}
        // reset={reset}
        // handleClose={handleClose}
        // setUpdateOpen={setUpdateOpen}
        // UpdateOpen={UpdateOpen}
        navigate={"/viewRegister"}
        // setErrorOpen={setErrorOpen}
        // errorOpen={errorOpen}
      />
    </div>
  );
};

export default UserRegister;
