// import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
// import companyLogo from "../asset/logo.jpg";
// import Images from "../asset";
// export default function Sidebar() {
//   const navigate = useNavigate();
//   const userdata = sessionStorage.getItem("userdata");
//   return (
//     <div>
//      <Sidebar className="d-flex flex-column vh-100  pt-2"
//       style={{ backgroundColor: " #D9D9D9" }}>

//     </Sidebar>
//     <div
//       className="d-flex flex-column vh-100  pt-2"
//       style={{ backgroundColor: " #D9D9D9" }}
//     >
//       <div className="d-flex justify-content-center">
//         <Link
//           to="/"
//           className="nav-link"
//           style={{ zIndex: 9, padding: "5px 10px" }}
//         >
//           <img
//             src={Images?.loginlogoIcon}
//             alt="logo"
//             style={{ width: "100%", height: "100px", borderRadius: "10px" }}
//           />
//         </Link>
//       </div>
//       <ul
//         className="nav nav-pills flex-column mb-auto pt-3 gap-1"
//         style={{ backgroundColor: " #D9D9D9" }}
//       >
//         <li className="nav-item sidebarList">
//           <Link to="/visitorAdd" className="nav-link sidebarListName ">
//             NEW VISITOR
//           </Link>
//         </li>

//         <li className="nav-item sidebarList">
//           <Link to="/" className="nav-link sidebarListName">
//             VIEW VISITOR
//           </Link>
//         </li>
//         <li className="nav-item sidebarList">
//           <Link to="/filterData" className="nav-link sidebarListName">
//             FILTER
//           </Link>
//         </li>
//         {userdata == "superAdmin" ? (
//           <>
//             <li className="nav-item sidebarList">
//               <Link to="/userRegister" className="nav-link sidebarListName">
//                 NEW REGISTER
//               </Link>
//             </li>
//             <li className="nav-item sidebarList">
//               <Link to="/viewRegister" className="nav-link sidebarListName">
//                 VIEW REGISTER
//               </Link>
//             </li>
//           </>
//         ) : (
//           <></>
//         )}
//         <li
//           className="nav-item sidebarList"
//           onClick={() => {
//             sessionStorage.removeItem("login");
//             window.location?.reload();
//             // navigate("/")
//           }}
//         >
//           <Link to="/" className="nav-link sidebarListName">
//             LOG OUT
//           </Link>
//         </li>
//       </ul>
//     </div>
//     </div>
//   );
// }

import Images from "../asset";
import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
export default function SidebarMenu() {
  const navigate = useNavigate();
  const userData = sessionStorage.getItem("userdata");
  const location = useLocation(); // Get current route
  const [activeItem, setActiveItem] = useState(location.pathname);
  return (
    <div>
      <Sidebar style={{ width: "100%",zIndex:0 }}>
        <Menu style={{ backgroundColor: "#D9D9D9", height: "100vh" }}>
          <div className="d-flex justify-content-center py-2">
            <img
              src={Images?.loginlogoIcon}
              alt="logo"
              style={{
                width: "auto",
                height: "auto",
                borderRadius: "10px",
                padding: "0px",
              }}
            />
          </div>

          {/* Visitors Information */}
          <SubMenu
            className="sideBarMenuItem"
            label="Visitors Information"
            defaultOpen
          >
            <MenuItem
              component={<Link to="/visitorEntry" />}
              className={`sideBarMenuItem ${
                activeItem === "/visitorEntry" ? "active-menu" : ""
              }`}
              onClick={() => setActiveItem("/visitorEntry")}
            >
              Visitor Entry
            </MenuItem>
            <MenuItem
              component={<Link to="/" />}
              className={`sideBarMenuItem ${
                activeItem === "/" ? "active-menu" : ""
              }`}
              onClick={() => setActiveItem("/")}
            >
              Visitors
            </MenuItem>
          </SubMenu>

          {/* Clients Information */}
          <SubMenu
            className="sideBarMenuItem"
            label="Clients Information"
            defaultOpen
          >
            <MenuItem
              component={<Link to="/clientEntry" />}
              className={`sideBarMenuItem ${
                activeItem === "/clientEntry" ? "active-menu" : ""
              }`}
              onClick={() => setActiveItem("/clientEntry")}
            >
              Client Entry
            </MenuItem>
            <MenuItem
              component={<Link to="/clientList" />}
              className={`sideBarMenuItem ${
                activeItem === "/clientList" ? "active-menu" : ""
              }`}
              onClick={() => setActiveItem("/clientList")}
            >
              Clients & Subject
            </MenuItem>
          </SubMenu>

          {/* Other Menu Items */}
          {/* {userData === "superAdmin" && ( */}
          <MenuItem
            component={<Link to="/filterData" />}
            className={`sideBarMenuItem ${
              activeItem === "/filterData" ? "active-menu" : ""
            }`}
            onClick={() => setActiveItem("/filterData")}
          >
            Search Box
          </MenuItem>
          {/* )} */}
          {userData === "superAdmin" && (
          <MenuItem
            component={<Link to="/fileUpload" />}
            className={`sideBarMenuItem ${
              activeItem === "/fileUpload" ? "active-menu" : ""
            }`}
            onClick={() => setActiveItem("/fileUpload")}
          >
            File Upload
          </MenuItem>
          )}

          {/* Super Admin Menu */}
          {userData === "superAdmin" && (
            <SubMenu className="sideBarMenuItem" label="User Management">
              <MenuItem
                component={<Link to="/viewRegister" />}
                className={`sideBarMenuItem ${
                  activeItem === "/viewRegister" ? "active-menu" : ""
                }`}
                onClick={() => setActiveItem("/viewRegister")}
              >
                Existing User
              </MenuItem>
              <MenuItem
                component={<Link to="/userRegister" />}
                className={`sideBarMenuItem ${
                  activeItem === "/userRegister" ? "active-menu" : ""
                }`}
                onClick={() => setActiveItem("/userRegister")}
              >
                Add New User
              </MenuItem>
            </SubMenu>
          )}

          <MenuItem
            onClick={() => {
              sessionStorage.removeItem("login");
              sessionStorage.removeItem("userdata");
              navigate("/");
              window.location.reload();
            }}
            className="fw-bold sidebarListName"
          >
            Logout
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(-1)
            }}
            // style={{backgroundColor:"#3c30bb",color:"white"}}
            className="fw-bold sideBarMenuItem text-end"
          >

          Back
          </MenuItem>

        </Menu>
      </Sidebar>
    </div>
  );
}
