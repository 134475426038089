import React from 'react'
import ClientInformationCom from '../component/ClientInformationCom'

export default function ClientInformation() {
  return (
    <div>
      <ClientInformationCom/>
    </div>
  )
}
