import React, { useCallback, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import InputField from "../component/InputField";
import add from "../asset/Add.png";
import minus from "../asset/minus.png";
import PinInput from "react-pin-input";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { utc } from "moment";
import Alert from "../component/Alert";
import Modal from "../component/Modal";
import { useLocation, useNavigate } from "react-router";
import { CitySelect, StateSelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { stateDistricts } from "../component/State";
// import { format } from "@react-input/number-format";
// import { format, utcToZonedTime } from 'date-fns-tz';

export default function VisitorAddScreen() {
  const { state } = useLocation();
  console.log("state", state);

  const navigate = useNavigate();
  const [isCitySelect, setIsCitySelect] = useState();

  const today = new Date();
  const [isModalOpen, setModalOpen] = useState(false);

  const [showClientInformation, setShowClientInformation] = useState(false);
  const [postAlert, setPostAlert] = useState();
  const [putAlert, setPutAlert] = useState();
  const [alsoWhatApp, setAlsoWhatApp] = useState();
  const [alsoPermanent, setAlsoPermanent] = useState();
  const [mobileNumberData, setMobileNumberData] = useState();
  const [filteredUser, setFilteredUser] = useState(null);
  const [newNumber, setNewNumber] = useState([]);
  const [alsoClient, setAlsoClient] = useState();
  console.log("showClientInformation", showClientInformation);

  // console.log("mobileNumberData", mobileNumberData);
  const { control, handleSubmit, setValue, watch, register, getValues } =
    useForm({
      defaultValues: {
        userForm: [
          {
            visitorName: "",
            visitorFileNumber: "",
            visitorMobileNumber: "",
            whatAppNumber: "",
            alternativeNumber: "",
            visitorCategory: "",
            visitorSourceForm: "",
            visitorAge: "",
            visitorPlaceOfResidence: "",
            visitorAddress: "",
            visitorDistrict: "",
            visitorState: "",
            visitorPinCode: "",
            date: "",
            visitorInTime: "",
            visitorOutTime: "",
            visitorAdditionalStatus: "",
            visitorStatus: "",
            dateOfAppoinment: moment(),
            dateOfVisit: "",
            email: "",
            whatsapp: "",
            caste: "",
            relegion: "",
            otherReligion: "",
            otherSourceForm: "",
            otherCategory: "",
          },
        ],
      },
    });
  const {
    fields: fieldsVisitor,
    append: appendVisitor,
    remove: removeVisitor,
  } = useFieldArray({
    name: "userForm",
    control,
  });
  const {
    fields: fieldsSubject,
    append: appendSubject,
    remove: removeSubject,
  } = useFieldArray({
    name: "subjectDetails",
    control,
  });

  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    if (state && state) {
      removeVisitor();
      removeSubject();
      console.log("state?.userForm", state?.userForm);

      setValue("subjectClientName", state?.subjectDetails?.clientName);
      setValue("subject", state?.subjectDetails?.subject);
      setValue("subjectCaseNumber", state?.subjectDetails?.caseNumber);
      setValue("subjectFileNumber", state?.subjectDetails?.fileNumber);
      setValue("subjectMobileNumber", state?.subjectDetails?.mobileNumber);
      setValue(
        "subjectMovableProperties",
        state?.subjectDetails?.movableProperties
      );
      setValue("subjectCaste", state?.subjectDetails?.caste);
      setValue("subjectReligion", state?.subjectDetails?.religion);
      setValue("subjectEducation", state?.subjectDetails?.education);
      setValue("subjectEmployment", state?.subjectDetails?.employment);
      setValue("SubjectAddress", state?.subjectDetails?.address);
      setValue("subjectNatureOfClose", state?.subjectDetails?.natureOfClosed);

      setValue(
        "subjectDateRegards",
        state?.subjectDetails?.dateOfRegards !== null
          ? moment(state?.subjectDetails?.dateOfRegards, "DD-MM-YYYY").format(
              "MM/DD/YYYY"
            )
          : ""
      );
      setValue(
        "subjectDateClose",
        state?.subjectDetails?.dateOfClosed !== null
          ? moment(state?.subjectDetails?.dateOfClosed, "DD-MM-YYYY").format(
              "MM/DD/YYYY"
            )
          : ""
      );
      // clientInformation
      setValue("clientName", state?.clientInformation?.clientName);
      setValue("clientAge", state?.clientInformation?.age);
      // setValue("clientMobileNumber", state?.clientInformation?.mobileNumber);
      // setValue(
      //   "clientAltNumber",
      //   state?.clientInformation?.alternativeMobileNumber
      // );
      setValue("clientEmailId", state?.clientInformation?.email);
      setValue("clientAddress", state?.clientInformation?.address);
      setValue("clientFatherName", state?.clientInformation?.fatherName);
      setValue("clientMarried", state?.clientInformation?.marrielStatus);
      setValue("clientGuardian", state?.clientInformation?.guardianOrParent);
      // case details
      setValue("caseDetailsName", state?.caseDetails?.caseNumber);
      setValue(
        "caseDetailsNatureOfCaseTitle",
        state?.caseDetails?.natureOfCareTitle
      );
      setValue(
        "natureOfCaseClientRequirement",
        state?.caseDetails?.natureOfCareClientReq
      );
      setValue(
        "caseDetailsDate",
        state?.caseDetails?.date !== null
          ? moment(state?.caseDetails?.date, "DD-MM-YYYY").format("MM/DD/YYYY")
          : ""
      );
      setValue("natureOfCareDetails", state?.caseDetails?.natureOfCareDetails);
      setValue("caseDetailsTotalAmount", state?.caseDetails?.totalAmount);
      // setValue("caseDetailsAmountReceived", state?.caseDetails?.amountReceived);
      // setValue("caseDetailsBalancePending", state?.caseDetails?.balancePending);
      setValue("caseDetailsStatus", state?.caseDetails?.status);
      setValue("caseDetailsPaymentType", state?.caseDetails?.paymentType);
      // referenceDetails
      console.log(
        "state?.caseDetails?.amountDate",
        state?.caseDetails?.amountDate[0] !== null
      );

      setValue("reference", state?.referenceDetails?.reference);
      setValue("referenceNameOfLos", state?.referenceDetails?.nameOfLos);
      setValue("caseDetailsAmountDate", new Date());

      setValue(
        "referenceData",
        state?.referenceDetails?.date !== null
          ? // ? state?.referenceDetails?.date
            moment(state?.referenceDetails?.date, "DD-MM-YYYY").format(
              "MM/DD/YYYY"
            )
          : ""
      );
      setValue("referenceFileNumber", state?.referenceDetails?.fileNumber);
      setValue(
        "referenceReasonForClosure",
        state?.referenceDetails?.reasonForClosure
      );
      setValue("referenceConfReport", state?.referenceDetails?.confReport);

      if (state?.visitorInfoList) {
        appendVisitor();
        state?.visitorInfoList &&
          state?.visitorInfoList?.map((formItem, index) => {
            console.log(
              "Number(formItem && formItem?.state)",
              Number(formItem && formItem?.state)
            );

            setValue(`userForm.${index}.name`, formItem && formItem?.name);
            setValue(
              `userForm.${index}.fileNumber`,
              formItem && formItem?.fileNumber
            );
            setValue(
              `userForm.${index}.mobileNumber`,
              formItem && formItem?.mobileNumber
            );
            setValue(
              `userForm.${index}.alternativeNumber`,
              formItem && formItem?.alternativeNumber
            );
            setValue(
              `userForm.${index}.whatsapp`,
              formItem && formItem?.whatsapp
            );
            setValue(`userForm.${index}.email`, formItem && formItem?.email);
            setValue(`userForm.${index}.caste`, formItem && formItem?.caste);
            setValue(
              `userForm.${index}.relegion`,
              formItem && formItem?.relegion
            );
            setValue(
              `userForm.${index}.otherReligion`,
              (formItem && formItem?.otherReligion) || ""
            );
            setValue(
              `userForm.${index}.temporaryAddress`,
              formItem && formItem?.temporaryAddress
            );
            setValue(
              `userForm.${index}.permanentAddress`,
              formItem && formItem?.permanentAddress
            );
            setValue(
              `userForm.${index}.officeAddress`,
              formItem && formItem?.officeAddress
            );
            setValue(
              `userForm.${index}.dateOfVisit`,
              formItem && formItem?.dateOfVisit !== null
                ? moment(
                    formItem && formItem?.dateOfVisit,
                    "DD-MM-YYYY"
                  ).format("MM/DD/YYYY")
                : ""
            );
            setValue(
              `userForm.${index}.dateOfAppoinment`,
              formItem && formItem?.dateOfAppoinment !== null
                ? moment(
                    formItem && formItem?.dateOfAppoinment,
                    "DD-MM-YYYY"
                  ).format("MM/DD/YYYY")
                : ""
            );
            setValue(
              `userForm.${index}.category`,
              formItem && formItem?.category
            );
            setValue(
              `userForm.${index}.otherCategory`,
              formItem && formItem?.otherCategory
            );
            setValue(
              `userForm.${index}.sourceForm`,
              formItem && formItem?.sourceForm
            );
            setValue(
              `userForm.${index}.otherSourceForm`,
              formItem && formItem?.otherSourceForm
            );
            setValue(`userForm.${index}.age`, formItem && formItem?.age);
            setValue(
              `userForm.${index}.placeOfResidence`,
              formItem && formItem?.placeOfResidence
            );
            setValue(
              `userForm.${index}.address`,
              formItem && formItem?.address
            );
            setValue(
              `userForm.${index}.district`,
              Number(formItem && formItem?.district)
            );
            setIsCitySelect(Number(formItem && formItem?.state));
            setValue(`userForm.${index}.state`, formItem && formItem?.state);
            // setIsCitySelect(Number(formItem && formItem?.state));
            // setValue(`userForm.${index}.state`, "Tamil Nadu");
            setValue(
              `userForm.${index}.pincode`,
              formItem && formItem?.pincode
            );
            // setValue(
            //   `userForm.${index}.date`,
            //   formItem && formItem?.date
            //   // moment(formItem?.date).format(" dd/MM/yyyy")
            // );
            // setValue(
            //   `userForm.${index}.date`,
            //   moment(formItem && formItem?.date, "DD-MM-YYYY").format(
            //     "MM/DD/YYYY"
            //   )
            // );
            setValue(
              `userForm.${index}.additionalStatus`,
              formItem && formItem?.additionalStatus
            );
            setValue(`userForm.${index}.status`, formItem && formItem?.status);
            // setValue(`userForm.${index}.inTime`, formItem && formItem?.inTime);
            // setValue(
            //   `userForm.${index}.outTime`,
            //   formItem && formItem?.outTime
            // );
          });
      }
      if (state?.subjectDetailstwo) {
        // setShowClientInformation(true);
        appendSubject();
        // removeSubject();
        state?.subjectDetailstwo &&
          state?.subjectDetailstwo?.map((formItem, index) => {
            console.log("formItem", formItem);
            setValue(
              `subjectDetails.${index}.subjectAppClientName`,
              formItem?.subjectAppClientName
            );
            setValue(
              `subjectDetails.${index}.subjectApp`,
              formItem?.subjectApp
            );
            setValue(
              `subjectDetails.${index}.subjectAppAddress`,
              formItem?.subjectAppAddress
            );
            setValue(
              `subjectDetails.${index}.subjectAppMobileNumber`,
              formItem?.subjectAppMobileNumber
            );
          });
      }
    }
  }, [state, setValue, appendVisitor, removeVisitor]);

  const { mutate, isLoading } = useMutation(
    (postData) =>
      axios.post(
        "https://api.2kvirtualworld.com/DADEV/visitors/save",
        postData
      ),
    {
      onSuccess: (data) => {
        // setPostAlert(true);
        navigate("/");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const upData = useMutation(
    (postData) =>
      axios.put(
        `https://api.2kvirtualworld.com/DADEV/visitors/update/${state?.id}`,
        postData
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: () => {
        // setPutAlert(true);
        navigate("/");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  useEffect(() => {
    if (
      state?.clientInformation?.clientName !== undefined &&
      state?.clientInformation?.clientName !== null
    ) {
      console.log("clientInformation", state?.clientInformation?.clientName);

      setShowClientInformation(true);
    }
  }, [state]);

  const { userForm, subjectDetails } = watch();
  console.log("userForm", userForm);

  // console.log("userForm->", userForm[0]?.alternativeNumber);

  // open modal
  const handleFocus = () => setModalOpen(true);

  // Close modal
  const handleCloseModal = () => setModalOpen(false);

  // Save the input value from modal to form
  const handleSave = (value) => {
    setValue("natureOfCareDetails", value);
    setModalOpen(false);
  };

  // console.log("filteredUser", filteredUser);

  const fetchingData = async () => {
    return await axios.get(`https://api.2kvirtualworld.com/DADEV/visitors/all`);
  };
  // Fetch visitor data using React Query
  const { data: visitorDetails, refetch } = useQuery(
    "fetchingData",
    fetchingData,
    {
      onSuccess: (data) => {
        // console.log('Visitor data fetched:', data?.data);
      },
    }
  );
  // let fetchingData

  useEffect(() => {
    if (mobileNumberData !== undefined || null) {
      const matchingVisitorInfoList =
        visitorDetails?.data &&
        visitorDetails?.data?.find(
          (items) =>
            items?.visitorInfoList &&
            items?.visitorInfoList?.some(
              (visitor) =>
                Number(visitor?.mobileNumber) === Number(mobileNumberData)
            )
        );
      console.log("matchingVisitorInfoList", matchingVisitorInfoList);
      if (matchingVisitorInfoList && matchingVisitorInfoList?.length !== 0) {
        matchingVisitorInfoList &&
          Array(matchingVisitorInfoList)?.forEach((item) => {
            console.log("arrayValue", item?.visitorInfoList);
            if (item?.visitorInfoList && item?.visitorInfoList !== 0) {
              appendVisitor();
              removeVisitor();
              item?.visitorInfoList &&
                item?.visitorInfoList?.map((value, index) => {
                  console.log("value", value);

                  setValue(`userForm.${index}.name`, value && value?.name);
                  setValue(
                    `userForm.${index}.fileNumber`,
                    value && value?.fileNumber
                  );
                  setValue(
                    `userForm.${index}.mobileNumber`,
                    value && value?.mobileNumber
                  );
                  setValue(
                    `userForm.${index}.alternativeNumber`,
                    value && value?.alternativeNumber
                  );
                  setValue(
                    `userForm.${index}.whatsapp`,
                    value && value?.whatsapp
                  );
                  setValue(`userForm.${index}.email`, value && value?.email);
                  setValue(`userForm.${index}.caste`, value && value?.caste);
                  setValue(
                    `userForm.${index}.relegion`,
                    value && value?.relegion
                  );
                  setValue(
                    `userForm.${index}.temporaryAddress`,
                    value && value?.temporaryAddress
                  );
                  setValue(
                    `userForm.${index}.permanentAddress`,
                    value && value?.permanentAddress
                  );
                  setValue(
                    `userForm.${index}.officeAddress`,
                    value && value?.officeAddress
                  );
                  setValue(
                    `userForm.${index}.dateOfVisit`,
                    value && value?.dateOfVisit !== null
                      ? moment(
                          value && value?.dateOfVisit,
                          "DD-MM-YYYY"
                        ).format("MM/DD/YYYY")
                      : ""
                  );
                  setValue(
                    `userForm.${index}.dateOfAppoinment`,
                    value && value?.dateOfAppoinment !== null
                      ? moment(
                          value && value?.dateOfAppoinment,
                          "DD-MM-YYYY"
                        ).format("MM/DD/YYYY")
                      : ""
                  );
                  setValue(
                    `userForm.${index}.category`,
                    value && value?.category
                  );
                  setValue(
                    `userForm.${index}.sourceForm`,
                    value && value?.sourceForm
                  );
                  setValue(`userForm.${index}.age`, value && value?.age);
                  setValue(
                    `userForm.${index}.placeOfResidence`,
                    value && value?.placeOfResidence
                  );
                  setValue(
                    `userForm.${index}.address`,
                    value && value?.address
                  );
                  setValue(
                    `userForm.${index}.district`,
                    value && value?.district
                  );
                  setValue(`userForm.${index}.state`, value && value?.state);
                  setValue(
                    `userForm.${index}.pincode`,
                    value && value?.pincode
                  );
                  setValue(
                    `userForm.${index}.additionalStatus`,
                    value && value?.additionalStatus
                  );
                  setValue(`userForm.${index}.status`, value && value?.status);
                  setValue(`userForm.${index}.inTime`, value && value?.inTime);
                  setValue(
                    `userForm.${index}.outTime`,
                    value && value?.outTime
                  );
                });
            }

            // const existingData = watch(`userForm.${index}`);
          });
      }

      if (
        matchingVisitorInfoList?.referenceDetails &&
        matchingVisitorInfoList?.referenceDetails
      ) {
        setValue(
          "reference",
          matchingVisitorInfoList?.referenceDetails?.reference || null
        );
        setValue(
          "referenceConfReport",
          matchingVisitorInfoList?.referenceDetails?.confReport || null
        );
        setValue(
          "referenceReasonForClosure",
          matchingVisitorInfoList?.referenceDetails?.reasonForClosure || null
        );
        setValue(
          "referenceFileNumber",
          matchingVisitorInfoList?.referenceDetails?.fileNumber || null
        );
        setValue(
          "referenceNameOfLos",
          matchingVisitorInfoList?.referenceDetails?.nameOfLos || null
        );
        setValue(
          "referenceData",
          matchingVisitorInfoList?.referenceDetails?.date !== null
            ? moment(
                matchingVisitorInfoList?.referenceDetails?.date,
                "DD-MM-YYYY"
              ).format("MM/DD/YYYY")
            : ""
        );
      }
      if (
        matchingVisitorInfoList?.caseDetails &&
        matchingVisitorInfoList?.caseDetails
      ) {
        setValue(
          "caseDetailsName",
          matchingVisitorInfoList?.caseDetails?.caseNumber || null
        );
        setValue(
          "natureOfCaseClientRequirement",
          matchingVisitorInfoList?.caseDetails?.natureOfCareClientReq || null
        );
        setValue(
          "caseDetailsNatureOfCaseTitle",
          matchingVisitorInfoList?.caseDetails?.natureOfCareTitle || null
        );
        setValue(
          "caseDetailsDate",
          matchingVisitorInfoList?.caseDetails?.date !== null
            ? moment(
                matchingVisitorInfoList?.caseDetails?.date,
                "DD-MM-YYYY"
              ).format("MM/DD/YYYY")
            : ""
        );
        setValue(
          "natureOfCareDetails",
          matchingVisitorInfoList?.caseDetails?.natureOfCareDetails || null
        );
        setValue(
          "caseDetailsTotalAmount",
          matchingVisitorInfoList?.caseDetails?.totalAmount || null
        );
        setValue(
          "caseDetailsStatus",
          matchingVisitorInfoList?.caseDetails?.status || null
        );
        setValue(
          "caseDetailsPaymentType",
          matchingVisitorInfoList?.caseDetails?.paymentType || null
        );
        setValue(
          "caseDetailsAmountReceived",
          matchingVisitorInfoList?.caseDetails?.amountReceived || null
        );
        setValue(
          "caseDetailsBalancePending",
          matchingVisitorInfoList?.caseDetails?.balancePending || null
        );

        setValue(
          "caseDetailsAmountDate",
          matchingVisitorInfoList?.caseDetails?.amountDate[0] !== null
            ? moment(
                matchingVisitorInfoList?.caseDetails?.amountDate
                // "YYYY-MM-DDTHH:mm:ssZ"
              ).format("MM/DD/YYYY")
            : ""
        );
      }
      if (
        matchingVisitorInfoList?.subjectDetails &&
        matchingVisitorInfoList?.subjectDetails
      ) {
        setValue(
          "subjectClientName",
          matchingVisitorInfoList?.subjectDetails?.clientName || null
        );
        setValue(
          "subject",
          matchingVisitorInfoList?.subjectDetails?.subject || null
        );
        setValue(
          "subjectCaseNumber",
          matchingVisitorInfoList?.subjectDetails?.caseNumber || null
        );
        setValue(
          "subjectFileNumber",
          matchingVisitorInfoList?.subjectDetails?.fileNumber || null
        );
        setValue(
          "subjectMobileNumber",
          matchingVisitorInfoList?.subjectDetails?.mobileNumber || null
        );
        setValue(
          "subjectMovableProperties",
          matchingVisitorInfoList?.subjectDetails?.movableProperties || null
        );
        setValue(
          "subjectCaste",
          matchingVisitorInfoList?.subjectDetails?.caste || null
        );
        setValue(
          "subjectReligion",
          matchingVisitorInfoList?.subjectDetails?.religion || null
        );
        setValue(
          "subjectEducation",
          matchingVisitorInfoList?.subjectDetails?.education || null
        );
        setValue(
          "subjectEmployment",
          matchingVisitorInfoList?.subjectDetails?.employment || null
        );
        setValue(
          "subjectNatureOfClose",
          matchingVisitorInfoList?.subjectDetails?.natureOfClosed || null
        );

        setValue(
          "SubjectAddress",
          matchingVisitorInfoList?.subjectDetails?.address || null
        );
        setValue(
          "subjectDateRegards",
          matchingVisitorInfoList?.subjectDetails?.dateOfRegards !== null
            ? moment(
                matchingVisitorInfoList?.subjectDetails?.dateOfRegards,
                "DD-MM-YYYY"
              ).format("MM/DD/YYYY")
            : ""
        );
        setValue(
          "subjectDateClose",
          matchingVisitorInfoList?.subjectDetails?.dateOfClosed !== null
            ? moment(
                matchingVisitorInfoList?.subjectDetails?.dateOfClosed,
                "DD-MM-YYYY"
              ).format("MM/DD/YYYY")
            : ""
        );
      }
      if (
        matchingVisitorInfoList?.subjectDetailstwo &&
        matchingVisitorInfoList?.subjectDetailstwo
      ) {
        appendSubject();
        removeSubject();
        matchingVisitorInfoList?.subjectDetailstwo?.forEach((item, index) => {
          setValue(
            `subjectDetails.${index}.subjectAppClientName`,
            (item && item?.subjectAppClientName) || null
          );
          setValue(
            `subjectDetails.${index}.subjectApp`,
            item && item?.subjectApp
          );
          setValue(
            `subjectDetails.${index}.subjectAppAddress`,
            item && item?.subjectAppAddress
          );
          setValue(
            `subjectDetails.${index}.subjectAppMobileNumber`,
            item && item?.subjectAppMobileNumber
          );
        });
      }
      if (
        matchingVisitorInfoList?.clientInformation &&
        matchingVisitorInfoList?.clientInformation
      ) {
        setValue(
          "clientName",
          matchingVisitorInfoList?.clientInformation?.clientName || null
        );
        setValue(
          "clientAge",
          matchingVisitorInfoList?.clientInformation?.age || null
        );
        setValue(
          "clientMobileNumber",
          matchingVisitorInfoList?.clientInformation?.mobileNumber || null
        );
        setValue(
          "clientAltNumber",
          matchingVisitorInfoList?.clientInformation?.alternativeMobileNumber ||
            null
        );
        setValue(
          "clientEmailId",
          matchingVisitorInfoList?.clientInformation?.email || null
        );
        setValue(
          "clientAddress",
          matchingVisitorInfoList?.clientInformation?.address || null
        );
        setValue(
          "clientFatherName",
          matchingVisitorInfoList?.clientInformation?.fatherName || null
        );
        setValue(
          "clientMarried",
          matchingVisitorInfoList?.clientInformation?.marrielStatus || null
        );
        setValue(
          "clientGuardian",
          matchingVisitorInfoList?.clientInformation?.guardianOrParent || null
        );
      }
    }
  }, [mobileNumberData]);

  useEffect(() => {
    if (!visitorDetails?.data) {
      refetch(); // Refetch the visitor data if it's not yet loaded
    }
  }, [visitorDetails, refetch]);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    // If checked, set the clientName value, otherwise reset it to undefined or ''
    if (checked) {
      setValue("clientName", userForm[0]?.name);
      setValue("clientAge", userForm[0]?.age);
      setValue("clientMobileNumber", userForm[0]?.mobileNumber);
      setValue("clientAltNumber", userForm[0]?.alternativeNumber);
      setValue("clientEmailId", userForm[0]?.email);
      setValue("clientAddress", userForm[0]?.permanentAddress);
      setAlsoClient(true);
    } else {
      setValue("clientName", ""); // You can set it to an empty string or null based on your needs
      setValue("clientAge", "");
      setValue("clientMobileNumber", "");
      setValue("clientAltNumber", "");
      setValue("clientEmailId", "");
      setValue("clientAddress", "");
      setAlsoClient(false);
    }
  };

  const addressCheckBox = (e, index) => {
    const checked = e.target.checked;
    if (checked) {
      userForm?.forEach((user, idx) => {
        setValue(`userForm.${index}.permanentAddress`, user.temporaryAddress);
      });
      setAlsoPermanent(true);
    } else {
      userForm?.forEach((user, idx) => {
        setValue(`userForm.${index}.permanentAddress`, " ");
      });
      setAlsoPermanent(false);
    }
  };
  const mobileNumberCheckBox = (e, index) => {
    const checked = e.target.checked;
    if (checked) {
      userForm?.forEach((user, idx) => {
        setValue(`userForm.${index}.whatsapp`, user.mobileNumber);
      });
      setAlsoWhatApp(true);
    } else {
      userForm?.forEach((user, idx) => {
        setValue(`userForm.${index}.whatsapp`, " ");
      });
      setAlsoWhatApp(false);
    }
  };

  const onSubmit = (data) => {
    console.log(
      "KDSDSD",
      state?.caseDetails?.amountReceived,
      data?.caseDetailsAmountReceived,
      data
    );
    const formData = {
      visitorInfoList: userForm?.map((data, index) => {
        return {
          name: data?.name,
          fileNumber: data?.fileNumber,
          mobileNumber: data?.mobileNumber,
          alternativeNumber: data?.alternativeNumber,
          whatsapp: data?.whatsapp,
          email: data?.email,
          caste: data?.caste,
          relegion: data?.relegion,
          otherReligion: data?.otherReligion,

          category: data?.category,
          otherCategory: data?.otherCategory,
          sourceForm: data?.sourceForm,
          otherSourceForm: data?.otherSourceForm,
          age: data?.age,
          placeOfResidence: data?.placeOfResidence,
          district: String(data?.district),
          state: String(data?.state),
          pincode: data?.pincode,
          date: moment(today).format("DD/MM/YYYY"),
          temporaryAddress: data?.temporaryAddress,
          permanentAddress: data?.permanentAddress,
          officeAddress: data?.officeAddress,
          dateOfVisit: data?.dateOfVisit
            ? moment(data.dateOfVisit).format("DD/MM/YYYY")
            : undefined,
          dateOfAppoinment: data?.dateOfAppoinment
            ? moment(data.dateOfAppoinment).format("DD/MM/YYYY")
            : undefined,
          additionalStatus: data?.additionalStatus,
          status: data?.status,
          inTime: data?.inTime,
          outTime: data?.outTime,
        };
      }),
      subjectDetails: {
        clientName: data?.subjectClientName,
        subject: data?.subject,
        caseNumber: data?.subjectCaseNumber,
        fileNumber: data?.subjectFileNumber,
        mobileNumber: data?.subjectMobileNumber,
        movableProperties: data?.subjectMovableProperties,
        caste: data?.subjectCaste,
        religion: data?.subjectReligion,
        education: data?.subjectEducation,
        employment: data?.subjectEmployment,
        address: data?.SubjectAddress,
        natureOfClosed: data?.subjectNatureOfClose,
        // dateOfRegards: data?.subjectDateRegards,
        dateOfRegards: data?.subjectDateRegards
          ? moment(data.subjectDateRegards).format("DD/MM/YYYY")
          : undefined,
        //  moment(data?.subjectDateRegards).format("DD/MM/YYYY"),
        //  data?.subjectDateRegards,
        //
        dateOfClosed: data?.subjectDateClose
          ? moment(data.subjectDateClose).format("DD/MM/YYYY")
          : undefined,
        //  moment(data?.subjectDateClose).format("DD/MM/YYYY"),

        // data?.subjectDateClose,
      },
      subjectDetailstwo: subjectDetails,
      clientInformation: {
        clientName: data?.clientName,
        age: data?.clientAge,
        mobileNumber: data?.clientMobileNumber,
        alternativeMobileNumber: data?.clientAltNumber,
        email: data?.clientEmailId,
        address: data?.clientAddress,
        fatherName: data?.clientFatherName,
        marrielStatus: data?.clientMarried,
        guardianOrParent: data?.clientGuardian,
      },
      caseDetails: {
        caseNumber: data?.caseDetailsName,
        natureOfCareTitle: data?.caseDetailsNatureOfCaseTitle,
        natureOfCareClientReq: data?.natureOfCaseClientRequirement,
        date: data?.caseDetailsDate
          ? moment(data.caseDetailsDate).format("DD/MM/YYYY")
          : undefined,
        natureOfCareDetails: data?.natureOfCareDetails,
        totalAmount: data?.caseDetailsTotalAmount,
        amountReceived:
          state?.caseDetails?.amountReceived == undefined
            ? [data?.caseDetailsAmountReceived]
            : state?.caseDetails?.amountReceived &&
              state?.caseDetails?.amountReceived.concat(
                Number(data?.caseDetailsAmountReceived)
              ),
        balancePending:
          state?.caseDetails?.balancePending == undefined
            ? [data?.caseDetailsBalancePending]
            : state?.caseDetails?.balancePending &&
              state?.caseDetails?.balancePending.concat(
                Number(data?.caseDetailsBalancePending)
              ),
        status:
          state?.caseDetails?.status == undefined
            ? [data?.caseDetailsStatus]
            : state?.caseDetails?.status &&
              state?.caseDetails?.status.concat(data?.caseDetailsStatus),
        amountDate:
          state?.caseDetails?.amountDate == undefined
            ? [data?.caseDetailsAmountDate]
            : state?.caseDetails?.amountDate &&
              state?.caseDetails?.amountDate.concat(
                data?.caseDetailsAmountDate
              ),
        paymentType:
          state?.caseDetails?.paymentType == undefined
            ? [data?.caseDetailsPaymentType]
            : state?.caseDetails?.paymentType &&
              state?.caseDetails?.paymentType.concat(
                data?.caseDetailsPaymentType
              ),
      },
      referenceDetails: {
        reference: data?.reference,
        nameOfLos: data?.referenceNameOfLos,
        date: data?.referenceData
          ? moment(data?.referenceData).format("DD/MM/YYYY")
          : undefined,
        fileNumber: data?.referenceFileNumber,
        reasonForClosure: data?.referenceReasonForClosure,
        confReport: data?.referenceConfReport,
      },
    };
    // setPostAlert(formData);
    console.log(
      "formData",
      data,
      "formData",
      formData,
      "state?.caseDetails?.amountReceived ",
      state?.caseDetails?.amountReceived
    );
    setPostAlert(formData);
  };

  const {
    caseDetailsTotalAmount,
    caseDetailsAmountReceived,
    caseDetailsBalancePending,
  } = watch();

  const Amount = Number(caseDetailsTotalAmount) || 0;
  const balanceAmt = Number(caseDetailsAmountReceived) || 0;
  const calclulateAmt = Amount - balanceAmt;

  const addAmt =
    Number(state?.caseDetails?.balancePending) +
    Number(caseDetailsAmountReceived);
  useEffect(() => {
    if (state) {
      if (balanceAmt === 0) {
        setValue("caseDetailsBalancePending", " ");
        setValue("caseDetailsStatus", "pending");
      } else if (
        Amount ==
        Number(state?.caseDetails?.amountReceived.reduce((a, b) => a + b)) +
          Number(balanceAmt)
      ) {
        setValue("caseDetailsBalancePending", 0);
        setValue("caseDetailsStatus", "completed");
      } else if (
        Amount -
          Number(state?.caseDetails?.amountReceived.reduce((a, b) => a + b)) +
          Number(balanceAmt) !==
        0
      ) {
        // alert("AAASSA")
        console.log(
          "LLLLLL",
          state?.caseDetails?.amountReceived.reduce((a, b) => a + b)
        );

        let Sum =
          Number(state?.caseDetails?.amountReceived.reduce((a, b) => a + b)) +
          Number(balanceAmt);
        setValue("caseDetailsBalancePending", Number(Amount) - Sum);
        setValue("caseDetailsStatus", "pending");
      }
    } else {
      if (Amount == Number(balanceAmt)) {
        setValue("caseDetailsBalancePending", 0);
        setValue("caseDetailsStatus", "completed");
      } else if (Number(balanceAmt) == 0) {
        // alert("AAASSA")
        // let Sum = Number(state?.caseDetails?.amountReceived) + Number(balanceAmt)
        setValue("caseDetailsBalancePending", Number(balanceAmt));
        setValue("caseDetailsStatus", "pending");
      } else if (Number(Amount) - Number(balanceAmt) !== 0) {
        setValue(
          "caseDetailsBalancePending",
          Number(Amount) - Number(balanceAmt)
        );
        setValue("caseDetailsStatus", "pending");
      }
    }
  }, [state, balanceAmt, Amount, calclulateAmt, caseDetailsBalancePending]);

  // console.log("isCitySelect", isCitySelect);
  const [districts, setDistricts] = useState([]);
  const allDistricts = Object.values(stateDistricts).flat();
  useEffect(() => {
    userForm.map((ele, i) => {
      console.log("LLDDDF", ele);

      setDistricts(stateDistricts[ele?.state] || []);
    });
  }, [userForm]);
  console.log("districts", districts);

  return (
    <>
      <div className="visitorAdd_mainContainer d-flex justify-content-center">
        <div
          className=" py-4"
          style={{
            height: "100%",
            width: "100%",
            overflowY: "scroll",

            // scrollbarX: "none",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-2">
              {fieldsVisitor?.map((field, index) => {
                return (
                  <div key={field.id}>
                    <header className="visitorAddHeader">
                      VISITOR INFORMATION {index + 1}
                    </header>
                    <div className=" pt-5">
                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <label>Name</label>
                          <Controller
                            control={control}
                            name={`userForm.${index}.name`}
                            render={(field) => (
                              <InputField
                                autofocus="autofocus"
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div className="col-5 d-flex flex-column">
                          <label>File Number</label>
                          <Controller
                            control={control}
                            name={`userForm.${index}.fileNumber`}
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="number"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <label>Mobile Number</label>

                          <Controller
                            control={control}
                            name={`userForm.${index}.mobileNumber`}
                            rules={{
                              minLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits",
                              },
                              maxLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits",
                              },
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message:
                                  "Please enter a valid 10-digit mobile number",
                              },
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <>
                                <PinInput
                                  length={10}
                                  initialValue={value || ""}
                                  onChange={(val) => {
                                    onChange(val);

                                    if (alsoWhatApp && val?.length === 10) {
                                      setValue(
                                        `userForm.${index}.whatsapp`,
                                        val
                                      );
                                    }
                                  }}
                                  type="numeric"
                                  style={{ width: "100%", borderRadius: "5px" }}
                                  inputStyle={{
                                    border: "1px solid gray",
                                    backgroundColor: "#fff",
                                    height: "50px",
                                    width: "10%",
                                    margin: "0px",
                                    borderRadius: "5px",
                                  }}
                                  autoSelect
                                  onComplete={(value, index) => {
                                    console.log("onComplete", value);
                                    setMobileNumberData(value);
                                  }}
                                />
                                {error && (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {error.message}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </div>

                        <div className="col-5 d-flex flex-column">
                          <label>Alternative Number</label>
                          <Controller
                            control={control}
                            name={`userForm.${index}.alternativeNumber`}
                            rules={{
                              minLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message if length is less than 10
                              },
                              maxLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message if length is more than 10
                              },
                              pattern: {
                                value: /^[0-9]{10}$/, // Ensure the input is numeric and exactly 10 digits
                                message:
                                  "Please enter a valid 10-digit mobile number", // Custom error message
                              },
                            }}
                            render={({
                              field: { onChange, onBlur, value, ref },
                              fieldState: { error },
                            }) => (
                              <>
                                <PinInput
                                  length={10}
                                  initialValue={value || ""}
                                  onChange={onChange}
                                  type="numeric"
                                  style={{ width: "100%", borderRadius: "5px" }}
                                  inputStyle={{
                                    border: "1px solid gray",
                                    backgroundColor: "#fff",
                                    height: "50px",
                                    width: "10%",
                                    margin: "0px",
                                    borderRadius: "5px",
                                  }}
                                  autoSelect={true}
                                />

                                {error && (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {error?.message}{" "}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <div className="d-flex justify-content-between">
                            <label>What App Number</label>
                            <div
                              style={{ fontSize: "12px", cursor: "pointer" }}
                              className="d-flex pt-1 justify-content-end"
                            >
                              <label className="d-flex gap-1 align-items-center">
                                <input
                                  onChange={(e) => {
                                    mobileNumberCheckBox(e, index);
                                  }}
                                  type="checkbox"
                                  style={{ width: "15px", height: "15px" }}
                                />
                                Also Same As Above
                              </label>
                            </div>
                          </div>
                          <Controller
                            key={
                              alsoWhatApp
                                ? "whatsapp-synced"
                                : "whatsapp-independent"
                            } // Unique key for re-rendering
                            control={control}
                            name={`userForm.${index}.whatsapp`}
                            rules={{
                              minLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message when length is less than 10
                              },
                              maxLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message when length is more than 10
                              },
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message:
                                  "Please enter a valid 10-digit mobile number", // Ensure it's numeric
                              },
                            }}
                            render={({ field: { onChange, value } }) => (
                              <PinInput
                                length={10}
                                initialValue={value}
                                value={value || ""}
                                onChange={(val) => onChange(val)}
                                type="numeric"
                                style={{ width: "100%", borderRadius: "5px" }}
                                inputStyle={{
                                  border: "1px solid gray",
                                  backgroundColor: "#fff",
                                  height: "50px",
                                  width: "10%",
                                  margin: "0px",
                                  borderRadius: "5px",
                                }}
                                autoSelect
                              />
                            )}
                          />
                        </div>
                        <div className="col-5  d-flex flex-column">
                          <label>E Mail</label>
                          <Controller
                            control={control}
                            name={`userForm.${index}.email`}
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="email"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 pb-3 d-flex justify-content-around">
                      <div className="col-5 d-flex flex-column">
                        <label>Caste</label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.caste`}
                          render={(field) => (
                            <InputField
                              visitorAddCss="visitorAddCss"
                              {...field}
                              type="text"
                              // placeholder="E-MAIL"
                            />
                          )}
                        />
                        {/* <Controller
                          control={control}
                          name="caste"
                          render={(field) => (
                            <InputField
                              visitorAddCss="visitorAddCss"
                              {...field}
                              type="text"
                              // placeholder="E-MAIL"
                            />
                          )}
                        /> */}
                      </div>
                      <div className="col-5 d-flex flex-column">
                        <label>Religion</label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.relegion`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <select
                              value={value}
                              className="visitorAddCss py-2"
                              onChange={onChange}
                              onBlur={onBlur} // Added onBlur for better form validation handling
                              ref={ref} // Make sure the ref is passed down for proper field tracking
                            >
                              <option value="">Select Religion</option>
                              <option value="Christianity">Christianity</option>
                              <option value="Islam">Islam</option>
                              <option value="Hinduism">Hinduism</option>
                              <option value="Other">Other</option>
                            </select>
                          )}
                        />
                        {userForm[index]?.relegion === "Other" && (
                          <Controller
                            name={`userForm.${index}.otherReligion`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                className="visitorAddCss my-2 py-2"
                                placeholder="Custom Religion"
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 pb-3 d-flex justify-content-around">
                      <div className="col-5 d-flex flex-column">
                        <label>Category</label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.category`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <select
                              className="visitorAddCss"
                              onChange={onChange}
                              value={value}
                            >
                              <option value=" "> </option>
                              <option value="Visitor">Visitor</option>
                              <option value="Client">Client</option>
                              <option value="Perspective">Perspective</option>
                              <option value="Business">Business</option>
                              <option value="Interview">Interview</option>
                              <option value="Other">Other</option>
                            </select>
                          )}
                        />
                        {userForm[index]?.category === "Other" && (
                          <Controller
                            name={`userForm.${index}.otherCategory`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                className="visitorAddCss my-2 py-2"
                                placeholder="Custom Category"
                              />
                            )}
                          />
                        )}
                      </div>
                      <div className="col-5 d-flex flex-column">
                        <label>Source Form</label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.sourceForm`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <select
                              value={value}
                              className="visitorAddCss"
                              onChange={onChange}
                            >
                              <option value=" "> </option>
                              <option value="JustDial">Just Dial</option>
                              <option value="YouTube">YouTube</option>
                              <option value="Google">Google</option>
                              <option value="Other">Other</option>
                            </select>
                          )}
                        />
                        {userForm[index]?.sourceForm === "Other" && (
                          <Controller
                            name={`userForm.${index}.otherSourceForm`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                className="visitorAddCss my-2 py-2"
                                placeholder="Custom Source"
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 pb-3 d-flex justify-content-around">
                      {/* <div className="col-5"> */}
                      <div className="col-5 pb-3 d-flex flex-column">
                        <label>Age</label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.age`}
                          render={(field) => (
                            <InputField
                              visitorAddCss="visitorAddCss"
                              {...field}
                              type="number"
                            />
                          )}
                        />
                      </div>

                      {/* </div> */}
                      <div className="col-5 d-flex flex-column">
                        <label>Place of Residence</label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.placeOfResidence`}
                          render={(field) => (
                            <InputField
                              visitorAddCss="visitorAddCss"
                              {...field}
                              type="text"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 pb-3 d-flex justify-content-around">
                      <div className="col-5 d-flex flex-column">
                        <label>State</label>

                        <Controller
                          control={control}
                          name={`userForm.${index}.state`}
                          render={({ field: { onChange, value } }) => (
                            <select
                              id="state"
                              value={value}
                              onChange={(e) => (
                                setDistricts(
                                  stateDistricts[e.target.value] || []
                                ),
                                onChange(e.target.value)
                              )}
                              className="visitorAddCss"
                            >
                              <option value="">Select State</option>
                              {Object.keys(stateDistricts).map((state) => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              ))}
                            </select>
                          )}
                        />
                      </div>
                      <div className="col-5 d-flex flex-column">
                        <label>District</label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.district`}
                          render={({ field: { onChange, value } }) => (
                            <select
                              id="district"
                              className="visitorAddCss"
                              value={value}
                              onChange={(e) => onChange(e.target.value)}
                            >
                              <option value="">Select District</option>
                              {districts.length !== 0
                                ? districts &&
                                  districts.map((district) => (
                                    <option key={district} value={district}>
                                      {district}
                                    </option>
                                  ))
                                : allDistricts &&
                                  allDistricts.map((district) => (
                                    <option key={district} value={district}>
                                      {district}
                                    </option>
                                  ))}
                            </select>
                            // <div
                            //   style={{
                            //     height: "50px",
                            //     width: "100%",
                            //     borderRadius: "5px",
                            //     border: "1px solid gray",
                            //     fontSize: "14px",
                            //     fontWeight: 400,
                            //     padding: "0px 6px",
                            //     outline: "none",
                            //     background: "#fff",
                            //   }}
                            // >
                            //   <CitySelect
                            //     className="visitorAddCss"
                            //     countryid={101}
                            //     stateid={isCitySelect}
                            //     value={value}
                            //     onChange={(e) => {
                            //       onChange(e.name);
                            //       // console.log("CitySelect",e.id)
                            //     }}
                            //     placeHolder="Select State"
                            //     style={{
                            //       border: "none",
                            //       height: "36px",
                            //       width: "100%",
                            //       borderRadius: "5px",
                            //       border: "1px solid gray",
                            //       fontSize: "14px",
                            //       fontWeight: 400,
                            //       padding: "0px 6px",
                            //       outline: "none",
                            //     }}
                            //   />
                            // </div>
                          )}
                        />
                      </div>
                    </div>
                    <div
                      className="col-5 pb-3 d-flex justify-content-start"
                      style={{ paddingLeft: "3.3rem" }}
                    >
                      <div className="col-12 d-flex flex-column">
                        <label>Pin code</label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.pincode`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <PinInput
                              length={6}
                              initialValue={value ? value : ""}
                              onChange={onChange}
                              type="numeric"
                              // inputMode="number"
                              style={{ width: "100%", borderRadius: "5px" }}
                              inputStyle={{
                                border: "1px solid gray",
                                backgroundColor: "#fff",
                                height: "50px",
                                width: "10%",
                                margin: "0px",
                                borderRadius: "5px",
                              }}
                              // inputFocusStyle={{ backgroundColor: "#002147", }}
                              onComplete={(value, index) => {}}
                              autoSelect={true}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 pb-3 d-flex justify-content-around">
                      <div className="col-5 d-flex flex-column">
                        <label>Temporary Address</label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.temporaryAddress`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <textarea
                              style={{ minHeight: "140px" }}
                              value={value}
                              className="visitorAddCss  py-2"
                              onChange={onChange}
                            ></textarea>
                          )}
                        />
                      </div>
                      <div className="col-5 d-flex flex-column">
                        <div className="d-flex justify-content-between">
                          <label>Permanent Address</label>
                          <div
                            style={{ fontSize: "12px", cursor: "pointer" }}
                            className="d-flex  justify-content-end"
                          >
                            <label className="d-flex gap-1 align-items-center">
                              <input
                                type="checkbox"
                                style={{ width: "15px", height: "15px" }}
                                onChange={(e) => {
                                  addressCheckBox(e, index);
                                }}
                              />
                              Also Same As Above
                            </label>
                          </div>
                        </div>

                        <Controller
                          control={control}
                          name={`userForm.${index}.permanentAddress`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <textarea
                              style={{ minHeight: "140px" }}
                              value={value}
                              className="visitorAddCss py-2"
                              onChange={onChange}
                            ></textarea>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-around">
                      <div className="col-5 d-flex flex-column">
                        <label>Office Address</label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.officeAddress`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <textarea
                              style={{ minHeight: "142px" }}
                              value={value}
                              className="visitorAddCss py-2"
                              onChange={onChange}
                            ></textarea>
                          )}
                        />
                      </div>
                      <div className="col-5 d-flex flex-column">
                        <div className="col-12 pb-3 d-flex flex-column">
                          <label>Data of visit</label>

                          <Controller
                            control={control}
                            name={`userForm.${index}.dateOfVisit`}
                            // defaultValue={today}
                            render={({ field }) => (
                              <DatePicker
                                dateFormat="eee dd/MM/yyyy"
                                className="visitorAddCss"
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                              />
                            )}
                          />
                        </div>
                        <div className="col-12 d-flex flex-column">
                          <label>Date of Entry</label>

                          {/* <Controller
                            control={control}
                            name={`userForm.${index}.dateOfAppoinment`}
                            render={({ field }) => (
                              <DatePicker
                                dateFormat="eee dd/MM/yyyy"
                                className="visitorAddCss"
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                              />
                            )}
                          /> */}
                          <Controller
                            control={control}
                            name={`userForm.${index}.dateOfAppoinment`}
                            render={({ field }) => (
                              <DatePicker
                                dateFormat="eee dd/MM/yyyy" // Format the date to display (e.g., Mon 06/11/2024)
                                className="visitorAddCss"
                                selected={
                                  field.value
                                    ? moment(field.value).toDate()
                                    : null
                                } // Convert moment to native Date
                                onChange={(date) =>
                                  field.onChange(moment(date))
                                } // Convert selected date to moment
                                minDate={moment().toDate()}
                                maxDate={moment().toDate()}
                                disabled={false}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12  d-flex justify-content-around">
                      <div
                        className="pb-3"
                        style={{ minHeight: "160px", width: "92%" }}
                      >
                        <label>Additional Particulars</label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.additionalStatus`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <textarea
                              style={{ height: "140px" }}
                              value={value}
                              className="visitorAddCss  py-2"
                              onChange={onChange}
                            ></textarea>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12  d-flex justify-content-around">
                      <div className="col-5 d-flex flex-column">
                        <div className="col-12 pb-3 d-flex flex-column">
                          <label>Status</label>
                          <Controller
                            control={control}
                            name={`userForm.${index}.status`}
                            defaultValue="Active"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <select
                                className="visitorAddCss"
                                value={value}
                                onChange={onChange}
                              >
                                <option value=""></option>
                                <option value="Active">Active</option>
                                <option value="In Active"> In Active</option>
                              </select>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-5 d-flex ">
                        <div className="col-6 pb-3 d-flex flex-column">
                          {/* <label>In Time</label>
                          <Controller
                            control={control}
                            name={`userForm.${index}.inTime`}
                            render={({ field }) => (
                              <input className="visitorAddCss"
                                type="time"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  setValue(userForm[index].outTime, ''); // Clear outTime when inTime changes
                                }}
                              />
                              // <InputField
                              //   visitorAddCss="visitorAddCss"
                              //   {...field}
                              //   type="time"
                              // />
                            )}
                          /> */}
                          <label>In Time</label>
                          <Controller
                            control={control}
                            name={`userForm.${index}.inTime`}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="time"
                                className="visitorAddCss"
                              />
                            )}
                          />
                        </div>

                        <div className="col-6 pb-3 d-flex flex-column">
                          {/* <label>Out Time</label>
                          <Controller
                            control={control}
                            name={`userForm.${index}.outTime`}
                            render={(field) => (
                              <input {...field} className="visitorAddCss" type="time" min={userForm[index].inTime} />
                              // <InputField
                              //   visitorAddCss="visitorAddCss"
                              //   {...field}
                              //   type="time"
                              // // mintime=
                              // />
                            )}
                          /> */}
                          <label>Out Time</label>
                          <Controller
                            control={control}
                            name={`userForm.${index}.outTime`}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="time"
                                className="visitorAddCss"
                                // Set the min value dynamically based on In Time
                                min={userForm[index]?.inTime || ""}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center py-3 mb-3">
                      <img
                        className=""
                        style={{ width: "80px", height: "60px" }}
                        onClick={() => {
                          appendVisitor();
                        }}
                        src={add}
                        alt=""
                      />
                      {index >= 1 ? (
                        <img
                          className=""
                          style={{ width: "80px", height: "50px" }}
                          onClick={() => {
                            removeVisitor(index);
                          }}
                          src={minus}
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="mb-3" style={{ cursor: "pointer" }}>
                <div
                  onClick={() => {
                    setShowClientInformation(true);
                  }}
                  className="col-12 visitorAddHeader"
                >
                  Client Information
                </div>
              </div>
              {showClientInformation && (
                <div>
                  <div className="">
                    <div className="row py-5">
                      <div className="col-12 pb-4 px-5 ">
                        <Controller
                          name="sameAsAbove"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <div className="d-flex align-items-center gap-1 m-0 p-0">
                              <input
                                type="checkbox"
                                id="sameAsAbove"
                                style={{ width: "20px", height: "20px" }}
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e); // keep the field state updated
                                  handleCheckboxChange(e); // handle the custom change logic
                                }}
                              />
                              <label htmlFor="sameAsAbove">
                                Also Same As Above
                              </label>
                            </div>
                          )}
                        />
                      </div>
                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <label>Client Name</label>
                          <Controller
                            control={control}
                            name="clientName"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div className="col-5 d-flex flex-column">
                          <label>Age</label>
                          <Controller
                            control={control}
                            name="clientAge"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <label>Mobile Number</label>
                          {/* <Controller
                            control={control}
                            name="clientMobileNumber"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <PinInput
                                length={10}
                                initialValue={value ? value : ""}
                                onChange={onChange}
                                type="numeric"
                                // inputMode="number"
                                style={{ width: "100%", borderRadius: "5px" }}
                                inputStyle={{
                                  border: "1px solid gray",
                                  backgroundColor: "#fff",
                                  height: "50px",
                                  width: "10%",
                                  margin: "0px",
                                  borderRadius: "5px",
                                }}
                                // inputFocusStyle={{ backgroundColor: "#002147", }}
                                onComplete={(value, index) => {}}
                                autoSelect={true}
                              />
                            )}
                          /> */}
                          <Controller
                            control={control}
                            name="clientMobileNumber"
                            key={
                              alsoClient
                                ? "whatsapp-synced"
                                : "whatsapp-independent"
                            }
                            rules={{
                              minLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message if length is less than 10
                              },
                              maxLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message if length is more than 10
                              },
                              pattern: {
                                value: /^[0-9]{10}$/, // Ensure the input is numeric and exactly 10 digits
                                message:
                                  "Please enter a valid 10-digit mobile number", // Custom error message
                              },
                            }}
                            render={({
                              field: { onChange, onBlur, value, ref },
                              fieldState: { error },
                            }) => (
                              <>
                                {/* PinInput component to handle numeric input */}
                                <PinInput
                                  length={10}
                                  initialValue={value || ""}
                                  onChange={onChange}
                                  type="numeric"
                                  style={{ width: "100%", borderRadius: "5px" }}
                                  inputStyle={{
                                    border: "1px solid gray",
                                    backgroundColor: "#fff",
                                    height: "50px",
                                    width: "10%",
                                    margin: "0px",
                                    borderRadius: "5px",
                                  }}
                                  autoSelect={true}
                                />

                                {/* Display error message if validation fails */}
                                {error && (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {error?.message}{" "}
                                    {/* Show the validation error message */}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </div>
                        <div className="col-5 d-flex flex-column">
                          <label>Alternative Number</label>
                          {/* <Controller
                            control={control}
                            name="clientAltNumber"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <PinInput
                                length={10}
                                initialValue={value ? value : ""}
                                onChange={onChange}
                                type="numeric"
                                // inputMode="number"
                                style={{ width: "100%", borderRadius: "5px" }}
                                inputStyle={{
                                  border: "1px solid gray",
                                  backgroundColor: "#fff",
                                  height: "50px",
                                  width: "10%",
                                  margin: "0px",
                                  borderRadius: "5px",
                                }}
                                // inputFocusStyle={{ backgroundColor: "#002147", }}
                                onComplete={(value, index) => {}}
                                autoSelect={true}
                              />
                            )}
                          /> */}
                          <Controller
                            control={control}
                            name="clientAltNumber"
                            key={
                              alsoClient
                                ? "whatsapp-synced"
                                : "whatsapp-independent"
                            }
                            rules={{
                              minLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message if length is less than 10
                              },
                              maxLength: {
                                value: 10,
                                message: "Mobile number must be 10 digits", // Error message if length is more than 10
                              },
                              pattern: {
                                value: /^[0-9]{10}$/, // Ensure the input is numeric and exactly 10 digits
                                message:
                                  "Please enter a valid 10-digit mobile number", // Custom error message
                              },
                            }}
                            render={({
                              field: { onChange, onBlur, value, ref },
                              fieldState: { error },
                            }) => (
                              <>
                                {/* PinInput component to handle numeric input */}
                                <PinInput
                                  length={10}
                                  initialValue={value || ""}
                                  onChange={onChange}
                                  type="numeric"
                                  style={{ width: "100%", borderRadius: "5px" }}
                                  inputStyle={{
                                    border: "1px solid gray",
                                    backgroundColor: "#fff",
                                    height: "50px",
                                    width: "10%",
                                    margin: "0px",
                                    borderRadius: "5px",
                                  }}
                                  autoSelect={true}
                                />

                                {/* Display error message if validation fails */}
                                {error && (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {error?.message}{" "}
                                    {/* Show the validation error message */}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5">
                          <div className="col-12 pb-3 d-flex flex-column">
                            <label>Email ID</label>
                            <Controller
                              control={control}
                              name="clientEmailId"
                              render={(field) => (
                                <InputField
                                  visitorAddCss="visitorAddCss"
                                  {...field}
                                  type="email"
                                />
                              )}
                            />
                          </div>
                          <div className="col-12 d-flex flex-column">
                            <label>Father Name</label>
                            <Controller
                              control={control}
                              name="clientFatherName"
                              render={(field) => (
                                <InputField
                                  visitorAddCss="visitorAddCss"
                                  {...field}
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        </div>

                        <div className="col-5 d-flex flex-column">
                          <label>Address</label>
                          <Controller
                            control={control}
                            name="clientAddress"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <textarea
                                value={value}
                                className="visitorAddCss h-100 py-2"
                                onChange={onChange}
                              ></textarea>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <label>Married/ Unmarried</label>
                          <Controller
                            control={control}
                            name="clientMarried"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div className="col-5 d-flex flex-column">
                          <label>
                            Guardian/ Spouse/ Parent/ Children/ Friend
                          </label>
                          <Controller
                            control={control}
                            name="clientGuardian"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <header className="visitorAddHeader mb-2">
                      SUBJECT DETAILS
                    </header>
                    <div className="py-5">
                      <div className="col-12 pb-3 d-flex justify-content-around">
                        {/* <div className="col-5"> */}
                        <div className="col-5 pb-3 d-flex flex-column">
                          <label>Client Name</label>
                          <Controller
                            control={control}
                            // name={`subjectDetails.${index}.subjectClientName`}
                            name="subjectClientName"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div className="col-5 d-flex flex-column">
                          <label>Subject</label>
                          <Controller
                            control={control}
                            // name={`subjectDetails.${index}.subject`}
                            name="subject"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                          {/* </div> */}
                        </div>
                      </div>

                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <label>Case Number</label>
                          <Controller
                            control={control}
                            // name={`subjectDetails.${index}.subjectCaseNumber`}
                            name="subjectCaseNumber"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                                // placeholder="E-MAIL"
                              />
                            )}
                          />
                        </div>
                        <div className="col-5 d-flex flex-column">
                          <label>File Number</label>
                          <Controller
                            control={control}
                            // name={`subjectDetails.${index}.subjectFileNumber`}
                            name="subjectFileNumber"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                                // placeholder="E-MAIL"
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <label>Mobile Number</label>
                          <Controller
                            control={control}
                            // name={`subjectDetails.${index}.subjectMobileNumber`}
                            name="subjectMobileNumber"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <PinInput
                                length={10}
                                initialValue={value ? value : ""}
                                onChange={onChange}
                                type="numeric"
                                // inputMode="number"
                                style={{ width: "100%", borderRadius: "5px" }}
                                inputStyle={{
                                  border: "1px solid gray",
                                  backgroundColor: "#fff",
                                  height: "50px",
                                  width: "10%",
                                  margin: "0px",
                                  borderRadius: "5px",
                                }}
                                // inputFocusStyle={{ backgroundColor: "#002147", }}
                                onComplete={(value, index) => {}}
                                autoSelect={true}
                              />
                            )}
                          />
                        </div>
                        <div className="col-5 d-flex flex-column">
                          <label>Movable Properties</label>
                          <Controller
                            control={control}
                            // name={`subjectDetails.${index}.subjectMovable`}
                            name="subjectMovableProperties"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                                // placeholder="E-MAIL"
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <label>Caste</label>
                          <Controller
                            control={control}
                            name="subjectCaste"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                                // placeholder="E-MAIL"
                              />
                            )}
                          />
                        </div>
                        <div className="col-5 d-flex flex-column">
                          <label>Religion</label>
                          <Controller
                            control={control}
                            name="subjectReligion"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                                // placeholder="E-MAIL"
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <label>Education</label>
                          <Controller
                            control={control}
                            // name={`subjectDetails.${index}.subjectCaseNumber`}
                            name="subjectEducation"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                                // placeholder="E-MAIL"
                              />
                            )}
                          />
                        </div>
                        <div className="col-5 d-flex flex-column">
                          <label>Employment</label>
                          <Controller
                            control={control}
                            // name={`subjectDetails.${index}.subjectFileNumber`}
                            name="subjectEmployment"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                                // placeholder="E-MAIL"
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div
                        className="col-12 pb-3 d-flex justify-content-around"
                        style={{ height: "150px" }}
                      >
                        <div className="col-5 d-flex flex-column">
                          <label>Nature of Closed</label>
                          <Controller
                            control={control}
                            // name={`subjectDetails.${index}.subjectNatureOfClose`}
                            name="subjectNatureOfClose"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <textarea
                                className="visitorAddCss h-100 py-2"
                                onChange={onChange}
                                value={value}
                              ></textarea>
                            )}
                          />
                        </div>
                        <div className="col-5 d-flex flex-column">
                          <label>Address</label>
                          <Controller
                            control={control}
                            // name={`subjectDetails.${index}.SubjectAddress`}
                            name="SubjectAddress"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <textarea
                                className="visitorAddCss h-100 py-2"
                                onChange={onChange}
                                value={value}
                              ></textarea>
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <label>Date of Regards </label>
                          <Controller
                            control={control}
                            name="subjectDateRegards"
                            render={({ field }) => (
                              <DatePicker
                                dateFormat="eee dd/MM/yyyy"
                                className="visitorAddCss"
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                              />
                            )}
                          />
                        </div>

                        <div className="col-5 d-flex flex-column">
                          <label>Date of Closed</label>
                          <Controller
                            control={control}
                            name="subjectDateClose"
                            render={({ field }) => (
                              <DatePicker
                                dateFormat="eee dd/MM/yyyy"
                                className="visitorAddCss"
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="row d-flex justify-content-center ">
                        <img
                          className=""
                          style={{ width: "80px", height: "50px" }}
                          onClick={() => {
                            appendSubject();
                          }}
                          src={add}
                          alt=""
                        />
                      </div>
                    </div>
                    {fieldsSubject?.map((field, index) => {
                      // console.log("fieldsSubject", fieldsSubject);
                      return (
                        <div key={field.id}>
                          <header className="visitorAddHeader mb-2">
                            SUBJECT DETAILS {index + 1}
                          </header>
                          <div className="py-2">
                            <div className="col-12 pb-3 d-flex justify-content-around">
                              <div className="col-5">
                                <div className="col-12 pb-3 d-flex flex-column">
                                  <label>Client Name</label>
                                  <Controller
                                    control={control}
                                    name={`subjectDetails.${index}.subjectAppClientName`}
                                    render={(field) => (
                                      <InputField
                                        visitorAddCss="visitorAddCss"
                                        {...field}
                                        type="text"
                                      />
                                    )}
                                  />
                                </div>
                                <div className="col-12 d-flex flex-column">
                                  <label>Subject</label>
                                  <Controller
                                    control={control}
                                    name={`subjectDetails.${index}.subjectApp`}
                                    render={(field) => (
                                      <InputField
                                        visitorAddCss="visitorAddCss"
                                        {...field}
                                        type="text"
                                      />
                                    )}
                                  />
                                </div>
                              </div>

                              <div className="col-5 d-flex flex-column">
                                <label>Address</label>
                                <Controller
                                  control={control}
                                  name={`subjectDetails.${index}.subjectAppAddress`}
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <textarea
                                      value={value}
                                      className="visitorAddCss h-100 py-2"
                                      onChange={onChange}
                                    ></textarea>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-12 pb-3 d-flex justify-content-around">
                              <div className="col-5 d-flex flex-column">
                                <label>Mobile Number</label>
                                <Controller
                                  control={control}
                                  name={`subjectDetails.${index}.subjectAppMobileNumber`}
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <PinInput
                                      length={10}
                                      initialValue={value ? value : ""}
                                      onChange={onChange}
                                      type="numeric"
                                      // inputMode="number"
                                      style={{
                                        width: "100%",
                                        borderRadius: "5px",
                                      }}
                                      inputStyle={{
                                        border: "1px solid gray",
                                        backgroundColor: "#fff",
                                        height: "50px",
                                        width: "10%",
                                        margin: "0px",
                                        borderRadius: "5px",
                                      }}
                                      // inputFocusStyle={{ backgroundColor: "#002147", }}
                                      onComplete={(value, index) => {}}
                                      autoSelect={true}
                                    />
                                  )}
                                />
                              </div>
                              <div className="col-5 d-flex flex-column">
                                <div className="row d-flex justify-content-start py-3 mb-3 ">
                                  <img
                                    className=""
                                    style={{ width: "80px", height: "50px" }}
                                    onClick={() => {
                                      appendSubject();
                                    }}
                                    src={add}
                                    alt=""
                                  />
                                  {
                                    <img
                                      className=""
                                      style={{ width: "80px", height: "50px" }}
                                      onClick={() => {
                                        removeSubject(index);
                                      }}
                                      src={minus}
                                      alt=""
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <header className="visitorAddHeader mb-2">
                      CASE DETAILS
                    </header>
                    <div>
                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <label>Case Number</label>
                          <Controller
                            control={control}
                            name="caseDetailsName"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                        </div>
                        <div className="col-5 d-flex flex-column">
                          <label>Nature of Care (Title)</label>
                          <Controller
                            control={control}
                            name="caseDetailsNatureOfCaseTitle"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div className="col-5 d-flex flex-column">
                          <label>Nature of Care (Client’s Requirement)</label>
                          <Controller
                            control={control}
                            name="natureOfCaseClientRequirement"
                            render={(field) => (
                              <InputField
                                visitorAddCss="visitorAddCss"
                                {...field}
                                type="text"
                              />
                            )}
                          />
                        </div>

                        <div className="col-5 d-flex flex-column">
                          <label>Date</label>
                          <Controller
                            control={control}
                            name="caseDetailsDate"
                            render={({ field }) => (
                              <DatePicker
                                dateFormat="eee dd/MM/yyyy"
                                className="visitorAddCss"
                                selected={field.value}
                                onChange={(date) => field.onChange(date)}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-12 pb-3 d-flex justify-content-around">
                        <div
                          className=""
                          style={{ minHeight: "160px", width: "92%" }}
                        >
                          <label>Nature of Care (Details)</label>

                          <Controller
                            control={control}
                            name="natureOfCareDetails"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <textarea
                                ref={ref}
                                value={value}
                                style={{ height: "140px", width: "100%" }}
                                className="visitorAddCss py-2"
                                onFocus={handleFocus} // Open modal on focus
                                onChange={(e) => {
                                  onChange(e); // Update form state (although we will override this value with modal)
                                }}
                                onBlur={onBlur}
                                disabled={isModalOpen}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="col-12 pb-3 d-flex justify-content-around">
                          <div className="col-11 d-flex flex-column">
                            <label className="fw-bold text-underline">
                              Amount Details
                            </label>
                          </div>
                        </div>
                        <div>
                          <div className="col-12 pb-3 d-flex justify-content-around">
                            <div className="col-5 d-flex flex-column">
                              <label>Total Amount</label>
                              <Controller
                                control={control}
                                name="caseDetailsTotalAmount"
                                render={(field) => (
                                  <InputField
                                    visitorAddCss="visitorAddCss"
                                    {...field}
                                    type="text"
                                  />
                                )}
                              />
                            </div>
                            <div className="col-5 d-flex flex-column">
                              <label>Amount Received</label>
                              <Controller
                                control={control}
                                name="caseDetailsAmountReceived"
                                render={(field) => (
                                  <InputField
                                    visitorAddCss="visitorAddCss"
                                    {...field}
                                    type="text"
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-12 pb-3 d-flex justify-content-around">
                            <div className="col-5 d-flex flex-column">
                              <label>Balance Pending</label>
                              <Controller
                                control={control}
                                name="caseDetailsBalancePending"
                                render={(field) => (
                                  <InputField
                                    visitorAddCss="visitorAddCss"
                                    {...field}
                                    type="text"
                                  />
                                )}
                              />
                            </div>
                            <div className="col-5 d-flex flex-column">
                              <label>Payment Type</label>
                              <Controller
                                control={control}
                                name="caseDetailsPaymentType"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <select
                                    value={value}
                                    className="visitorAddCss  py-2"
                                    onChange={onChange}
                                  >
                                    <option value=" "> </option>
                                    <option value=" Cash">Cash </option>
                                    <option value=" Card">Card </option>
                                    <option value="Online">Online</option>
                                  </select>
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-12 pb-3 d-flex justify-content-around">
                            <div className="col-5 d-flex flex-column">
                              <label>Status</label>
                              <Controller
                                control={control}
                                name="caseDetailsStatus"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <select
                                    value={value}
                                    className="visitorAddCss  py-2"
                                    onChange={onChange}
                                  >
                                    <option value=" "> </option>
                                    <option value="pending">Pending</option>
                                    <option value="completed">Completed</option>
                                  </select>
                                )}
                              />
                            </div>
                            <div className="col-5 d-flex flex-column">
                              <label>Date</label>
                              <Controller
                                control={control}
                                name="caseDetailsAmountDate"
                                render={({ field }) => (
                                  <DatePicker
                                    dateFormat="eee dd/MM/yyyy"
                                    className="visitorAddCss"
                                    selected={field.value}
                                    onChange={(date) => field.onChange(date)}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex justify-content-center py-3"
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            {/* <div style={{
                              display: 'flex', flexDirection: "row", justifyContent: "space-around",
                              width: "90%"
                            }}>
                              <div>
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500px",
                                  }}
                                >
                                  Balance Pending
                                </span>
                              </div>
                              <div>

                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500px",
                                  }}
                                >
                                  Amount Received
                                  :
                                </span>
                              </div>
                              <div>

                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500px",
                                  }}
                                >
                                  Date Of Pay

                                </span>
                              </div>
                              <div>

                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500px",
                                  }}
                                >
                                  Status
                                </span>
                              </div>
                            </div>
                            {state &&
                              state?.caseDetails?.balancePending.map(
                                (ele, i) => {
                                  console.log(
                                    "state && state?.caseDetails",
                                    state?.caseDetails?.totalAmount,
                                    state &&
                                    state?.caseDetails?.amountReceived.reduce((a, b) => a + b)
                                  );
                                  return (

                                    <div className="col-11 pb-3 d-flex justify-content-center">
                                      <div className="col-3 visitorAddAmtDetails">


                                        {state &&
                                          state?.caseDetails?.balancePending[i]}
                                      </div>
                                      <div className="col-3 visitorAddAmtDetails">


                                        {state &&
                                          state?.caseDetails?.amountReceived[i]}
                                      </div>
                                      <div className="col-3 visitorAddAmtDetails">

                                        {moment(
                                          state &&
                                          state?.caseDetails?.amountDate[i]
                                        )
                                          .utc()
                                          .format("DD-MM-YYYY")}
                                      </div>
                                      <div className="col-3 visitorAddAmtDetails">

                                        {state && state?.caseDetails?.status[i]}
                                      </div>
                                    </div>

                                    // </div>
                                  );
                                }
                              )} */}
                            {/* <table style={{ borderCollapse: "collapse", width: "100%" }}>
                              <thead>
                                <tr>
                                  <th>Balance Pending</th>
                                  <th>Amount Received</th>
                                  <th>Date Of Pay</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody >
                                {state &&
                                  state?.caseDetails?.balancePending.map(
                                    (ele, i) => (
                                      <tr key={i}>
                                        <td className="visitorAddAmtDetails">{state &&
                                          state?.caseDetails?.balancePending[i]}</td>
                                        <td className="visitorAddAmtDetails">{state &&
                                          state?.caseDetails?.amountReceived[i]}</td>
                                        <td className="visitorAddAmtDetails">{moment(
                                          state &&
                                          state?.caseDetails?.amountDate[i]
                                        )
                                          .utc()
                                          .format("DD-MM-YYYY")}</td>
                                        <td className="visitorAddAmtDetails">  {state && state?.caseDetails?.status[i]}</td>
                                      </tr>
                                    ))}
                              </tbody>
                            </table> */}
                            <table
                              style={{
                                borderCollapse: "collapse",
                                width: "100%",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th className="visitorAddAmtDetails">
                                    Balance Pending
                                  </th>
                                  <th className="visitorAddAmtDetails">
                                    Amount Received
                                  </th>
                                  <th className="visitorAddAmtDetails">
                                    Date Of Pay
                                  </th>
                                  <th className="visitorAddAmtDetails">
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {state &&
                                  state?.caseDetails?.balancePending.map(
                                    (ele, i) => {
                                      // Exclude rows where amountReceived is null
                                      if (
                                        state?.caseDetails?.amountReceived[
                                          i
                                        ] === null
                                      ) {
                                        return null;
                                      }
                                      return (
                                        <tr key={i}>
                                          <td className="visitorAddAmtDetails">
                                            {
                                              state?.caseDetails
                                                ?.balancePending[i]
                                            }
                                          </td>
                                          <td className="visitorAddAmtDetails">
                                            {
                                              state?.caseDetails
                                                ?.amountReceived[i]
                                            }
                                          </td>
                                          <td className="visitorAddAmtDetails">
                                            {moment(
                                              state?.caseDetails?.amountDate[i]
                                            )
                                              .utc()
                                              .format("DD-MM-YYYY")}
                                          </td>
                                          <td className="visitorAddAmtDetails">
                                            {state?.caseDetails?.status[i]}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <header className="visitorAddHeader mb-2 ">
                        REFERENCE DETAILS
                      </header>
                      <div>
                        <div className="col-12 pb-3 d-flex justify-content-around">
                          <div className="col-5 d-flex flex-column">
                            <label>Reference</label>
                            <Controller
                              control={control}
                              name="reference"
                              render={(field) => (
                                <InputField
                                  visitorAddCss="visitorAddCss"
                                  {...field}
                                  type="text"
                                />
                              )}
                            />
                          </div>
                          <div className="col-5 d-flex flex-column">
                            <label>Name of (Los)</label>
                            <Controller
                              control={control}
                              name="referenceNameOfLos"
                              render={(field) => (
                                <InputField
                                  visitorAddCss="visitorAddCss"
                                  {...field}
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-12 pb-3 d-flex justify-content-around">
                          <div className="col-5 d-flex flex-column">
                            <label>Date</label>
                            <Controller
                              control={control}
                              name="referenceData"
                              render={({ field }) => (
                                <DatePicker
                                  dateFormat="eee dd/MM/yyyy"
                                  className="visitorAddCss"
                                  selected={field.value}
                                  onChange={(date) => field.onChange(date)}
                                />
                              )}
                            />
                          </div>
                          <div className="col-5 d-flex flex-column">
                            <label>File Number</label>
                            <Controller
                              control={control}
                              name="referenceFileNumber"
                              render={(field) => (
                                <InputField
                                  visitorAddCss="visitorAddCss"
                                  {...field}
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-12 pb-3 d-flex justify-content-around">
                          <div className="col-5 d-flex flex-column">
                            <label>Reason for Closure</label>
                            <Controller
                              control={control}
                              name="referenceReasonForClosure"
                              render={(field) => (
                                <InputField
                                  visitorAddCss="visitorAddCss"
                                  {...field}
                                  type="text"
                                />
                              )}
                            />
                          </div>
                          <div className="col-5 d-flex flex-column">
                            <label>Conf.Report</label>
                            <Controller
                              control={control}
                              name="referenceConfReport"
                              render={(field) => (
                                <InputField
                                  visitorAddCss="visitorAddCss"
                                  {...field}
                                  type="text"
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mb-3 px-2">
                <button type="submit" className="col-12  visitorAddHeader">
                  {isLoading ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>

        <div>
          <Alert
            open={postAlert}
            setPutAlert={setPutAlert}
            postAlert={postAlert}
            state={state}
            upData={upData}
            putAlert={putAlert}
            mutate={mutate}
            navigate={navigate}
            id={state?.id}
            add={true}
            onCloses={setPostAlert}
            // setPutAlert={setPutAlert}
          />
        </div>
      </div>
      <Modal
        show={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSave}
        value={watch("natureOfCareDetails")}
      />
    </>
  );
}
