import React from "react";
// import Sidebar from './Sidebar'
import ViewRegister from "../screens/ViewRegister";
// import SidebarMenu from './SidebarMenu'

export default function ViewRegisterComponent() {
  return (
    <section>
      <ViewRegister />
    </section>
  );
}
