import { useEffect, useState } from "react";

const PasscodePopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [enteredCode, setEnteredCode] = useState(""); // Store user input
  const correctPasscode = "2k00111";

  useEffect(() => {
    const popupShown = localStorage.getItem("popupShown");
    const targetDate = new Date("2025-03-19T11:00:00").getTime(); // 19 March 2025 at 11:00 AM
    const currentTime = new Date().getTime();

    if (!popupShown) {
      const timeDifference = targetDate - currentTime;

      if (timeDifference <= 0) {
        // If the target date has already passed, open the popup immediately
        setShowPopup(true);
      } else {
        // Schedule the popup to open at the target date and time
        setTimeout(() => {
          setShowPopup(true);
        }, timeDifference);
      }
    }
  }, []);

  const handlePasscodeSubmit = () => {
    if (enteredCode === correctPasscode) {
      localStorage.setItem("popupShown", "true"); // Mark popup as shown
      setShowPopup(false);
    } else {
      alert("Incorrect passcode. Try again!");
    }
  };

  return (
    <>
      {showPopup && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              width: "35%",
              height: "auto",
              borderRadius: "5px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign:"center"
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h5>For verification purposes, please enter a PIN code to continue using your software</h5>
            <h6 style={{color:""}}> Contact 2K Virtual World</h6>
            <input
              type="text"
              placeholder="Enter Code"
              value={enteredCode}
              onChange={(e) => setEnteredCode(e.target.value)}
              style={styles.input}
            />
            <button onClick={handlePasscodeSubmit} style={styles.button}>
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  input: {
    display: "block",
    margin: "10px 0",
    padding: "5px",
    textAlign: "center",
  },
  button: {
    padding: "8px 12px",
    background: "#007bff",
    color: "white",
    border: "none",
    cursor: "pointer",
  },
};

export default PasscodePopup;
