import React, { useState } from "react";
import companyLogo from "../asset/logo.jpg";
import LoginImg from "../asset/loginImg.jpg";
import { Controller, useForm } from "react-hook-form";
import InputField from "../component/InputField";
import userIcon from "../asset/User.png";
import emailIcon from "../asset/Email.png";
import passwordIcon from "../asset/Lock.png";
import { useNavigate } from "react-router";
import { useMutation } from "react-query";
import visible from "../asset/visibility.png";
import visibleOff from "../asset/visibility_off.png";
import axios from "axios";
import Images from "../asset";
import AlertModal from "../component/AlertModal";
import hatImg from "../asset/hatImg.png";
import binocular from "../asset/binocular.png";
import notPad from "../asset/notePad.png";
import whiteSheet from "../asset/whiteSheet.png";

export default function ResetPassword() {
  const { control, handleSubmit, setValue, watch, register } = useForm();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(true);
  const [showError, setShowError] = useState();
  const [open, setOpen] = useState();

  const { mutate, isLoading } = useMutation(
    (postData) =>
      // axios.post(`${process.env.REACT_APP_HOS}/registers`, postData),
      axios.post(
        `${process.env.REACT_APP_HOST}/registers/resetpassword`,
        postData
      ),
    {
      onSuccess: (data) => {
        setOpen(data && data?.data);
        // sessionStorage.setItem("login", true);
        // window.location?.reload();
        // navigate("/");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
        setShowError(error?.response.data);
      },
    }
  );

  const submit = (data) => {
    console.log("data", data);
    const formData = {
      newPassword: data?.pass,
    };
    mutate(data);
  };
  return (
    // <div className="userRegisterContainer container-fluid px-0 ">
    //   <div className="userRegisterInnerContainer d-flex justify-content-center align-items-center">
    //     <div className="userRegister_registerMainContainer">
    //       <header className="userRegister_registerHeader">
    //         <img
    //           className=" userRegister_registerLogo"
    //           src={companyLogo}
    //           alt=""
    //         />
    //       </header>
    //       <section className="d-flex justify-content-between userRegister_registerSection ">
    //         <div className="userRegister_registerRightImgDiv">
    //           <img
    //             className=" userRegister_registerRightImg"
    //             src={LoginImg}
    //             alt=""
    //           />
    //         </div>
    //         <div className="userRegister_registerForm  mx-3 py-4 ">
    //           <p className="fw-bold mb-0">Reset Password</p>
    //           <p className="">Welcome Back,</p>
    //           <form className="row" onSubmit={handleSubmit(submit)}>
    //             <div className="pe-5 ">
    //               <div className="userRegisterInputs d-flex">
    //                 <img
    //                   className="userRegisterInputLogo"
    //                   src={userIcon}
    //                   alt=""
    //                 />
    //                 <Controller
    //                   control={control}
    //                   name="email"
    //                   render={(field) => (
    //                     <InputField
    //                       registerInputCss=" registerInputCss"
    //                       {...field}
    //                       type="email"
    //                       placeholder="E-Mail"
    //                     />
    //                   )}
    //                 />
    //               </div>
    //               <div className="userRegisterInputs d-flex">
    //                 <img
    //                   className="userRegisterInputLogo"
    //                   src={userIcon}
    //                   alt=""
    //                 />
    //                 <Controller
    //                   control={control}
    //                   name="otp"
    //                   render={(field) => (
    //                     <InputField
    //                       registerInputCss=" registerInputCss"
    //                       {...field}
    //                       type="number"
    //                       placeholder="ENTER CODE"
    //                     />
    //                   )}
    //                 />
    //               </div>
    //               <div className="userRegisterInputs d-flex">
    //                 <img
    //                   className="userRegisterInputLogo"
    //                   src={passwordIcon}
    //                   alt=""
    //                 />
    //                 <Controller
    //                   control={control}
    //                   name="newPassword"
    //                   render={(field) => (
    //                     <InputField
    //                       registerInputCss=" registerInputCss"
    //                       {...field}
    //                       type={isShow?"password":"text"}
    //                       placeholder="NEW PASSWORD"
    //                     />
    //                   )}
    //                 />
    //                 {!isShow ? (
    //                   <img
    //                     onClick={() => setIsShow(!isShow)}
    //                     src={visibleOff}
    //                     alt="Show"
    //                     style={{height:"30px"}}
    //                   />
    //                 ) : (
    //                   <img
    //                     onClick={() => setIsShow(!isShow)}
    //                     src={visible}
    //                     alt="Show"
    //                     style={{height:"30px"}}
    //                   />
    //                 )}
    //               </div>
    //               <div className="userRegisterBtnContainer mt-3">
    //                 <button className="userRegisterBtn" type="submit">
    //                   {isLoading ? (
    //                     <span
    //                       class="spinner-border spinner-border-sm"
    //                       role="status"
    //                       aria-hidden="true"
    //                     ></span>
    //                   ) : (
    //                     "Submit"
    //                   )}
    //                 </button>
    //               </div>
    //             </div>
    //           </form>
    //           <div className="d-flex justify-content-between">
    //             <div
    //               onClick={() => {
    //                 navigate("/");
    //               }}
    //               className="text-end pe-5  text-decoration-underline userLoginNavigate"
    //             >
    //               Login?
    //             </div>

    //           </div>
    //         </div>
    //       </section>
    //     </div>
    //   </div>
    // </div>
    <div
      className="userRegisterContainer container-fluid px-0 "
      style={{ overflow: "hidden",display:"flex" }}
    >
     <div
        className="loginNewBackImage"
        style={{
          width: "50%",
        }}
      >
        <div className="d-flex">
          <div className="loginHatImage">
            <img
              className="hatMove"
              style={{ height: "100%" }}
              src={hatImg}
              alt=""
            />
          </div>
          <div className="loginWhiteSheet">
            <img
              // className="loginWhiteSheet"
              style={{ height: "100%" }}
              src={whiteSheet}
              alt=""
            />
          </div>
        </div>
        <div className="loginBinocularImage">
          <img style={{ height: "100%" }} src={binocular} alt="" />
        </div>
        <div className="loginNotPadImage">
          <img
            style={{ height: "100%" }}
            className="notPadImage"
            src={notPad}
            alt=""
          />
        </div>
      </div>
      <div
        className="row vh-100"
        style={{
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width:"50%"
        }}
      >
        <div
          className="col-lg-9"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div className="bg-white_loginform">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img src={Images?.loginlogoIcon} alt="loginlogoIcon" />
            </div>
            <p
              className=""
              style={{
                textShadow: " 2px 2px #00000040",
                font: "Poppins",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Reset Password
            </p>
            {/* <p className="">Welcome Back,</p> */}
            <form className="row" onSubmit={handleSubmit(submit)}>
              <div className=" ">
                <div className="userRegisterInputs --userRegisterInputs d-flex">
                  <img
                    className="userRegisterInputLogo"
                    src={userIcon}
                    alt=""
                  />
                  <Controller
                    control={control}
                    name="email"
                    render={(field) => (
                      <InputField
                        registerInputCss=" registerInputCss"
                        {...field}
                        type="email"
                        placeholder="E-Mail"
                      />
                    )}
                  />
                </div>
                <div className="userRegisterInputs --userRegisterInputs d-flex">
                  <img
                    className="userRegisterInputLogo"
                    src={userIcon}
                    alt=""
                  />
                  <Controller
                    control={control}
                    name="otp"
                    render={(field) => (
                      <InputField
                        registerInputCss=" registerInputCss"
                        {...field}
                        type="number"
                        placeholder="ENTER CODE"
                      />
                    )}
                  />
                </div>
                <div className="userRegisterInputs --userRegisterInputs d-flex">
                  <img
                    className="userRegisterInputLogo"
                    src={passwordIcon}
                    alt=""
                  />
                  <Controller
                    control={control}
                    name="newPassword"
                    render={(field) => (
                      <InputField
                        registerInputCss=" registerInputCss"
                        {...field}
                        type={isShow ? "password" : "text"}
                        placeholder="NEW PASSWORD"
                      />
                    )}
                  />
                  {!isShow ? (
                    <img
                      onClick={() => setIsShow(!isShow)}
                      src={visible}
                      alt="Show"
                      style={{ height: "30px" }}
                    />
                  ) : (
                    <img
                      onClick={() => setIsShow(!isShow)}
                      src={visibleOff}
                      alt="Show"
                      style={{ height: "30px" }}
                    />
                  )}
                </div>
                <div className="userRegisterBtnContainer mt-3">
                  <button className="userRegisterBtn" type="submit">
                    {isLoading ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </form>
            <div
              onClick={() => {
                navigate("/");
              }}
              className="text-end pe-5  text-decoration-underline userLoginNavigate"
            >
              Login?
            </div>
            <p className="mb-0 fs-5 fw-bold text-danger">{showError}</p>

            {/* <div className="userRegister_registerMainContainer userRegister_registerForm ">
        
          </div> */}
          </div>
        </div>
      </div>
      <AlertModal
        open={open}
        setOpen={setOpen}
        // reset={reset}
        // handleClose={handleClose}
        // setUpdateOpen={setUpdateOpen}
        // UpdateOpen={UpdateOpen}
        navigate={"/"}
        // setErrorOpen={setErrorOpen}
        // errorOpen={errorOpen}
      />
    </div>
  );
}
