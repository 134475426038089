import React, { useState } from "react";
import companyLogo from "../asset/logo.jpg";
import LoginImg from "../asset/loginImg.jpg";
import { Controller, useForm } from "react-hook-form";
import InputField from "../component/InputField";
import userIcon from "../asset/User.png";
import emailIcon from "../asset/Email.png";
import passwordIcon from "../asset/Lock.png";
import { useNavigate } from "react-router";
import { useMutation } from "react-query";
import visible from "../asset/visibility.png";
import visibleOff from "../asset/visibility_off.png";
import axios from "axios";
import Images from "../asset";
import hatImg from "../asset/hatImg.png";
import binocular from "../asset/binocular.png";
import notPad from "../asset/notePad.png";
import whiteSheet from "../asset/whiteSheet.png";

export default function UserLogin() {
  const { control, handleSubmit, setValue, watch, register } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [isShow, setIsShow] = useState(true);
  // console.log('error',error);

  const { mutate, isLoading } = useMutation(
    (postData) =>
      // axios.post(`${process.env.REACT_APP_HOS}/registers`, postData),
      axios.post(
        `${process.env.REACT_APP_HOST}/registers/login`,
        postData
      ),
    {
      onSuccess: (data) => {
        console.log("DDDD", data?.data?.register?.role);
        sessionStorage.setItem("login", true);
        sessionStorage.setItem(
          "userdata",
          data && data?.data?.register ? data?.data?.register?.role : "admin"
        );
        // console.log('userdata',data[0])
        window.location.reload();
        navigate("/");
      },
      onError: (error) => {
        setError(error && error?.response?.data?.message);
      },
    }
  );

  const submit = (data) => {
    console.log("data", data);
    mutate(data);
  };
  return (
    <div
      className="userRegisterContainer container-fluid px-0 "
      style={{ overflow: "hidden", display: "flex" }}
    >
      <div
        className="loginNewBackImage"
        style={{
          width: "50%",
        }}
      >
        <div className="d-flex">
          <div className="loginHatImage">
            <img
              className="hatMove"
              style={{ height: "100%" }}
              src={hatImg}
              alt=""
            />
          </div>
          <div className="loginWhiteSheet">
            <img
              // className="loginWhiteSheet"
              style={{ height: "100%" }}
              src={whiteSheet}
              alt=""
            />
          </div>
        </div>
        <div className="loginBinocularImage">
          <img style={{ height: "100%" }} src={binocular} alt="" />
        </div>
        <div className="loginNotPadImage">
          <img
            style={{ height: "100%" }}
            className="notPadImage"
            src={notPad}
            alt=""
          />
        </div>
      </div>
      <div
        className="row vh-100"
        style={{
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
        }}
      >
        <div
          className="col-lg-9"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div className="bg-white_loginform">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img src={Images?.loginlogoIcon} alt="loginlogoIcon" />
            </div>
            <div>
              <p
                className=" "
                style={{
                  // boxShadow: "0px 4px 4px 0px #00000040",
                  textShadow: " 2px 2px #00000040",
                  font: "Poppins",
                  fontSize: "35px",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Login
              </p>
            </div>
            {/* <p className="">Welcome Back,</p> */}
            <form className="row" onSubmit={handleSubmit(submit)}>
              <div>
                {/* <p className="mb-0">User email addresss</p> */}
                <div className="userRegisterInputs --userRegisterInputs d-flex">
                  <Controller
                    control={control}
                    name="email"
                    render={(field) => (
                      <InputField
                        registerInputCss=" registerInputCss"
                        {...field}
                        type="text"
                        placeholder="E-Mail"
                      />
                    )}
                  />
                </div>

                <div className="userRegisterInputs mt-3 --userRegisterInputs d-flex">
                  <Controller
                    control={control}
                    name="password"
                    render={(field) => (
                      <InputField
                        registerInputCss=" registerInputCss"
                        {...field}
                        type={`${!isShow ? "text" : "password"}`}
                        placeholder="PASSWORD"
                      />
                    )}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  >
                    {!isShow ? (
                      <div
                        onClick={() => setIsShow(!isShow)}
                        style={{ display: "flex" }}
                      >
                        <img
                          src={visible}
                          alt="Show"
                          style={{ height: "25px" }}
                        />{" "}
                        {/* &nbsp;
                      <p className="mb-0" style={{ color: "#adadad" }}>
                        Hide it
                      </p> */}
                      </div>
                    ) : (
                      <div
                        onClick={() => setIsShow(!isShow)}
                        style={{ display: "flex" }}
                      >
                        <img
                          onClick={() => setIsShow(!isShow)}
                          src={visibleOff}
                          alt="Show"
                          style={{ height: "25px" }}
                        />
                        {/* &nbsp; */}
                        {/* <p className="mb-0" style={{ color: "#adadad" }}>
                        Show it
                      </p> */}
                      </div>
                    )}
                  </div>
                </div>

                <div
                  onClick={() => {
                    navigate("/forgetPassword");
                  }}
                  className="text-end text-decoration-underline userLoginNavigate"
                >
                  Forget password?
                </div>
                <div className="userRegisterBtnContainer">
                  <button className="userRegisterBtn" type="submit">
                    {isLoading ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Log In"
                    )}
                  </button>
                </div>
              </div>
            </form>
            <p className="mb-0 fs-5 fw-bold text-danger">{error}</p>
            <div className="d-flex justify-content-between">
              {/* <div
                onClick={() => {
                  navigate("/userRegister");
                }}
                className="text-end pe-5  text-decoration-underline userLoginNavigate"
              >
                REGISTER?
              </div> */}
              {/* <div
                onClick={() => {
                  navigate("/forgetPassword");
                }}
                className="text-end pe-5  text-decoration-underline userLoginNavigate"
              >
                Forget password?
              </div> */}
            </div>
            {/* <div className="userRegister_registerMainContainer userRegister_registerForm ">
          
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
