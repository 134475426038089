import React from 'react'
// import Sidebar from './Sidebar'
import UserRegister from '../screens/UserRegister'
import SidebarMenu from './SidebarMenu'

export default function RegisterComponent() {
  return (
    <section>
      {/* <div
        className="container-fluid"
        style={{ overflow: "hidden", height: "100vh" }}
      >
        <div className="row p-0">
          <div className="col-lg-2 p-0">
            <SidebarMenu />
          </div>
          <div className="col-lg-10  p-0">
            <div className="row ">
              <div className="col-lg-12 p-0">
               
  
              </div>
              <div
                className="col-lg-12 "
                style={{ position: "relative", height: "100vh" }}
              >
                
               
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <UserRegister />
    </section>
  )
}
