import React from "react";
import VisitorListCom from "../../src/component/VisitorListCom";
const VisitorList = () => {
  return (
    <>
      <section className="clientViewMainSection p-0 m-0">
        <div className="container-fluid">
          <div className="row p-0 m-0">
            <div className="col-lg-12">
              <VisitorListCom />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VisitorList;
