import React from "react";

export default function InputField(props) {
  return (
    <>
      <input
        className={`${props?.registerInputCss} ${props.visitorAddCss} ${props.visiterInfoCss} `}
        type={props?.type}
        placeholder={props?.placeholder}
        readOnly={props?.readOnly}
        {...props?.field}
        list={props?.list}
        style={
          props?.fieldState && props?.fieldState?.error
            ? { borderColor: "red" }
            : {}
        }
        // autofocus={`${props?.autofocus},${props?.autofocusOff}`}
        // props?.autoFocus
      />
    </>
  );
}
