import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../asset";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Loading from "./Loading";
import Alert from "./Alert";

import Dropdown from "react-multilevel-dropdown";
import moment from "moment/moment";

export default function DemoTest() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState();
  const [sortOrder, setSortOrder] = useState(null);
  const [dobSortOrder, setDobSortOrder] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]); // Store selected items
  const [selectedValues, setSelectedValues] = useState({}); // Store selected values for each category
  const userData = sessionStorage.getItem("userdata");
  const [selectedDetail, setSelectedDetail] = useState("overAll");
  const [dltId, setDltId] = useState();
  const [postAlert, setPostAlert] = useState();
  const [putAlert, setPutAlert] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  console.log("postAlert", postAlert, selectedRows);

  const clientData = async () => {
    return await axios.get(`${process.env.REACT_APP_HOST}/visitors/all`);
  };

  const { data: clientdetails } = useQuery("clientData", clientData);

  const { data, isLoading, isError, refetch } = useQuery(
    "clientData",
    clientData
  );
  const deleteUser = async (id) => {
    return await axios.delete(
      `${process.env.REACT_APP_HOST}/visitors/delete/${id}`
    );
  };

  // // Delete multiple users
  const deleteUsers = async (ids) => {
    return await Promise.all(
      ids?.map((id) => {
        return deleteUser(id);
      })
    );
  };
  let details = (data && data?.data) || [];

  const handleCheckboxDelete = (id) => {
    console.log("handleCheckboxDelete", handleCheckboxDelete);

    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows?.includes(id);
      if (isSelected) {
        return prevSelectedRows?.filter((rowId) => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });
  };

  const mutation = useMutation({
    mutationFn: deleteUsers,
    onSuccess: () => {
      refetch();
      setPutAlert(false);
      setSelectedRows([]); // Clear selected rows
    },
    onError: (error) => {
      // console.error("Error deleting rows:", error);
      alert("Error deleting rows");
    },
  });
  const handleDelete = (id) => {
    setDltId(id);
    setPostAlert(true);
  };
  useEffect(() => {
    if (selectedRows.length !== 0 && putAlert) {
      mutation.mutate(selectedRows);
      setPostAlert(false);
      setPutAlert(false);
    } else if (putAlert && dltId) {
      deleteUser(dltId)
        .then(() => {
          refetch();
          setPostAlert(false);
          setPutAlert(false);
          setDltId(null);
        })
        .catch((error) => {
          alert("Error deleting row");
        });
    }
  }, [selectedRows, putAlert, dltId]);
  // Function to handle checkbox change
  const handleCheckboxChange = (type) => {
    setSelectedDetail((prev) => (prev === type ? "" : type));
  };

  const categories = [
    // { value: "name", label: "Name" },
    { value: "age", label: "Age" },
    { value: "caste", label: "Caste" },
    // { value: "place", label: "Place" },
    { value: "religion", label: "Religion" },
    { value: "category", label: "Category" },
    { value: "dob", label: "Date Of Birth" },
  ];
  //   const visitorData = async () => {
  //     return await axios.get(`${process.env.REACT_APP_HOST}/newvisitors`);
  //   };

  //   const { data, isLoading, isError, refetch } = useQuery(
  //     "visitorData",
  //     visitorData
  //   );

  //   let details = (data && data?.data) || [];

  let clientInformation = (clientdetails && clientdetails?.data) || [];

  // Visitor search filter

  const filteredVisitors = searchTerm
    ? details &&
      details?.filter((visitor) => {
        const term = searchTerm?.toLowerCase();
        return (
          // (visitor && visitor?.fileNumber?.toString().startsWith(term)) ||
          (visitor && visitor?.name?.toLowerCase().startsWith(term)) ||
          (visitor && visitor?.age?.toString().startsWith(term)) ||
          (visitor && visitor?.mobileNumber?.toString().startsWith(term)) ||
          (visitor && visitor?.email?.toString().startsWith(term)) ||
          (visitor && visitor?.education?.toString().startsWith(term)) ||
          (visitor &&
            visitor?.permanentAddress?.toLowerCase().includes(term)) ||
          (visitor && visitor?.officeAddress?.toLowerCase().includes(term))
          // (visitor && visitor?.dob?.toString().toLowerCase().startsWith(term))
        );
      })
    : details;

  // Client Filter

  const filteredClients =
    clientInformation &&
    clientInformation?.filter((detail) => {
      if (!searchTerm) return true;

      const { caseNumber } = detail?.caseDetails || {};
      const { subjectName, subjectMobileNumber } = detail?.subjectDetails || {};
      const { mobileNumber, clientName, email, age, address } =
        detail?.clientInformation || {};
      const term = searchTerm?.toLowerCase();
      return (
        caseNumber?.toString().toLowerCase().startsWith(term) ||
        mobileNumber?.toString().toLowerCase().startsWith(term) ||
        clientName?.toLowerCase().startsWith(term) ||
        email?.toString().toLowerCase().startsWith(term) ||
        age?.toString().toLowerCase().startsWith(term) ||
        detail?.subjectDetails?.subjectName?.toLowerCase().startsWith(term) ||
        detail?.subjectDetails?.subjectMobileNumber
          ?.toString()
          .toLowerCase()
          .startsWith(term) ||
        detail?.subjectDetails?.subjectAge
          ?.toString()
          .toLowerCase()
          .startsWith(term) ||
        detail?.subjectDetails?.subjectPermanentAddress
          ?.toString()
          .toLowerCase()
          .startsWith(term) ||
        address?.toString().toLowerCase().startsWith(term)
      );
    });

  // Helper function to generate age range options
  const generateAgeOptions = () => {
    const ageRanges = [
      "0/18",
      "18/30",
      "30/40",
      "40/50",
      "50/60",
      "60/70",
      "70/80",
      "80/90",
      "90/99",
    ];

    return (
      ageRanges &&
      ageRanges?.map((range) => ({
        label: `${range}`,
        value: range,
      }))
    );
  };

  const generateOptions = (category) => {
    if (!category) return []; // Return empty if no category selected

    if (category === "age") {
      return generateAgeOptions(); // Return age options if category is "age"
    }

    const uniqueValues = [
      ...new Set(
        filteredVisitors && filteredVisitors?.map((item) => item[category])
      ),
    ];

    return (
      uniqueValues &&
      uniqueValues?.map((value) => ({
        label: value,
        value,
      }))
    );
  };
  // Handle item selection and removal
  const handleItemSelection = (selectedOption) => {
    setSelectedItems((prevItems) => {
      const isAlreadySelected =
        prevItems &&
        prevItems?.some(
          (item) =>
            item?.value === selectedOption?.value &&
            item?.label === selectedOption?.label
        );
      if (isAlreadySelected) {
        return (
          prevItems &&
          prevItems?.filter(
            (item) =>
              item?.value !== selectedOption?.value ||
              item?.label !== selectedOption?.label
          )
        ); // Remove item
      } else {
        return [...prevItems, selectedOption]; // Add item
      }
    });
  };
  // Handle category selection
  const handleCategorySelect = (categoryItem) => {
    console.log("Selected Category:", categoryItem);
  };
  // Handle value selection for category
  const handleValueSelect = (category, option) => {
    console.log("Selected Value:", option); // Debugging log
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [category]: option?.value, // Update the selected value for the specific category
    }));
    handleItemSelection(option); // Add item to selected items
  };

  const handleRemoveItem = (item) => {
    setSelectedItems(
      (prevItems) =>
        prevItems &&
        prevItems?.filter(
          (i) => i?.value !== item?.value || i?.label !== item?.label
        )
    );

    setSelectedValues((prevValues) => {
      const updatedValues = { ...prevValues };
      const category = Object.keys(prevValues).find(
        (key) => prevValues[key] === item?.label
      );
      if (category) {
        delete updatedValues[category];
      }
      return updatedValues;
    });
  };

  //   const filteredData =
  //     selectedDetail === "visitor" ? filteredVisitors : filteredClients;

  // Filtering the data based on selected filters
  //   const filteredDatas =
  //     filteredVisitors &&
  //     filteredVisitors?.filter((row) => {
  //       return Object.keys(selectedValues)?.every((category) => {
  //         if (selectedValues[category]) {
  //           // Check if the selected category is "age"
  //           if (category === "age" && selectedValues[category]) {
  //             const ageRange = selectedValues[category]; // Get the selected age range
  //             const [minAge, maxAge] = ageRange.split("/").map(Number); // Split and convert to numbers
  //             const age = Number(row[category]); // Get the age from the row data

  //             // Check if the row's age is within the selected range
  //             return age >= minAge && age <= maxAge;
  //           }

  //           // Default filtering for other categories
  //           return row[category] === selectedValues[category];
  //         }
  //         return true;
  //       });
  //     });

  const downloadOverallClient = () => {
    // Determine data to export based on filter
    const exportData =
      filteredClients && filteredClients?.length > 0
        ? filteredClients
        : filteredClients;

    // Prepare data for the table worksheet
    const tableDatas =
      exportData && exportData?.length > 0
        ? exportData?.map((data, index) => ({
            "S.NO": index + 1,
            "  CLIENT NAME": data?.clientInformation?.clientName || "NULL",
            "CLIENT AGE": data?.clientInformation?.age || "NULL",
            "NAME OF PARENTS/GUARDIANS":
              data?.clientInformation?.guardianOrParent || "NULL",
            "CLIENT MOBILE NUMBER":
              data?.clientInformation?.mobileNumber || "NULL",
            "CLIENT EMAIL ID": data?.clientInformation?.email || "NULL",
            "CLIENT ADDRESS": data?.clientInformation?.address || "NULL",
            "SUBJECT NAME": data?.subjectDetails?.subjectName || "NULL",
            "SUBJECT AGE": data?.clientInformation?.age || "NULL",
            "SUBJECT MOBILE NO":
              data?.subjectDetails?.subjectMobileNumber || "NULL",
            "SUBJECT ADDRESS":
              data?.subjectDetails?.subjectPermanentAddress || "NULL",
            "NATURE OF CASE(TITLE)":
              data?.caseDetails?.natureOfCareTitle || "NULL",
            "NATURE OF CASE(DETAILS)":
              data?.caseDetails?.natureOfCareDetails || "NULL",
            "NATURE OF CASE(REQUIREMENTS)":
              data?.caseDetails?.natureOfCareClientReq || "NULL",
            "REFERENCE(JD/GS/OTHERS)":
              data?.referenceDetails?.reference || "NULL",
            "NAME OF(IOs)": data?.referenceDetails?.nameOfLos || "NULL",
            "DATE OF CLOSING": data?.caseDetails?.dateOfClosed || "NULL",
            // "FILE NUMBER": data?.referenceDetails?.fileNumber || "NULL",
            "REASON FOR CLOSURE":
              data?.referenceDetails?.reasonForClosure || "NULL",
            CR: data?.referenceDetails?.confReport || "NULL",
          }))
        : []; // Default to an empty array

    // Create table worksheet with headers
    const tableWorksheet = XLSX.utils.json_to_sheet(
      tableDatas?.length > 0 ? tableDatas : [{}],
      {
        skipHeader: false, // Ensure the header is included
      }
    );

    // If tableDatas is empty, add headers manually
    if (tableDatas?.length === 0) {
      XLSX.utils.sheet_add_aoa(tableWorksheet, [
        [
          "S.NO",
          // "FILE NO",
          "CLIENT NAME",
          "CLIENT AGE",
          "NAME OF PARENTS/GUARDIANS",
          "CLIENT MOBILE NUMBER",
          "CLIENT EMAIL ID",
          "CLIENT ADDRESS",
          "SUBJECT NAME",
          "SUBJECT AGE",
          "SUBJECT MOBILE NO",
          "SUBJECT ADDRESS",
          "NATURE OF CASE(TITLE)",
          "NATURE OF CASE(DETAILS)",
          "NATURE OF CASE(REQUIREMENTS)",
          "REFERENCE(JD/GS/OTHERS)",
          "NAME OF(IOs)",
          "DATE OF CLOSING",
          // "FILE NUMBER",
          "REASON FOR CLOSURE",
        ],
      ]);
    }

    // Create a new workbook and add the worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Client&Subject Information.xlsx");
  };

  const downloadClient = () => {
    // Determine data to export based on filter
    const exportData =
      filteredClients && filteredClients?.length > 0
        ? filteredClients
        : filteredClients;

    // Prepare data for the table worksheet
    const tableDatas =
      exportData && exportData?.length > 0
        ? exportData?.map((data, index) => ({
            // console.log('exportData',data);

            "S.NO": index + 1,
            "CLIENT NAME": data?.clientInformation?.clientName || "NULL",
            "CLIENT AGE": data?.clientInformation?.age || "NULL",
            "CLIENT EDUCATION": data?.clientInformation?.education || "NULL",
            "CLIENT MOBILE NUMBER":
              data?.clientInformation?.mobileNumber || "NULL",
            "CLIENT EMAIL ID": data?.clientInformation?.email || "NULL",
            "CASE FILE NO":
              data?.clientInformation?.caseDetails?.caseNumber || "NULL",
            "NAME OF PARENTS/GUARDIAN":
              data?.clientInformation?.guardianOrParent || "NULL",
            "CLIENT ADDRESS": data?.clientInformation?.address || "NULL",
          }))
        : []; // Default to an empty array

    // Create table worksheet with headers
    const tableWorksheet = XLSX.utils.json_to_sheet(
      tableDatas?.length > 0 ? tableDatas : [{}],
      {
        skipHeader: false, // Ensure the header is included
      }
    );

    // If tableDatas is empty, add headers manually
    if (tableDatas?.length === 0) {
      XLSX.utils.sheet_add_aoa(tableWorksheet, [
        [
          "S.NO",
          "CLIENT NAME",
          "CLIENT AGE",
          "CLIENT EDUCATION",
          "CLIENT MOBILE NUMBER",
          "CLIENT EMAIL ID",
          "CASE FILE NO",
          "NAME OF PARENTS/GUARDIAN",
          "CLIENT ADDRESS",
        ],
      ]);
    }

    // Create a new workbook and add the worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Client Information.xlsx");
  };
  const downloadsubject = () => {
    // Determine data to export based on filter
    const exportData =
      filteredClients && filteredClients?.length > 0
        ? filteredClients
        : filteredClients;

    // Prepare data for the table worksheet
    const tableDatas =
      exportData && exportData?.length > 0
        ? exportData?.map((data, index) => ({
            "S.NO": index + 1,
            "SUBJECT NAME": data?.subjectDetails?.subjectName || "NULL",
            "SUBJECT AGE": data?.clientInformation?.age || "NULL",
            "SUBJECT MOBILE NO":
              data?.subjectDetails?.subjectMobileNumber || "NULL",
            "SUBJECT ADDRESS":
              data?.subjectDetails?.subjectPermanentAddress || "NULL",
            "NATURE OF CASE(TITLE)":
              data?.caseDetails?.natureOfCareTitle || "NULL",
            "NATURE OF CASE(DETAILS)":
              data?.caseDetails?.natureOfCareDetails || "NULL",
            "NATURE OF CASE(REQUIREMENTS)":
              data?.caseDetails?.natureOfCareClientReq || "NULL",
            "REFERENCE(JD/GS/OTHERS)":
              data?.referenceDetails?.reference || "NULL",
            "NAME OF(IOs)": data?.referenceDetails?.nameOfLos || "NULL",
            "DATE OF CLOSING": data?.caseDetails?.dateOfClosed || "NULL",
            // "FILE NUMBER": data?.referenceDetails?.fileNumber || "NULL",
            "REASON FOR CLOSURE":
              data?.referenceDetails?.reasonForClosure || "NULL",
            CR: data?.referenceDetails?.confReport || "NULL",
          }))
        : []; // Default to an empty array

    // Create table worksheet with headers
    const tableWorksheet = XLSX.utils.json_to_sheet(
      tableDatas?.length > 0 ? tableDatas : [{}],
      {
        skipHeader: false, // Ensure the header is included
      }
    );

    // If tableDatas is empty, add headers manually
    if (tableDatas?.length === 0) {
      XLSX.utils.sheet_add_aoa(tableWorksheet, [
        [
          "S.NO",

          "SUBJECT NAME",
          "SUBJECT AGE",
          "SUBJECT MOBILE NO",
          "SUBJECT ADDRESS",
          "NATURE OF CASE(TITLE)",
          "NATURE OF CASE(DETAILS)",
          "NATURE OF CASE(REQUIREMENTS)",
          "REFERENCE(JD/GS/OTHERS)",
          "NAME OF(IOs)",
          "DATE OF CLOSING",
          // "FILE NUMBER",
          "REASON FOR CLOSURE",
        ],
      ]);
    }

    // Create a new workbook and add the worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Subject Information.xlsx");
  };

  //   const sortedData = useMemo(() => {
  //     if (!filteredDatas) return [];

  //     let sorted = [...filteredDatas];

  //     sorted.sort((a, b) => {
  //       // Sort by name first if `sortOrder` is defined
  //       if (sortOrder === "atoz" || sortOrder === "ztoa") {
  //         const nameA = a.name ? a.name.toLowerCase() : "";
  //         const nameB = b.name ? b.name.toLowerCase() : "";
  //         const nameComparison = nameA.localeCompare(nameB);

  //         if (sortOrder === "atoz") return nameComparison;
  //         if (sortOrder === "ztoa") return -nameComparison;
  //       }

  //       // Then, sort by DOB if `dobSortOrder` is defined
  //       if (dobSortOrder === "dobAscending" || dobSortOrder === "dobDescending") {
  //         const dobComparison = new Date(a.dob) - new Date(b.dob);

  //         if (dobSortOrder === "dobAscending") return dobComparison;
  //         if (dobSortOrder === "dobDescending") return -dobComparison;
  //       }
  //       if (dobSortOrder === "dovAscending" || dobSortOrder === "dovDescending") {
  //         const dovComparison = new Date(a.dateOfVisit) - new Date(b.dateOfVisit);

  //         if (dobSortOrder === "dovAscending") return dovComparison;
  //         if (dobSortOrder === "dovDescending") return -dovComparison;
  //       }

  //       return 0; // No sorting applied
  //     });

  //     return sorted;
  //   }, [filteredDatas, sortOrder, dobSortOrder]);

  const clientInfo = useMemo(() => {
    if (!filteredClients) return [];
    const sorted = [...filteredClients];

    if (sortOrder === "atoz") {
      sorted?.sort((a, b) =>
        a?.clientInformation?.clientName
          ?.toLowerCase()
          .localeCompare(b?.clientInformation?.clientName?.toLowerCase())
      );
    } else if (sortOrder === "ztoa") {
      sorted?.sort((a, b) =>
        b?.clientInformation?.clientName
          ?.toLowerCase()
          .localeCompare(a?.clientInformation?.clientName?.toLowerCase())
      );
    }
    return sorted;
  }, [filteredClients, sortOrder]);

  const subjectInfo = useMemo(() => {
    if (!filteredClients) return [];
    const sorted = [...filteredClients];
    if (sortOrder === "atoz") {
      sorted?.sort((a, b) =>
        a?.subjectDetails?.subjectName
          ?.toLowerCase()
          .localeCompare(b?.subjectDetails?.subjectName?.toLowerCase())
      );
    } else if (sortOrder === "ztoa") {
      sorted?.sort((a, b) =>
        b?.subjectDetails?.subjectName
          ?.toLowerCase()
          .localeCompare(a?.subjectDetails?.subjectName?.toLowerCase())
      );
    }
    return sorted;
  }, [filteredClients, sortOrder]);
  return (
    <>
      <div className="visitor-main-container px-1 m-0">
        <div className="row pt-2 px-2">
          <div className="col-12 d-flex justify-content-start align-items-center gap-5">
            <label className="d-flex align-items-center gap-2">
              <input
                type="checkbox"
                checked={selectedDetail === "overAll"}
                onChange={() => handleCheckboxChange("overAll")}
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
              Over All
            </label>

            <label className="d-flex align-items-center gap-2">
              <input
                type="checkbox"
                checked={selectedDetail === "client"}
                onChange={() => handleCheckboxChange("client")}
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
              Client
            </label>
            <label className="d-flex align-items-center gap-2">
              <input
                type="checkbox"
                checked={selectedDetail === "subject"}
                onChange={() => handleCheckboxChange("subject")}
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
              Subject
            </label>
          </div>
        </div>
        {selectedDetail == "overAll" && (
          <>
            <div className="row pt-2 pb-1">
              <div className="col-4  d-flex align-items-center gap-1">
                <div
                  style={{ width: "50px", height: "40px", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                >
                  <img
                    src={Images?.arrowBack}
                    style={{ width: "100%", height: "100%" }}
                    alt="no-image"
                    title="back arrow"
                  />
                </div>
                <h6 className="client-view-head pt-2">
                  Over All Client & Subject
                </h6>
              </div>
              <div className="col-8 d-flex align-items-center justify-content-end gap-2">
                <div className="search-block-client-view">
                  <input
                    type="search"
                    className="table-search"
                    value={searchTerm}
                    placeholder="Search by  name, age, mobNo, emailID, place"
                    title="Search by  name, age, mobNo, emailID ,place"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div className="image_icon">
                    <img
                      src={Images?.searchIcon}
                      style={{ width: "100%", height: "100%" }}
                      alt="Search"
                    />
                  </div>
                </div>

                <button
                  className="new-register-btn --client-register"
                  onClick={() => {
                    downloadOverallClient();
                  }}
                >
                  Download
                </button>
                {selectedRows == undefined || selectedRows?.length == 0 ? (
                  <></>
                ) : (
                  <>
                    <button
                      className="delete-btn"
                      onClick={handleDelete}
                      // disabled={mutation.isLoading}
                    >
                      <img
                        style={{ height: "30px" }}
                        src={Images?.deleteIcon}
                        alt={"deleteIcon"}
                        // style={{ width: "100%", height: "100%" }}
                      />
                      {/* {mutation.isLoading ? "Deleting..." : "DELETE"} */}
                    </button>
                  </>
                )}
              </div>
            </div>
            <div
              className="row  m-0 p-0"
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {/* <div className="col-12 d-flex justify-content-end align-items-center p-0 m-0">
                {selectedItems && selectedItems?.length > 0 ? (
                  <>
                    {selectedItems?.map((item) => {
                      return (
                        <>
                          <span
                            key={item?.value + item?.label}
                            style={{
                              marginLeft: "5px",
                              padding: "5px",
                              border: "1px solid #ccc",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            {item?.label}
                            <button
                              onClick={() => handleRemoveItem(item)} // Remove item when clicked
                              style={{
                                marginLeft: "5px",
                                background: "red",
                                color: "white",
                                border: "none",
                                padding: "0px 6px",
                                cursor: "pointer",
                              }}
                            >
                              X
                            </button>
                          </span>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    ""
                  </>
                )}
              </div> */}
            </div>

            <div className="filter-table-block mt-4">
              <TableContainer className="product_table_container_sticky">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="product_table_heading"
                        style={{ width: "50px" }}
                      >
                        S.No
                      </TableCell>
                      {userData == "admin" ? (
                        <></>
                      ) : (
                        <>
                          <TableCell className="product_table_heading">
                            Edit/Delete
                          </TableCell>
                        </>
                      )}

                      <TableCell className="product_table_heading">
                        Client's Name
                        <div className="d-flex gap-2 align-items-center">
                          <button
                            className="filter-button"
                            onClick={() => setSortOrder("atoz")}
                            style={{
                              fontSize: "5px",
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={Images?.arrowdown}
                              alt="no-image"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </button>
                          <button
                            className="filter-button"
                            onClick={() => setSortOrder("ztoa")}
                            style={{
                              fontSize: "5px",
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={Images?.arrowup}
                              alt="no-images"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </button>
                        </div>
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Age
                      </TableCell>
                      <TableCell className="product_table_heading">
                        education
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Type of Parents/Guardian
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Case File No
                      </TableCell>

                      <TableCell className="product_table_heading">
                        Client's Mob No
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Email Id, if any
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Client's Address
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Subject's Name
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Relationship between Subject & Client
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Subject's Mobile No
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Name Of Parents/Guardian
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Subject's Res.Address
                      </TableCell>
                      <TableCell className="product_table_heading">
                        NATURE OF CASE(Title)
                      </TableCell>
                      <TableCell className="product_table_heading">
                        NATURE OF CASE(Details)
                      </TableCell>
                      <TableCell className="product_table_heading">
                        NATURE OF CASE(Client's Requirements)
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Reference(JD/GS/Others)
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Name of (IOs)
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Date Of Closing
                      </TableCell>
                      {/* <TableCell className="product_table_heading">
                    File No
                  </TableCell> */}
                      <TableCell className="product_table_heading">
                        Reason For Closure
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Conferencial Report
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <Loading />
                    ) : clientInfo && clientInfo?.length > 0 ? (
                      clientInfo?.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell className="product_table_data">
                            {i + 1}
                          </TableCell>
                          {userData == "admin" ? (
                            <></>
                          ) : (
                            <>
                              <TableCell className="product_table_data">
                                <div className="d-flex align-items-start justify-content-start gap-1">
                                  <div className="d-flex align-items-center justify-content-center gap-2">
                                    <input
                                      type="checkbox"
                                      checked={selectedRows?.includes(item?.id)}
                                      title="Select Row"
                                      onChange={() =>
                                        handleCheckboxDelete(item?.id)
                                      }
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer",
                                      }}
                                    />

                                    <div
                                      style={{
                                        width: "25px",
                                        height: "30px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        src={Images?.pencilIcon}
                                        alt="edit-icon"
                                        title="Edit Row"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        onClick={() => {
                                          navigate("/clientEntry", {
                                            state: item,
                                          });
                                        }}
                                      />
                                    </div>
                                    {/* <div
                                  style={{
                                    width: "27px",
                                    height: "27px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={Images?.deleteIcon}
                                    alt="edit-icon"
                                    style={{ width: "100%", height: "100%" }}
                                    onClick={() => handleDelete(item?.id)}
                                    title="Delete Row"
                                  />
                                </div> */}
                                  </div>
                                </div>
                              </TableCell>
                            </>
                          )}
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {item && item?.clientInformation?.clientName}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {item?.clientInformation?.age}
                          </TableCell>

                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {item?.clientInformation?.education}
                          </TableCell>

                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.clientInformation?.guardianOrParent) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.caseDetails?.caseNumber) || "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.clientInformation?.mobileNumber) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.clientInformation?.email) || "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.clientInformation?.address) || "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {`${item && item?.subjectDetails?.subjectName}` ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.clientInformation?.guardianOrParent) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.subjectDetails?.subjectMobileNumber) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.clientInformation?.guardianOrParent) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.subjectDetails?.subjectPermanentAddress) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.caseDetails?.natureOfCareTitle) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.caseDetails?.natureOfCareDetails) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.caseDetails?.natureOfCareClientReq) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.referenceDetails?.reference) || "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.referenceDetails?.nameOfLos) || "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.caseDetails?.dateOfClosed) || "-"}
                          </TableCell>

                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.referenceDetails?.reasonForClosure) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.referenceDetails?.confReport) ||
                              "-"}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={23}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
        {selectedDetail == "client" && (
          <>
            <div className="row pt-2 pb-2">
              <div className="col-4  d-flex align-items-center gap-1">
                <div
                  style={{ width: "50px", height: "40px", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                >
                  <img
                    src={Images?.arrowBack}
                    style={{ width: "100%", height: "100%" }}
                    alt="no-image"
                    title="back arrow"
                  />
                </div>
                <h6 className="client-view-head pt-2">Client</h6>
              </div>
              <div className="col-8 d-flex align-items-center justify-content-end gap-2">
                <div className="search-block-client-view">
                  <input
                    type="search"
                    className="table-search"
                    value={searchTerm}
                    placeholder="Search by caseFileNo, name, age, mobNo, emailID, place"
                    title="Search by caseFileNo, name, age, mobNo, emailID, place"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div className="image_icon">
                    <img
                      src={Images?.searchIcon}
                      style={{ width: "100%", height: "100%" }}
                      alt="Search"
                    />
                  </div>
                </div>
                <button
                  className="new-register-btn --client-register"
                  onClick={() => {
                    downloadClient();
                  }}
                >
                  Download
                </button>
              </div>
            </div>
            <div className="filter-table-block my-1">
              <TableContainer className="filter-table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="product_table_heading">
                        S.No
                      </TableCell>

                      {/* <TableCell className="product_table_heading">
                            Date Of Registration
                          </TableCell> */}
                      <TableCell className="product_table_heading">
                        Client's Name
                        <div className="d-flex gap-2 align-items-center">
                          <button
                            className="filter-button"
                            onClick={() => setSortOrder("atoz")}
                            style={{
                              width: "70px",
                              height: "30px",
                              fontSize: "12px",
                              textAlign: "center",
                              lineHeight: "normal",
                            }}
                          >
                            <img
                              src={Images?.arrowdown}
                              alt="no-image"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </button>
                          <button
                            className="filter-button"
                            onClick={() => setSortOrder("ztoa")}
                            style={{
                              width: "70px",
                              height: "30px",
                              fontSize: "12px",
                              textAlign: "center",
                              lineHeight: "normal",
                            }}
                          >
                            <img
                              src={Images?.arrowup}
                              alt="no-images"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </button>
                        </div>
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Age
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Education
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Case File No
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Name of Parents/Guardian
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Client's Mob No
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Email Id, if any
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Client's Address
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <Loading />
                    ) : clientInfo && clientInfo?.length > 0 ? (
                      clientInfo?.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {i + 1}
                          </TableCell>

                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {item && item?.clientInformation?.clientName}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {item?.clientInformation?.age}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {item?.clientInformation?.education}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.caseDetails?.caseNumber) || "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.clientInformation?.guardianOrParent) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.clientInformation?.mobileNumber) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.clientInformation?.email) || "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.clientInformation?.address) || "-"}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={23}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
        {selectedDetail == "subject" && (
          <>
            <div className="row pt-2 pb-2">
              <div className="col-4  d-flex align-items-center gap-1">
                <div
                  style={{ width: "50px", height: "40px", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                >
                  <img
                    src={Images?.arrowBack}
                    style={{ width: "100%", height: "100%" }}
                    alt="no-image"
                    title="back-arrow"
                  />
                </div>
                <h6 className="client-view-head pt-2">Subject</h6>
              </div>
              <div className="col-8 d-flex align-items-center justify-content-end gap-2">
                <div className="search-block-client-view">
                  <input
                    type="search"
                    className="table-search"
                    value={searchTerm}
                    placeholder="Search by caseFileNo, name, age, mobNo, emailID, place"
                    title="Search by caseFileNo, name, age, mobNo, emailID, place"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div className="image_icon">
                    <img
                      src={Images?.searchIcon}
                      style={{ width: "100%", height: "100%" }}
                      alt="Search"
                    />
                  </div>
                </div>
                <button
                  className="new-register-btn --client-register"
                  onClick={() => {
                    downloadsubject();
                  }}
                >
                  Download
                </button>
              </div>
            </div>
            <div className="filter-table-block mb-1">
              <TableContainer className="filter-table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="product_table_heading">
                        S.No
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Subject's Name
                        <div className="d-flex gap-2 align-items-center">
                          <button
                            className="filter-button"
                            onClick={() => setSortOrder("atoz")}
                            style={{
                              width: "70px",
                              height: "30px",
                              fontSize: "12px",
                              textAlign: "center",
                              lineHeight: "normal",
                            }}
                          >
                            <img
                              src={Images?.arrowdown}
                              alt="no-image"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </button>
                          <button
                            className="filter-button"
                            onClick={() => setSortOrder("ztoa")}
                            style={{
                              width: "70px",
                              height: "30px",
                              fontSize: "12px",
                              textAlign: "center",
                              lineHeight: "normal",
                            }}
                          >
                            <img
                              src={Images?.arrowup}
                              alt="no-images"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </button>
                        </div>
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Age
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Relationship between Subject & Client
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Subject's Mobile No
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Subject's Res.Address
                      </TableCell>
                      <TableCell className="product_table_heading">
                        NATURE OF CASE(Title)
                      </TableCell>
                      <TableCell className="product_table_heading">
                        NATURE OF CASE(Details)
                      </TableCell>
                      <TableCell className="product_table_heading">
                        NATURE OF CASE(Client's Requirements)
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Reference(JD/GS/Others)
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Name of (IOs)
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Date Of Closing
                      </TableCell>
                      {/* <TableCell className="product_table_heading">
                            File No
                          </TableCell> */}
                      <TableCell className="product_table_heading">
                        Reason For Closure
                      </TableCell>
                      <TableCell className="product_table_heading">
                        Conferencial Report
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <Loading />
                    ) : subjectInfo && subjectInfo?.length > 0 ? (
                      subjectInfo?.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {i + 1}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {item && item?.subjectDetails?.subjectName}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {item && item?.subjectDetails?.subjectAge}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.clientInformation?.guardianOrParent) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.subjectDetails?.subjectMobileNumber) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.subjectDetails?.subjectPermanentAddress) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.caseDetails?.natureOfCareTitle) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.caseDetails?.natureOfCareDetails) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.caseDetails?.natureOfCareClientReq) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.referenceDetails?.reference) || "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.referenceDetails?.nameOfLos) || "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.caseDetails?.dateOfClosed) || "-"}
                          </TableCell>
                          {/* <TableCell className="product_table_data">
                                {(item && item?.referenceDetails?.fileNumber) ||
                                  "-"}
                              </TableCell> */}
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item &&
                              item?.referenceDetails?.reasonForClosure) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            className="product_table_data"
                            onClick={() => {
                              navigate("/clientEntry", {
                                state: item,
                              });
                            }}
                          >
                            {(item && item?.referenceDetails?.confReport) ||
                              "-"}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={23}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
        <Alert
          open={postAlert}
          setPutAlert={setPutAlert}
          // navigate = "/clientList"
          navigation="/clientList"
          // postAlert={postAlert}
          // putAlert={putAlert}
          add={false}
          onCloses={setPostAlert}
          // setPutAlert={setPutAlert}
        />
      </div>
    </>
  );
}
