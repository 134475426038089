import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UserRegister from "./screens/UserRegister";
import UserLogin from "./screens/UserLogin";
import VisitorView from "./screens/VisitorView/VisitorView";
import { QueryClient, QueryClientProvider } from "react-query";
import VisitorAdd from "./component/VisitorAdd";
import ClientView from "../src/screens/ClientViewPage/ClientView";
import Demo from "./screens/Demo";
import FileUpload from "./screens/FileUpload";
import FileUploadComponent from "./component/FileUploadComponent";
import UserForgetPassword from "./screens/UserForgetPassword";
import ResetPassword from "./screens/ResetPassword";
import OtpCode from "./screens/OtpCode";
import FilterData from "./screens/FilterData";
import RegisterComponent from "./component/RegisterComponent";
import ViewRegister from "./screens/ViewRegister";
import ViewRegisterComponent from "./component/ViewRegisterComponent";
import SidebarMenu from "./component/SidebarMenu";
import VisitorInformation from "./screens/VisitorInformation";
import VisitorList from "./screens/VisitorList";
import ClientInformation from "./screens/ClientInformation";
import ClientList from "./screens/ClientList";
import DemoTest from "./component/DemoTest";
import PasscodePopup from "./component/PasscodePopup";

function Navigation() {
  const queryClient = new QueryClient();
  const login = sessionStorage.getItem("login");
  const userdata = sessionStorage.getItem("userdata");
  console.log("login", login);


  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router>
          {login ? (
            userdata === "superAdmin" ? (
              <React.StrictMode>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "100%" }}
                >
                  <div style={{ width: "15%", marginRight: "0px" }}>
                    <SidebarMenu />
                  </div>
                  <div style={{ width: "83%"}}>
                    <Routes>
                      <Route path="/" element={<VisitorList />} />
                      <Route path="/demo" element={<DemoTest />} />
                      <Route
                        path="/visitorEntry"
                        element={<VisitorInformation />}
                      />
                      {/* <Route path="/visitorList" element={<VisitorList />} /> */}
                      <Route
                        path="/clientEntry"
                        element={<ClientInformation />}
                      />
                      <Route path="/clientList" element={<ClientList />} />
                      <Route path="/visitorAdd" element={<VisitorAdd />} />
                      <Route
                        path="/fileUpload"
                        element={<FileUploadComponent />}
                      />
                      <Route path="/filterData" element={<FilterData />} />
                      <Route path="/demo" element={<Demo />} />
                      <Route
                        path="/userRegister"
                        element={<RegisterComponent />}
                      />
                      <Route
                        path="/viewRegister"
                        element={<ViewRegisterComponent />}
                      />
                    </Routes>
                  </div>
                </div>
              </React.StrictMode>
            ) : (
              <React.StrictMode>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "100%" ,}}
                >
                  <div style={{ width: "15%", marginRight: "0px" }}>
                    <SidebarMenu />
                  </div>
                  <div style={{ width: "83%" }}>
                    <Routes>
                      <Route path="/" element={<VisitorList />} />
                      <Route
                        path="/visitorEntry"
                        element={<VisitorInformation />}
                      />
                      {/* <Route path="/visitorList" element={<VisitorList />} /> */}
                      <Route
                        path="/clientEntry"
                        element={<ClientInformation />}
                      />
                      <Route path="/clientList" element={<ClientList />} />
                      <Route path="/filterData" element={<FilterData />} />
                    </Routes>
                  </div>
                </div>
              </React.StrictMode>
            )
          ) : (
            <React.StrictMode>
              <Routes>
                {/* <Route path="/userRegister" element={<UserRegister />} /> */}
                <Route path="/" element={<UserLogin />} />
                <Route
                  path="/forgetPassword"
                  element={<UserForgetPassword />}
                />
                <Route path="/resetPassword" element={<ResetPassword />} />
              </Routes>
            </React.StrictMode>
          )}
        </Router>
      </QueryClientProvider>
      <PasscodePopup />
    </>
  );
}

export default Navigation;
